import { SHOW_SIDEBAR, HIDE_SIDEBAR } from "../actions/types";

const initialState = {
    isSidebar: true,
};

const sidebar = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_SIDEBAR:
      return {
        ...state,
        isSidebar: true,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        isSidebar: false,
      };

    default:
      return state;
  }
};
export default sidebar;
