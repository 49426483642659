import axios from "axios";

export const getSkills = async (data) => {
  try {
    const url = `/skills/getAll`;
    const response = await axios.get(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSelfEvalSkills = async () => {
  try {
    const url = `/self_evaluations/skills`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSelfEvalTest = async (skillData, type) => {
  try {
    let config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const url = `/questions/getAll/${type}`;
    const response = await axios.post(url, skillData, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addSelfEvaluation = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `/self_evaluations/add`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addSkills = async (data) => {
  try {
    const url = `/self_evaluations/addSkills`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadAiCvData = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `/ai_interview/upload_resume`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitAiAns = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `/ai_interview/audio`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitMCQTest = async (data) => {
  try {
    const url = `/questions/submit_answers`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitMCQCoding = async (data) => {
  try {
    const url = `/questions/submit_answers_coding`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAIInterviewScore = async (apct_id = "") => {
  try {
    const url = `/candidate/get/inteviewAiScore/${apct_id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCourses = async (data) => {
  try {
    const url = `/self_evaluations/course/suggesstions`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteEvSkill = async (sesId) => {
  try {
    const url = `/self_evaluations_skills/remove/${sesId}`;
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const endInt = async (data) => {
  try {
    const url = `/ai_interview/conclude`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};
