import { useState } from "react";
import AceEditor from "react-ace";
import {
  Stack,
  IconButton,
} from "@mui/material";
import NightlightIcon from "@mui/icons-material/Nightlight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

import Select from "react-select";

import { languages } from "../../data/dummyData";

import "ace-builds/src-noconflict/mode-apex";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sass";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-ruby";

import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

const ace = require("ace-builds/src-noconflict/ace");
ace.config.set(
  "basePath",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/"
);
ace.config.setModuleUrl(
  "ace/mode/javascript_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-javascript.js"
);
export default function Editor(data) {
  const [defaultLanguage, setDefaultLanguage] = useState(languages[0]);
  const [themeDark, setDarkTheme] = useState(true);

  const toggleTheme = () => {
    setDarkTheme(!themeDark);
  };

  const handleChangeLang = (selectedOption) => {
    setDefaultLanguage(selectedOption);
  };

  // useEffect(() => {
  //   console.log("default", defaultLanguage);
  // }, [defaultLanguage]);

  //   console.log(data);
  return (
    <>
      <Stack
        sx={{ width: "100%", height: "50px", backgroundColor: "#EAF0F5" }}
        direction={{ xs: "row", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <div
          style={{
            zIndex: 1000,
          }}
        >
          <Select
            value={defaultLanguage}
            onChange={handleChangeLang}
            options={languages}
          />
        </div>
        <div>
          {themeDark ? (
            <IconButton onClick={toggleTheme}>
              <WbSunnyIcon />
            </IconButton>
          ) : (
            <IconButton onClick={toggleTheme}>
              <NightlightIcon />
            </IconButton>
          )}
        </div>
      </Stack>

      <AceEditor
        mode={defaultLanguage.value}
        theme={themeDark ? "chaos" : "github"}
        width="100%"
        height="400px"
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
        onChange={data.onChange}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        // value={data.value}
        wrapEnabled={true}
        setOptions={{
          useWorker: false,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </>
  );
}
