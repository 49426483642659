import { useEffect, useState, useRef, useCallback } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  MenuItem,
  Select,
  Grid,
  LinearProgress,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import React from "react";
import { connect } from "react-redux";
import AIInterview from "./AIInterview";
import { getApplicant } from "../../redux/actions/applicant";
import { getInterviewStatus } from "../../redux/actions/candidate";
import { useNavigate } from "react-router-dom";
import useFullScreen from "../../hooks/fullScreenWindow";
import NetworkInfo from "../../components/candidate/NetworkInfo";
import OperatingSystemCheck from "../../components/OperatingSystemCheck";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
const CandidateDashboard = ({
  getApplicant,
  applicant,
  skills,
  getInterviewStatus,
  auth: { user },
  interviewStatus,
  hideSidebar
}) => {
  const [mcqCompleted, setMcqCompleted] = useState(false);
  const [codingCompleted, setCodingCompleted] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullScreen();
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [deviceConfirmed, setDeviceConfirmed] = useState(false);
  // const [selectedDevice, setSelectedDevice] = useState("");
  const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
  const [microphoneQuality, setMicrophoneQuality] = useState(0); // Microphone quality as a percentage
  const [isMuted, setIsMuted] = useState(true);
  const [pictureTaken, setPictureTaken] = useState(false);
  const [speed, setSpeed] = useState("");
  const [volumeLevel, setVolumeLevel] = useState(0);
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const navigate = useNavigate();

  const videoRef = useCallback(
    (node) => {
      if (node && selectedVideoDevice) {
        startVideo(selectedVideoDevice, node);
      }
    },
    [selectedVideoDevice]
  );

  useEffect(() => {
    hideSidebar();
  }, [hideSidebar]);

  // Fetch applicant and interview status
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.u_apct_id_fk) {
          await getInterviewStatus(user.u_apct_id_fk);
          await getApplicant(user.u_apct_id_fk);
        }
      } catch (error) {
        console.error(
          "Error fetching interview status or applicant data:",
          error
        );
      }
    };

    fetchData();
  }, [user, getInterviewStatus, getApplicant]);

   // Request permissions and detect video devices
   useEffect(() => {
    const getPermissionsAndDevices = async () => {
      try {
        // Request permissions for video and audio
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream after permissions are granted

        // Enumerate video input devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setVideoDevices(videoDevices);
        const audioDevices = devices.filter((d) => d.kind === "audioinput");
        setAudioDevices(audioDevices);

        // // Automatically select the first device
        // if (videoDevices.length > 0) {
        //   selectedAudioDevice(videoDevices[0].deviceId);
        // }
      } catch (error) {
        console.error(
          "Error requesting permissions or fetching video devices:",
          error
        );
      }
    };

    getPermissionsAndDevices();
  }, []);


  // Set MCQ and Coding test completion state
  useEffect(() => {
    if (applicant) {
      setMcqCompleted(!applicant.apct_mcq && applicant.apct_mcq_complete);
      setCodingCompleted(
        !applicant.apct_coding && applicant.apct_coding_complete
      );
    }
  }, [applicant]);

  // Fetch video and audio devices
  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setVideoDevices(devices.filter((d) => d.kind === "videoinput"));
        setAudioDevices(devices.filter((d) => d.kind === "audioinput"));
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };
    getDevices();
  }, []);

  // Start video preview
  const startVideo = async (deviceId, videoElement) => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
  
      // Enable only the video stream initially
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
        audio: false, // Audio is disabled by default
      });
  
      videoElement.srcObject = videoStream;
      videoElement.play();
      streamRef.current = videoStream; // Save the video stream reference
    } catch (error) {
      console.error("Error starting video stream:", error);
    }
  };
  

  const startAudio = async (audioDeviceId) => {
    try {
      // Enable audio stream when a microphone is selected
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: audioDeviceId } },
      });
  
      // Check microphone quality for the selected audio device
      checkMicrophoneQuality(audioStream);
    } catch (error) {
      console.error("Error starting audio stream:", error);
    }
  };

  const handleAudioDeviceSelection = async (event) => {
    const audioDeviceId = event.target.value;
    setSelectedAudioDevice(audioDeviceId);
  
    // Start the audio stream and check quality
    startAudio(audioDeviceId);
  };

  // Check microphone quality and mute status
  const checkMicrophoneQuality = async (stream) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);

    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;

    let silentFrames = 0; // Count of consecutive silent frames
    const silenceThreshold = 50; // Number of frames to confirm mute
    const silenceLevel = 80; // Adjusted threshold for silence detection

    const analyzeAudio = () => {
      analyser.getByteTimeDomainData(dataArray);
      const sum = dataArray.reduce((acc, value) => acc + Math.abs(value - 128), 0);
      const average = sum / dataArray.length;

      const quality = Math.min((average / 128) * 100, 100); // Calculate quality as a percentage
      setMicrophoneQuality(quality);
      // console.log("==========>",sum);

      // const isAudioSilent = sum < 80;
      // Increment or reset silentFrames based on audio input level
      if (sum < silenceLevel) {
        silentFrames++;
      } else {
        silentFrames = 0;
      }
      const isAudioSilent = silentFrames > silenceThreshold;
      if (stream.getAudioTracks()[0].muted) {
        setIsMuted(true);
      } else if (isAudioSilent) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }

      // console.log(
      //   "Audio Input Level:",
      //   sum,
      //   "Silent Frames:",
      //   silentFrames,
      //   "Muted:",
      //   silentFrames > silenceThreshold
      // );
      
      // Check if the microphone is muted based on low or no audio input
      // const isAudioSilent = sum < 5; // Low threshold for audio input
      // setIsMuted(isAudioSilent);
      // console.log("====================>", stream.getAudioTracks());
      requestAnimationFrame(analyzeAudio);
    };

    analyzeAudio();
  };

  const takePicture = () => {
    const canvas = document.createElement("canvas");
    const video = document.querySelector("#picture-capture-video");
    if (!video) {
      console.error("Video element not found for picture capture.");
      return;
    }
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const picture = canvas.toDataURL("image/png");
    console.log("Picture captured: ", picture);
    setPictureTaken(true);
  };

  const handleJoin = () => {
    setOpenDeviceDialog(false);
    navigate("/candidate/interview");
  };

  const handleCancel = () => {
    setOpenDeviceDialog(false);
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  

  const confirmDeviceSelection = () => {
    if (selectedVideoDevice && selectedAudioDevice && !isMuted) {
      localStorage.setItem("selectedDeviceId", selectedVideoDevice);
      localStorage.setItem("selectedDeviceIdAudio", selectedAudioDevice);
      setDeviceConfirmed(true);
      setOpenDeviceDialog(false);
      takePicture();
      // Stop video stream to save resources
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    } else {
      alert("Please select all required perameter");
    }
  };

  const handleStartMcqTest = () => {
    enterFullScreen();
    navigate("/candidate/mcq-test");
  };

  const handleStartCodingTest = () => {
    enterFullScreen();
    navigate("/candidate/coding-test");
  };

  // const showButton = (() => {
  //   if (!interviewStatus || interviewStatus.apct_stage === "completed") return false;

  //   if (applicant?.apct_coding && applicant?.apct_mcq) {
  //       // Case 1: Both coding and MCQ are enabled, show button only if both are completed
  //       return codingCompleted && mcqCompleted;
  //   }

  //   if (applicant?.apct_coding && !applicant?.apct_mcq ) {
  //       // Case 2: Only coding is enabled, show button if coding is completed
  //       return codingCompleted;
  //   }

  //   if (!applicant?.apct_coding && applicant?.apct_mcq) {
  //     // Case 2: Only coding is enabled, show button if coding is completed
  //     return mcqCompleted;
  //   }

  //   if (!applicant?.apct_coding && !applicant?.apct_mcq) {
  //       // Case 3: Neither coding nor MCQ is enabled, show button
  //       return true;
  //   }

  //   return false;
  // })();

  return (
    <>
      <Dialog open={openDeviceDialog} maxWidth="md" fullWidth>
        <Grid container>
          {/* Left Section */}
          <Grid item xs={6} style={{ padding: "20px" }}>
            <video
              id="picture-capture-video"
              ref={videoRef}
              style={{
                width: "100%",
                height: "auto",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
              autoPlay
              playsInline
              muted
            />
            <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
              Microphone Quality:
            </Typography>
            <LinearProgress
              variant="determinate"
              value={microphoneQuality}
              style={{ height: "10px", borderRadius: "5px" }}
            />
            <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
              Microphone Status:{" "}
              {isMuted ? <MicOffIcon color="error" /> : <MicIcon color="primary" />}
            </Typography>
          </Grid>

          {/* Right Section */}
          <Grid item xs={6} style={{ padding: "20px" }}>
            <Typography variant="h5">Select Devices</Typography>
            <Select
              value={selectedVideoDevice}
              onChange={(e) => setSelectedVideoDevice(e.target.value)}
              displayEmpty
              fullWidth
              style={{ marginTop: "20px" }}
            >
              <MenuItem value="" disabled>
                Select Camera
              </MenuItem>
              {videoDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Camera ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedAudioDevice}
              onChange={(e) => setSelectedAudioDevice(e.target.value)}
              displayEmpty
              fullWidth
              style={{ marginTop: "20px" }}
            >
              <MenuItem value="" disabled>
                Select Microphone
              </MenuItem>
              {audioDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
            <Box style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <Button variant="contained" color="primary" onClick={confirmDeviceSelection}>
                Take Picture
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>

      {/* Test Buttons */}
      {applicant?.apct_mcq || applicant?.apct_coding || interviewStatus ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          {!mcqCompleted && applicant?.apct_mcq ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartMcqTest}
            >
              Start MCQ Test
            </Button>
          ) : !codingCompleted && applicant?.apct_coding ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartCodingTest}
            >
              Start Coding Test
            </Button>
          ) : interviewStatus &&
            interviewStatus.apct_stage !== "completed" &&
            deviceConfirmed && (
              <AIInterview
                userId={user && user.u_id}
                userConsent={applicant?.apct_user_consent}
                InterViewStage={interviewStatus.apct_stage}
                interViewThreadId={applicant?.apct_int_thread_id}
                streamRef={streamRef}
              />
            )}
          {interviewStatus &&
            interviewStatus.apct_stage !== "completed" &&
            !deviceConfirmed && !applicant?.apct_mcq && !applicant?.apct_coding && (
              <>
                {speed > 1 ? (
                  // <Button
                  //   variant="contained"
                  //   color="primary"
                  //   onClick={() => setOpenDeviceDialog(true)}
                  // >
                  //   Select Video Device
                  // </Button>
                  <Grid container>
            {/* Left Section */}
            <Grid item xs={6} style={{ padding: "20px" }}>
              <video
                id="picture-capture-video"
                ref={videoRef}
                style={{
                  width: "100%",
                  height: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                }}
                autoPlay
                playsInline
                muted
              />
              
            </Grid>

            {/* Right Section */}
            <Grid item xs={6} style={{ padding: "20px" }}>
              <Typography variant="h5">Select Devices (Current OS:<OperatingSystemCheck />)</Typography>
              <Select
                value={selectedVideoDevice}
                onChange={(e) => setSelectedVideoDevice(e.target.value)}
                displayEmpty
                fullWidth
                style={{ marginTop: "20px" }}
              >
                <MenuItem value="" disabled>
                  Select Camera
                </MenuItem>
                {videoDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Camera ${device.deviceId}`}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={selectedAudioDevice}
                onChange={handleAudioDeviceSelection}
                displayEmpty
                fullWidth
                style={{ marginTop: "20px" }}
              >
                <MenuItem value="" disabled>
                  Select Microphone
                </MenuItem>
                {audioDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Microphone ${device.deviceId}`}
                  </MenuItem>
                ))}
              </Select>
              <Box style={{ marginTop: "20px", display: "flex", gap: "10px", flexDirection:"column"}}>
              <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
                Microphone Status:{" "}
                {isMuted ? <MicOffIcon color="error" /> : <><MicIcon color="primary" /><LinearProgress
                variant="determinate"
                value={microphoneQuality}
                style={{ height: "10px", borderRadius: "5px" }}
              /></>}
              </Typography>
                <Button variant="contained" color="primary" onClick={confirmDeviceSelection}>
                  Take Picture
                </Button>
                {/* <Button variant="outlined" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button> */}
              </Box>
              
            </Grid>
          </Grid>
                ) : (
                  "Poor network please ensure a stable and high-quality network connection to resolve the connectivity issue."
                )}
                <NetworkInfo speed={speed} setSpeed={setSpeed} />
              </>
            )}

          {/* {showButton && !deviceConfirmed && (
            <>
                {speed > 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenDeviceDialog(true)}
                    >
                        Select Video Device
                    </Button>
                )}
                <NetworkInfo speed={speed} setSpeed={setSpeed} />
            </>
        )} */}
        </Box>
      ) : (
        <Typography variant="subtitle2" textAlign="center" color="primary">
          No tests available.
        </Typography>
      )}

      {interviewStatus && interviewStatus.apct_stage === "completed" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            Your AI interview is completed, HR will get back to you.
          </Typography>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  interviewStatus: state.candidate.interviewStatus,
  applicant: state.applicant?.applicant?.applicant,
  skills: state.applicant?.applicant?.skills,
});

const mapDispatchToProps = { getInterviewStatus, getApplicant, hideSidebar };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
