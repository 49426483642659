import { GET_APP_ORG_LABELS_SUCCESS, CLEAR_MESSAGE } from "../actions/types";

const initialState = {
  appOrgLabel: {}
};

export default function app_org_labels(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_APP_ORG_LABELS_SUCCESS:
      return { ...state, appOrgLabel: payload };
    default:
      return state;
  }
}
