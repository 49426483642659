import { useState, useEffect } from "react";
import { Typography, Card, CardContent, Divider, Stack, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { deleteQuestionSetAction, getQuestionSets } from '../../redux/actions/questions';
import { formatDate } from "../../utils/dateUtils";

export const QuestionSet = ({ question_sets, getQuestionSets, deleteQuestionSetAction }) => {
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [tableHeight, setTableHeight] = useState(400);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [questionSetToDelete, setQuestionSetToDelete] = useState();
    const rowHeight = 52;
    const updateTableHeight = (pageSize) => {
        const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
        const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
        const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
        setTableHeight(newHeight);
    };
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getQuestionSets()
    }, [])

    const handleCloseDeleteDialog = () => {
        // setSkillToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleDeleteQuestionSet = async () => {
        deleteQuestionSetAction(questionSetToDelete.qs_id)
        handleCloseDeleteDialog();
    };

    const handleOpenDeleteDialog = (qs) => {
        setQuestionSetToDelete(qs);
        // setIsDeletingMustHave(isMustHave);
        setOpenDeleteDialog(true);
    };
    const columns = [
        {
            field: "qs_name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => (
                <Link to={params.row.qs_id}>
                    {params.row.qs_name}
                </Link>
            ),
        },
        {
            field: "total_questions",
            headerName: "Total Question",
            minWidth: 200,
            flex: 1,
        },
        {
            field: "qs_created_at",
            headerName: "Created At",
            flex: 1,
            renderCell: (params) => (
                <>
                    {params && params.row && params.row.qs_created_at
                        ? formatDate(
                            JSON.parse(localStorage.getItem("user")),
                            params.row.qs_created_at
                        )
                        : ""}
                </>
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <Button
                    sx={{ color: "red" }}
                    onClick={() => handleOpenDeleteDialog(params.row)}
                >
                    <DeleteIcon />
                </Button>
            ),
        },
    ];

    return (
        <>
            <Card raised sx={{ minWidth: "100%" }}>
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5">{t('Question Set')}</Typography>
                        <Button component={Link} to="create" variant="text" size="small" sx={{ color: "green" }}>
                            <AddIcon /> Add
                        </Button>
                    </Box>
                    <Divider />
                    <div style={{ height: tableHeight, width: "100%" }}>
                        {[] && (
                            <ServerPaginationGridNoRowCount
                                setPaginationModals={setPaginationModals}
                                paginationModals={paginationModals}
                                rowsData={question_sets}
                                getRowId={(row) => row?.qs_id}
                                total={2}
                                columns={columns}
                            />
                        )}
                    </div>
                </CardContent>
            </Card>
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Question Set?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteQuestionSet} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    question_sets: state.questions.question_sets,
});

const mapDispatchToProps = { getQuestionSets, deleteQuestionSetAction };

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSet);