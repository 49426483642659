import React, { Suspense, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

// Avatar Component
const Avatar = ({ mouthOpenValue, eyeBlinkValue, avatarData }) => {
  // console.log("===========>", avatarData.avog_avatar_path);
  const { scene } = useGLTF(avatarData.avog_avatar_path); // Replace with your GLB model path
  // Apply morph target updates
  scene.traverse((object) => {
    if (object.morphTargetDictionary && object.morphTargetInfluences) {
      const morphIndex = object.morphTargetDictionary["mouthOpen"]; // Use the "mouthOpen" morph target
      if (morphIndex !== undefined) {
        object.morphTargetInfluences[morphIndex] = mouthOpenValue; // Set influence based on mouthOpenValue
      }

      // Eye Blink Morph Targets
      if (object.name === "EyeLeft" || object.name === "EyeRight") {
        object.scale.y = 1 - eyeBlinkValue; // Reduce Y-scale for blinking
      }
    }
  });

  return (
    <primitive
      object={scene}
      scale={[3.2, 3.0, 2.5]} // Adjust scale
      position={[0, -2.5, -2]} // Adjust position to align vertically
      rotation={[0.5, 0, 0]} // Slight tilt for a natural look
    />
  );
};

const LipSyncAvatar = ({ speechText, speaking, avatarData }) => {
  // console.log(avatarData);
  const [mouthOpenValue, setMouthOpenValue] = useState(0); // Control mouth openness
  const [eyeBlinkValue, setEyeBlinkValue] = useState(0); // Control eye blinking
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: window.innerWidth * 0.4,
    height: window.innerHeight * 0.5,
  });

  // Update dimensions on resize
  useEffect(() => {
    const handleResize = () => {
      setCanvasDimensions({
        width: window.innerWidth * 0.4, // Adjust canvas width to 80% of the viewport
        height: window.innerHeight * 0.5, // Adjust canvas height to 50% of the viewport
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Eye blinking effect
    const blinkInterval = setInterval(() => {
      setEyeBlinkValue(1); // Close eyes
      setTimeout(() => setEyeBlinkValue(0), 200); // Open eyes after 200ms
    }, 3000); // Blink every 3 seconds

    return () => clearInterval(blinkInterval);
  }, []);

  // Update mouth openness during speaking
  useEffect(() => {
    if (speaking) {
      const interval = setInterval(() => {
        setMouthOpenValue(Math.random()); // Randomize mouth openness for animation
      }, 100);

      return () => clearInterval(interval);
    } else {
      setMouthOpenValue(0); // Reset mouth when not speaking
    }
  }, [speaking]);

  return (
    <div style={{ textAlign: "center", marginTop: "-50px" }}>
      <Canvas
        style={{
          width: `${canvasDimensions.width}px`,
          height: `${canvasDimensions.height}px`,
          margin: "0 auto",
        }}
      >
        <ambientLight intensity={5} />
        <directionalLight position={[5, 5, 5]} intensity={0.5}/>
        <Suspense fallback={null}>
          <Avatar
            mouthOpenValue={mouthOpenValue}
            eyeBlinkValue={eyeBlinkValue}
            avatarData={avatarData}
          />
        </Suspense>
        <OrbitControls
          enableZoom={false} // Disable zoom for consistent avatar size
          target={[0, 2, 0]} // Focus on the head and shoulders
          maxDistance={1.5} // Restrict how far the user can zoom out
          minDistance={1.5} // Restrict how close the user can zoom in
        />
      </Canvas>
    </div>
  );
};

export default LipSyncAvatar;
