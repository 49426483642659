import axios from "axios";

export const _createQuestionSet = async (data) => {
    try {
        const url = `/question-set/create`;
        const response = await axios.post(url, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const _getQuestionSets = async () => {
    try {
        const url = `/question-set/list`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        throw error;
    }
};

export const _getQuestionSetDetail = async (id) => {
    try {
        const url = `/question-set/${id}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        throw error;
    }
};

export const _createQuestion = async (id, data) => {
    try {
        const url = `/question-set/${id}/create-question`;
        const response = await axios.post(url, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const _deleteQuestionSet = async (id) => {
    try {
        const url = `/question-set/delete/${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        throw error;
    }
};