import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";
import { v4 as uuidv4 } from "uuid";

export const setMessage =
  (msg, timeout = 3000) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: SET_MESSAGE,
      payload: { msg, id },
    });

    setTimeout(() => dispatch({ type: CLEAR_MESSAGE, payload: id }), timeout);
  };