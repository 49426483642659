import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Card,
  Divider,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getAllList,
  createJob,
  updateJobSkills,
  uploadCVs,
  clearAddJobData,
  parseJD,
  clearJDData,
} from "../../redux/actions/jobs";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getQuestionSets } from "../../redux/actions/questions";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
    borderRadius: 10,
  }),
};

export const AddJob = ({
  getAllList,
  currentUser,
  createJob,
  uploadCVs,
  clearAddJobData,
  parseJD,
  dataList,
  jobCreated,
  cvsUploaded,
  jobSkillsUpdated,
  parsedJDData,
  clearJDData,
  getQuestionSets,
  question_sets
}) => {
  let formDataObj = {
    title: null,
    description: null,
    minExp: 0,
    maxExp: 0,
  };

  const [formData, setFormData] = useState(formDataObj);
  const { title, minExp, maxExp } = formData;
  const [techList, setTechList] = useState([]);
  const [skillsList, setSkillsList] = useState();
  const [addSkillsList, setAddSkillsList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [domain, setDomain] = useState(null);
  const [technology, setTechnology] = useState(null);
  const [questionSet, setQuestionSet] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [uploadedJd, setUploadedJd] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [countdown, setCountdown] = useState(10); // Added countdown state
  const [redirecting, setRedirecting] = useState(false); // Added redirecting state
  const [selectedOption, setSelectedOption] = useState("Baali"); // Added selectedOption state
  const [hrEmail, setHrEmail] = useState();
  const [emailError, setEmailError] = useState('');
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setHrEmail(value);

    if (value && !validateEmail(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };
  const [selectedOptionCV, setSelectedOptionCV] = useState("Baali"); // Added selectedOptionCV state
  const [init, setInit] = useState(true); // Added init state
  const [isSubmitted, setIsSubmitted] = useState(false); // Added isSubmitted state
  const [isEditSkillForMustHave, setIsEditSkillForMustHave] = useState(false);
  const [isEditSkillForNiceToHave, setIsEditSkillForNiceToHave] =
    useState(false);
  const [iseEditAdditionalSkill, setIsEditAdditionalSkill] = useState(false);
  const [newMustHaveSkill, setNewMustHaveSkill] = useState([]);
  const [newNiceToHaveSkill, setNewNiceToHaveSkill] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState(null);
  const [isDeletingMustHave, setIsDeletingMustHave] = useState(false);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  useEffect(() => {
    if (init) {
      resetForm();
      setInit(false);
    }
  }, [init]);

  useEffect(() => {
    getQuestionSets()
  }, [])

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const removeCV = (index) => () => {
    const files = [...uploadedFiles];
    files.splice(index, 1);
    setUploadedFiles(files);
    setFileLimit(false);
  };

  const handleJDFile = async (e) => {
    let reqBody = new FormData();
    let file = e.target.files[0];

    if (file) {
      setUploadedJd(file);
      reqBody.append(`jd_file`, file, file.name);
      reqBody.append(`model_name`, selectedOption);
      reqBody.append(`hrEmail`, hrEmail || currentUser.user.u_email);
      await parseJD(reqBody);
    }
  };

  useEffect(() => {
    if (parsedJDData && parsedJDData) {
      setFormData((prevData) => ({
        ...prevData,
        title: parsedJDData.jdTitle,
        minExp: parsedJDData.exp,
        maxExp: parsedJDData.exp + 2,
      }));
      if (parsedJDData.skills && parsedJDData.skills.length > 0) {
        let sk = parsedJDData.skills.map((skill) => {
          return {
            id: uuidv4(),
            name: skill,
            weightage: 1,
          };
        });
        setSkillsList(sk);
      }
    }
  }, [parsedJDData]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "maxExp" && value < minExp) {
      return;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name === "minExp" && value > maxExp) {
      return;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    localStorage.setItem("model_name", selectedOption);
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // console.log("event.target.value 1", event.target.value);
    // localStorage.setItem("model_name", event.target.value);
  };

  useEffect(() => {
    getAllList();
  }, []);

  useEffect(() => {
    if (dataList) {
      const { domains, skillTechnologies } = dataList;

      if (domains && domains.length > 0) {
        setDomainList(domains);
        setDomain();
      }

      if (skillTechnologies && skillTechnologies.length > 0) {
        setTechList(skillTechnologies);
        setTechnology();
      }
    }
  }, [dataList]);

  const onClickRightSkill = (row) => {
    setSkillsList((prevValue) => prevValue.filter((r) => r.id !== row.id));
    setAddSkillsList((prevValue) => [...prevValue, row]);
  };

  const onClickLefttSkill = (row) => {
    setSkillsList((prevValue) => [...prevValue, row]);
    setAddSkillsList((prevValue) => prevValue.filter((r) => r.id !== row.id));
  };

  // const onChangeWidthage = (e, row) => {
  //   if (e.target.value > 0 && e.target.value < 5) {
  //     setAddSkillsList((prevValue) =>
  //       prevValue.map((r) =>
  //         r.id === row.id ? { ...r, weightage: e.target.value } : r
  //       )
  //     );
  //   }
  // };

  const onChangeWidthage = (e, id, index) => {
    let value = e.target.value;
    if (value < 1 && value != "") {
      value = 1;
    }
    if (value > 4) {
      value = 4;
    }
    if (id === "new") {
      // setNewNiceToHaveSkill({ ...newNiceToHaveSkill, weightage: value });
      const skills = newNiceToHaveSkill.map((skill, idx) => {
        if (idx === index) {
          skill.weightage = value;
        }
        return skill;
      });
      setNewNiceToHaveSkill(skills);
    } else {
      let skills = addSkillsList.map((skill) => {
        if (skill.id === id) {
          skill.weightage = value;
        }
        return skill;
      });
      setAddSkillsList(skills);
    }
  };

  const setFormDataObj = async () => {
    let form = new FormData();
    form.append(`title`, title);
    form.append(`minExp`, minExp);
    form.append(`maxExp`, maxExp);
    if (questionSet) {
      form.append(`questionSet`, questionSet);
    }
    form.append(`selectedQuestionTypes`, Object.entries(selectedQuestionTypes).filter(([key, value]) => value).map(([key]) => key).join(','))
    form.append(`domain`, domain.dm_id);
    form.append(`technology`, technology.sl_tech_id);
    form.append(`mustHave`, JSON.stringify(skillsList));
    form.append(`additionalSkills`, JSON.stringify(addSkillsList));
    form.append(`filePath`, parsedJDData.filePath);
    form.append(`fileName`, parsedJDData.fileName);
    form.append(`model_name`, selectedOption);
    form.append(`job_id`, parsedJDData.job_id);

    if (selectedOption === "chat_gpt" && parsedJDData) {
      form.append(`assistant_id`, parsedJDData.assistant_id);
      form.append(`vector_store_id`, parsedJDData.vector_store_id);
      form.append(`thread_id`, parsedJDData.thread_id);
    }

    uploadedFiles.forEach((file) => {
      form.append(`files`, file, file.name);
    });
    setTotalSteps(uploadedFiles.length);
    setUploadedFiles([]);
    return form;
  };

  // useEffect(() => {
  //   if (totalSteps > 0 && currentStep >= totalSteps) {
  //     setRedirecting(true);
  //     const interval = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);

  //     setTimeout(() => {
  //       clearInterval(interval);
  //       resetForm();
  //       navigate(`/hr/batches/batch-detail/${jobCreated.batch_id}`);
  //     }, 10000);
  //   }
  // }, [currentStep, totalSteps, cvsUploaded.batch_id, navigate]);

  // useEffect(() => {
  //   if (currentStep < totalSteps) {
  //     setTimeout(() => {
  //       setCurrentStep((prevStep) => prevStep + 1);
  //     }, 6000);
  //   }
  // }, [currentStep, totalSteps]);

  // const handleChangeSkill = (e, isAdditional, index) => {
  //   let value = e.target.value;
  //   if (isAdditional) {
  //     setAddSkillsList((prevValue) =>
  //       prevValue.map((r, i) => (i === index ? { ...r, name: value } : r))
  //     );
  //   } else {
  //     setSkillsList((prevValue) =>
  //       prevValue.map((r, i) => (i === index ? { ...r, name: value } : r))
  //     );
  //   }
  // };

  const handleChangeSkill = (e, isAdditional, id, index) => {
    // let value = e.target.value;
    const { name, value } = e.target;
    if (id === "new") {
      if (isAdditional) {
        let updatedSkills = [...newMustHaveSkill];
        updatedSkills[index][name] = value;
        setNewMustHaveSkill(updatedSkills);
        // setNewMustHaveSkill({ ...newNiceToHaveSkill, name: value });
      } else {
        let updatedAddSkills = [...newNiceToHaveSkill];
        updatedAddSkills[index][name] = value;
        setNewNiceToHaveSkill(updatedAddSkills);
        // setNewNiceToHaveSkill({ ...newMustHaveSkill, name: value });
      }
    } else {
      if (isAdditional) {
        let updatedSkills = addSkillsList.map((skill, idx) => {
          if (idx === index) {
            return { ...skill, [name]: value };
          }
          return skill;
        });
        setAddSkillsList(updatedSkills);
      } else {
        let updatedAddSkills = skillsList.map((skill, idx) => {
          if (idx === index) {
            return { ...skill, [name]: value };
          }
          return skill;
        });
        setSkillsList(updatedAddSkills);
      }
    }
  };

  //   const handleChangeSkill = (e, isAdditional, index) => {
  //   const { name, value } = e.target;
  //   if (index === "new") {
  //     if (isAdditional) {
  //             console.log("name", name,value);
  //   console.log('isAdditional', isAdditional, index)
  //       const updatedSkills = [...newNiceToHaveSkill];
  //       updatedSkills[index][name] = value;
  //       setNewMustHaveSkill(updatedSkills);
  //     } else {
  //       const updatedSkills = [...newMustHaveSkill];
  //       updatedSkills[index][name] = value;
  //       setNewNiceToHaveSkill(updatedSkills);
  //     }
  //   } else {
  //     if (isAdditional) {
  //       const updatedSkills = addSkillsList.map((skill, idx) => {
  //         if (idx === index) {
  //           return { ...skill, [name]: value };
  //         }
  //         return skill;
  //       });
  //       setAddSkillsList(updatedSkills);
  //     } else {
  //       const updatedSkills = skillsList.map((skill, idx) => {
  //         if (idx === index) {
  //           return { ...skill, [name]: value };
  //         }
  //         return skill;
  //       });
  //       setSkillsList(updatedSkills);
  //     }
  //   }
  // };

  // const handleAddSkill = (isAdditional) => {

  //   if (isAdditional) {
  //     setIsEditSkill(true);
  //     setSkillsList((prevValue) => [
  //       ...prevValue,
  //       { id: uuidv4(), name: "", weightage: 1 },
  //     ]);
  //   } else {
  //     setIsEditAdditionalSkill(true);
  //     setAddSkillsList((prevValue) => [
  //       ...prevValue,
  //       { id: uuidv4(), name: "" , weightage: 1 },
  //     ]);
  //   }
  // };

  //   const handleAddSkill = (isAdditional) => {
  //   setIsEditSkill(true);
  //   if (isAdditional) {
  //     setNewMustHaveSkill({ name: "", weightage: 1 });
  //   } else {
  //     setNewNiceToHaveSkill({ name: "", weightage: 1 });
  //   }
  // };

  const handleAddSkill = (isAdditional) => {
    if (isAdditional) {
      setIsEditSkillForMustHave(true);
      setNewMustHaveSkill([...newMustHaveSkill, { name: "", weightage: 1 }]);
    } else {
      setIsEditSkillForNiceToHave(true);
      setNewNiceToHaveSkill([
        ...newNiceToHaveSkill,
        { name: "", weightage: 1 },
      ]);
    }
  };

  const onClickUpdateSkills = async (isMustHave) => {
    if (isMustHave) {
      if (newMustHaveSkill.some((skill) => skill.name === "")) {
        toast.error("Skill name is required");
        return;
      }

      const updatedMustHaveSkills = newMustHaveSkill.map((skill) => ({
        id: uuidv4(),
        name: skill.name,
        weightage: skill.weightage || 1,
      }));

      setSkillsList([...updatedMustHaveSkills, ...skillsList]);
    } else {
      if (newNiceToHaveSkill.some((skill) => skill.name === "")) {
        toast.error("Skill name is required");
        return;
      }
      const updatedNiceToHaveSkills = newNiceToHaveSkill.map((skill) => ({
        id: uuidv4(),
        name: skill.name,
        weightage: skill.weightage || 1,
      }));

      setAddSkillsList([...updatedNiceToHaveSkills, ...addSkillsList]);
    }

    if (isMustHave) {
      setNewMustHaveSkill([]);
      setIsEditSkillForMustHave(false);
    } else {
      setNewNiceToHaveSkill([]);
      setIsEditSkillForNiceToHave(false);
    }
  };

  // const handleCancelNewSkill = (isAdditional) => {
  //   if (isAdditional) {
  //     setAddSkillsList((prevValue) =>
  //       prevValue.filter((r) => r.id !== addSkillsList[addSkillsList.length - 1].id)
  //     );
  //   } else {
  //     setSkillsList((prevValue) =>
  //       prevValue.filter((r) => r.id !== skillsList[skillsList.length - 1].id)
  //     );
  //   }
  //   setIsEditSkill(false);
  // };
  const handleCancelNewSkill = (isAdditional) => {
    if (isAdditional) {
      setNewMustHaveSkill([]);
    } else {
      setNewNiceToHaveSkill([]);
    }
  };

  const handleDeleteSkill = async () => {
    if (isDeletingMustHave) {
      setSkillsList(
        skillsList.filter((skill) => skill.id !== skillToDelete.id)
      );
    } else {
      setAddSkillsList(
        addSkillsList.filter((skill) => skill.id !== skillToDelete.id)
      );
    }
    handleCloseDeleteDialog();
  };

  const handleOpenDeleteDialog = (skill, isMustHave) => {
    setSkillToDelete(skill);
    setIsDeletingMustHave(isMustHave);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSkillToDelete(null);
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (jobCreated) {
      if (jobCreated.batch_id) {
        navigate(`/hr/batches/batch-detail/${jobCreated.batch_id}/`);
      } else {
        navigate(`/hr/jd-detail/${jobCreated.jb_id}`);
      }
    }
  }, [jobCreated]);

  const onClickSubmit = async () => {
    let body = await setFormDataObj();
    setIsSubmitted(true);
    await createJob(body, uploadedFiles.length);
  };

  const onClickReset = () => {
    navigate(0);
  };

  const resetForm = () => {
    clearJDData();
    setIsSubmitted(false);
    setFormData(formDataObj);
    setUploadedFiles([]);
    setFileLimit(false);
    setUploadedJd(null);
    setJobId(null);
    setSkillsList([]);
    setAddSkillsList([]);
    setDomain(null);
    setTechnology(null);
  };

  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState({
    mcq: false,
    coding: false,
    ai: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSelectedQuestionTypes((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Card xs={12} sx={{ mt: 1 }}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6}>
            <Typography variant={"h5"} sx={{ mt: 2, ml: 2 }}>
              {t('Create Job Description')}
            </Typography>
          </Grid>
          {!parsedJDData ? (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Analysing Module
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOption}
                  label="Analysing Module"
                  onChange={handleOptionChange}
                >
                  {/* <MenuItem value={"chat_gpt"}>Chat GPT</MenuItem>
                  <MenuItem value={"Baali"}>Baali</MenuItem> */}
                  <MenuItem value={"gemini"}>Gemini</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
        <Divider />
        <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={4} >
            <Typography variant="subtitle1" fontSize={14}>
              Email (for candidate interview response)
            </Typography>
            <TextField
              name="hrEmail"
              type="email"
              onChange={handleEmailChange}
              value={hrEmail}
            />
            {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
          </Grid>
          <Grid item xs={12} sm={8} textAlign="center">
            <Button
              color="primary"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              disabled={parsedJDData || emailError}
            >
              Upload Job Description
              <VisuallyHiddenInput
                type="file"
                onChange={handleJDFile}
                inputProps={{
                  accept:
                    "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }}
              />
            </Button>
            <br />
            {parsedJDData && parsedJDData ? null : (
              <small>
                <InfoIcon /> <i>Only PDF & Docx files are supported!</i>
              </small>
            )}
            <Typography variant="subtitle1" fontSize={14}>
              {uploadedJd && uploadedJd.name}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {parsedJDData && parsedJDData ? (
        <>
          <Card xs={12} sx={{ mt: 1 }}>
            <Typography variant={"h5"} sx={{ mt: 2, ml: 2 }}>
              Job Description Details
            </Typography>
            <Divider />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" fontSize={14}>
                  Job Title *
                </Typography>
                <TextField
                  name="title"
                  required
                  autoFocus
                  value={title}
                  disabled={isSubmitted}
                  onChange={handleFormDataChange}
                  fullWidth
                  inputProps={{
                    style: { fontFamily: "inherit" },
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                {/* <Typography variant="subtitle1" fontSize={14}>
                  Domain *
                </Typography> */}
                <FormControl fullWidth disabled={isSubmitted}>
                  <InputLabel id="domain-select-label">Domain *</InputLabel>
                  <Select
                    labelId="domain-select-label"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    label="Domain"
                    required
                  >
                    {domainList.map((domainItem) => (
                      <MenuItem key={domainItem.dm_id} value={domainItem}>
                        {domainItem.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" fontSize={14}>
                  Min Experience *
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  name="minExp"
                  value={minExp}
                  onChange={handleFormDataChange}
                  hiddenLabel
                  required
                  disabled={isSubmitted}
                  variant="outlined"
                  fontWeight="bold"
                  inputProps={{
                    autoComplete: "off",
                    ariaLabel: "Experience",
                    step: "0.5",
                    min: 0,
                    max: maxExp,
                    style: { font: "inherit" },
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                {/* <Typography variant="subtitle1" fontSize={14}>
                  {" "}
                  Technology *{" "}
                </Typography> */}
                <FormControl fullWidth disabled={isSubmitted}>
                  <InputLabel id="domain-select-label">Technology *</InputLabel>
                  <Select
                    labelId="technology-select-label"
                    value={technology}
                    onChange={(e) => setTechnology(e.target.value)}
                    label="Technology"
                    required
                  >
                    {techList.map((techItem) => (
                      <MenuItem key={techItem.sl_tech_id} value={techItem}>
                        {techItem.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle1" fontSize={14}>
                  Max Experience *
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  name="maxExp"
                  value={maxExp}
                  onChange={handleFormDataChange}
                  hiddenLabel
                  required
                  disabled={isSubmitted}
                  variant="outlined"
                  fontWeight="bold"
                  inputProps={{
                    autoComplete: "off",
                    ariaLabel: "Experience",
                    step: "0.5",
                    min: minExp,
                    style: { font: "inherit" },
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <FormControl fullWidth disabled={isSubmitted}>
                  <InputLabel id="domain-select-label">Question Set </InputLabel>
                  <Select
                    labelId="qs-select-label"
                    value={questionSet}
                    onChange={(e) => setQuestionSet(e.target.value)}
                    label="Question Set"
                  >
                    {question_sets && question_sets?.map((qsItem) => (
                      <MenuItem key={qsItem.qs_id} value={qsItem.qs_id}>
                        {qsItem.qs_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {questionSet && (<Grid item xs={12} container flexDirection="row" justifyContent="space-around" alignItems="center">
                <Typography variant="subtitle1" fontSize={14}>
                  Question Type
                </Typography>
                <FormControlLabel
                  control={<Checkbox
                    name="mcq"
                    checked={selectedQuestionTypes.mcq}
                    onChange={handleChange}
                  />}
                  label="MCQ"
                />
                <FormControlLabel
                  control={<Checkbox
                    name="coding"
                    checked={selectedQuestionTypes.coding}
                    onChange={handleChange}
                  />}
                  label="Coding"
                />
                <FormControlLabel
                  control={<Checkbox
                    name="ai"
                    checked={selectedQuestionTypes.ai}
                    onChange={handleChange}
                  />}
                  label="AI Questions"
                />
              </Grid>
              )}
            </Grid>
          </Card>
          {(skillsList && skillsList.length > 0) ||
            (addSkillsList && addSkillsList.length > 0) ? (
            <Card xs={12} sx={{ mt: 1 }}>
              <Typography variant={"h5"} sx={{ mt: 2, ml: 2 }}>
                Skill Analysis
              </Typography>
              <Divider />
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle1" fontSize={14}>
                    Must Have Skills (Weightage : 5)
                  </Typography>
                  {skillsList && skillsList.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      sx={{ mt: 3, maxHeight: 400 }}
                    >
                      <Table
                        aria-label="Must Have Skills (Weightage : 5)"
                        size="small"
                        align={"right"}
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell width={200} align="left">
                              Skill Name
                            </TableCell>

                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell width={50} align="left">
                              <Button
                                variant="text"
                                size={"small"}
                                sx={{ color: "white" }}
                                onClick={() => handleAddSkill(true)}
                              >
                                +Add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isEditSkillForMustHave &&
                            newMustHaveSkill?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <TextField
                                    name="name"
                                    value={row.name}
                                    onChange={(e) =>
                                      handleChangeSkill(e, true, "new", index)
                                    }
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          {skillsList.map((row, index) => (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                <TextField
                                  name="name"
                                  value={row.name}
                                  disabled={isSubmitted}
                                  onChange={(e) =>
                                    handleChangeSkill(e, false, row.id, index)
                                  }
                                  fullWidth
                                  inputProps={{
                                    style: { fontFamily: "inherit" },
                                  }}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <IconButton
                                  disabled={isSubmitted}
                                  onClick={() => onClickRightSkill(row)}
                                >
                                  <ArrowForwardIcon className={"pointer"} />
                                </IconButton>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() =>
                                    handleOpenDeleteDialog(row, true)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null}
                  {isEditSkillForMustHave && newMustHaveSkill.length > 0 && (
                    <TableRow>
                      <Button onClick={() => onClickUpdateSkills(true)}>
                        Save
                      </Button>
                      <Button onClick={() => handleCancelNewSkill(true)}>
                        Cancel
                      </Button>
                    </TableRow>
                  )}
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle1" fontSize={14}>
                    Nice to Have Skills (Weightage : 1-4)
                  </Typography>
                  {addSkillsList ? (
                    <TableContainer
                      component={Paper}
                      sx={{ mt: 3, maxHeight: 300 }}
                    >
                      <Table
                        aria-label="Nice to Have Skills (Weightage : 1-4)"
                        size="small"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow style={{ height: 33 }}>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell width={200} align="left">
                              Skill Name
                            </TableCell>
                            <TableCell align="left">Weightage</TableCell>
                            <TableCell width={100} align="left">
                              <Button
                                variant="text"
                                size={"small"}
                                sx={{ color: "white" }}
                                onClick={() => handleAddSkill(false)}
                              >
                                +Add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isEditSkillForNiceToHave &&
                            newNiceToHaveSkill?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <IconButton
                                    disabled={true}
                                  // onClick={() => onClickLefttSkill(row)}
                                  >
                                    <ArrowBackIcon className={"pointer"} />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    name="name"
                                    value={row.name}
                                    onChange={(e) =>
                                      handleChangeSkill(e, false, "new", index)
                                    }
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    type="number"
                                    name="weightage"
                                    value={row.weightage}
                                    onChange={(e) =>
                                      onChangeWidthage(e, "new", index)
                                    }
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          {addSkillsList.map((row, index) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                <IconButton
                                  disabled={isSubmitted}
                                  onClick={() => onClickLefttSkill(row)}
                                >
                                  <ArrowBackIcon className={"pointer"} />
                                </IconButton>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <TextField
                                  name="name"
                                  value={row.name}
                                  disabled={isSubmitted}
                                  onChange={(e) =>
                                    handleChangeSkill(e, true, row.id, index)
                                  }
                                  // fullWidth
                                  inputProps={{
                                    style: { fontFamily: "inherit" },
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left" width={200}>
                                <Box>
                                  <TextField
                                    type="number"
                                    id="outlined-basic"
                                    name="weightage"
                                    value={row.weightage}
                                    onChange={(e) =>
                                      onChangeWidthage(e, row.id, index)
                                    }
                                    hiddenLabel
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    disabled={isSubmitted}
                                    inputProps={{
                                      autoComplete: "off",
                                      ariaLabel: "Experience",
                                      step: "1",
                                      min: 1,
                                      max: 4,
                                    }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() =>
                                    handleOpenDeleteDialog(row, false)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null}
                  {isEditSkillForNiceToHave &&
                    newNiceToHaveSkill.length > 0 && (
                      <TableRow>
                        <Button onClick={() => onClickUpdateSkills(false)}>
                          Save
                        </Button>
                        <Button onClick={() => handleCancelNewSkill(false)}>
                          Cancel
                        </Button>
                      </TableRow>
                    )}
                </Grid>
              </Grid>
            </Card>
          ) : null}
        </>
      ) : null}
      {parsedJDData && parsedJDData ? (
        <>
          <Card xs={12} sx={{ mt: 1 }}>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant={"h5"} sx={{ mt: 2, ml: 2 }}>
                  Upload CVs
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth disabled={isSubmitted}>
                  <InputLabel id="demo-simple-select-label">
                    Analysing Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={localStorage.getItem("model_name")}
                    label="Analysing Module"
                    disabled
                  >
                    <MenuItem value={"chat_gpt"}>Chat GPT</MenuItem>
                    <MenuItem value={"Baali"}>Baali</MenuItem>
                    <MenuItem value={"gemini"}>Gemini</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Divider />
              <Grid item xs={12} sm={12} textAlign="center">
                <Button
                  color="primary"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  disabled={isSubmitted}
                >
                  Upload CVs
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileEvent}
                    multiple
                    disabled={fileLimit}
                    accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </Button>
                <br />
                <small>
                  <InfoIcon /> <i>Only PDF & Docx files are supported!</i>
                </small>
                {/* {totalSteps > 0 && (
                  <ProgressBarComponent
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                  />
                )} */}
                {uploadedFiles.length > 0 ? (
                  <TableContainer
                    component={Paper}
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" width={20}>
                            SN
                          </TableCell>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {uploadedFiles.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              {" "}
                              <IconButton onClick={removeCV(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {/* {redirecting && (
              <Grid item xs={12} textAlign="center" sx={{ mt: 4 }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  You are redirecting in {countdown} seconds...
                </Typography>
              </Grid>
            )} */}
          </Card>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={6}>
              <Button
                size="small"
                component="label"
                color="error"
                variant="contained"
                onClick={onClickReset}
                disabled={totalSteps > 0 || isSubmitted}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="end">
              <Button
                size="small"
                color="primary"
                component="label"
                variant="contained"
                onClick={onClickSubmit}
                disabled={
                  !title ||
                  !maxExp ||
                  !domain ||
                  !technology ||
                  isSubmitted
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this skill?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSkill} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dataList: state.jobs.dataList,
  jobCreated: state.jobs.jobCreated,
  cvsUploaded: state.jobs.cvsUploaded,
  jobSkillsUpdated: state.jobs.jobSkillsUpdated,
  parsedJDData: state.jobs.parsedJDData,
  question_sets: state.questions.question_sets,
  currentUser: state.auth,
});

const mapDispatchToProps = {
  getAllList,
  createJob,
  updateJobSkills,
  uploadCVs,
  clearAddJobData,
  parseJD,
  clearJDData,
  getQuestionSets
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJob);
