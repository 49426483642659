import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, TextField, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { submitSelfEvaluation } from "../../redux/actions/dashboard";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const AddExperience = ({ submitSelfEvaluation }) => {
  const navigate = useNavigate();

  const [name, setname] = useState("");
  const [overallExp, setOverallExp] = useState("");
  const [relevantExp, setRelevantExp] = useState("");
  const [file, setFile] = useState(null);

  const onChangeName = (e) => {
    setname(e.target.value);
  };

  const onChangeOverallExp = (e) => {
    setOverallExp(e.target.value);
  };

  const onChangeRelevantExp = (e) => {
    setRelevantExp(e.target.value);
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const submitData = async () => {
    let reqBody = new FormData();
    if (file) {
      reqBody.append(`se_cv`, file, file.name);
      reqBody.append(`se_name`, name);
      reqBody.append(`se_overall_experience`, overallExp);
      reqBody.append(`se_relevant_experience`, relevantExp);
      await submitSelfEvaluation(reqBody);
      navigate(-1);
    } else {
      alert("Please upload your CV");
      return;
    }
  };
  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignSelf="center"
        spacing={1}
        useFlexGap
        sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
      >
        <TextField
          id="outlined-basic"
          name="name"
          value={name}
          onChange={onChangeName}
          hiddenLabel
          size="small"
          variant="outlined"
          aria-label="Employee Name"
          placeholder="Enter your name"
          inputProps={{
            autoComplete: "off",
            ariaLabel: "Employee Name",
          }}
        />
        <TextField
          id="outlined-basic"
          type="number"
          name="overallExp"
          value={overallExp.toString()}
          onChange={onChangeOverallExp}
          hiddenLabel
          size="small"
          variant="outlined"
          aria-label="OverAll Experience"
          placeholder="Overall experience"
          inputProps={{
            autoComplete: "off",
            ariaLabel: "OverAll Experience ",
            step: "0.5",
          }}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignSelf="center"
        spacing={1}
        useFlexGap
        sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
      >
        <TextField
          id="outlined-basic"
          type="number"
          name="relevantExp"
          value={relevantExp.toString()}
          onChange={onChangeRelevantExp}
          hiddenLabel
          size="small"
          variant="outlined"
          aria-label="Relevant Experience"
          placeholder="Relevant experience"
          inputProps={{
            autoComplete: "off",
            ariaLabel: "Relevant Experience",
            step: "0.5",
          }}
        />
        <Button
          color="primary"
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload CV
          <VisuallyHiddenInput type="file" onChange={onChangeFile} />
        </Button>
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          mt: 3,
          justifyContent: "space-between",
        }}
      >
        <Button
          color="primary"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          color="primary"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={submitData}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { submitSelfEvaluation };

export default connect(mapStateToProps, mapDispatchToProps)(AddExperience);
