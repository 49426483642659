import { thunk } from "redux-thunk";
import {legacy_createStore as createStore, applyMiddleware} from "redux";
import rootReducer from "./reducers";
let middle = applyMiddleware(thunk);

if (process.env.REACT_APP_NODE_ENV !== "production" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null) {
    const { composeWithDevTools } = require("redux-devtools-extension");
    middle = composeWithDevTools(applyMiddleware(thunk));
}

const store = createStore(rootReducer, middle);

export default store;
