import {GET_APPLICANT_SUCCESS, GET_APPLICANT_RESUME_SUCCESS,GET_APPLICANT_REPORT_SUCCESS,VIEW_APPLICANT_REPORT_SUCCESS, GET_APPLICANT_VIDEO_SUCCESS, PLAY_APPLICANT_VIDEO_SUCCESS} from "../actions/types";

const initialState = {
    applicant: null,
    applicant_resume: null,
    applicant_report: null,
    applicant_video:[],
    play_applicant_video:[],
    };

const applicant = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_APPLICANT_SUCCESS:
            return {
                ...state,
                applicant: payload,
            };

        case GET_APPLICANT_RESUME_SUCCESS:
            return {
                ...state,
                applicant_resume: payload,
            };

        case GET_APPLICANT_REPORT_SUCCESS:
            return {
                ...state,
                applicant_report: payload,
            };
        case VIEW_APPLICANT_REPORT_SUCCESS:
            return {
                ...state,
                applicant_report: payload,
            };
            case GET_APPLICANT_VIDEO_SUCCESS:
                return {
                    ...state,
                    applicant_video: payload,
                };
                case PLAY_APPLICANT_VIDEO_SUCCESS:
                    return {
                        ...state,
                        play_applicant_video: payload,
                    };
        
        default:
            return state;
    }
};
export default applicant;
