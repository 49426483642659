import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Stack,
  Card,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Slider,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import {
  getAllSkills,
  submitSkills,
  deleteEvSkills,
} from "../../redux/actions/dashboard";
import { skillsLevels, getSkillLevel } from "../../utils/getSkillLevelName";
import { authUser } from "../../redux/actions/auth";
import { submitSelfEvaluation } from "../../redux/actions/dashboard";
import AddIcon from "@mui/icons-material/Add";
export const AddSkills = ({
  getAllSkills,
  skills,
  submitSkills,
  submitSelfEvaluation,
  auth,
  authUser,
  deleteEvSkills,
  currentUserDetails
}) => {
  const navigate = useNavigate();
  const [skillOptions, setSkillOptions] = useState([]);
  const skillObj = {
    id: uuidv4(),
    ses_skills_id: "",
    ses_skills_name: "",
    ses_skills_level: 0,
    ses_skills_exp: 0,
    ses_id: "",
    isDeleted: false,
  };
  const [skillList, setSkillList] = useState([skillObj]);
  const [se_id, setse_id] = useState("");
  const [name, setname] = useState("");
  const [overallExp, setOverallExp] = useState("");
  const [relevantExp, setRelevantExp] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [overallExpError, setOverallExpError] = useState("");
  const [relevantExpError, setRelevantExpError] = useState("");
  const [isNewDelete, setNewDelete] = useState(0);

  useEffect(() => {
    if (currentUserDetails && currentUserDetails.exp) {
      setOverallExp(currentUserDetails.exp);
    }
    if (currentUserDetails && currentUserDetails.rel_exp) {
      setRelevantExp(currentUserDetails.rel_exp);
    }
    
  }, [currentUserDetails]);

  const onChangeName = (e) => {
    setname(e.target.value);
    setUsernameError("");
  };

  const onChangeOverallExp = (e) => {
    setOverallExp(e.target.value);
    setOverallExpError("");
  };

  const onChangeRelevantExp = (e) => {
    setRelevantExp(e.target.value);
    setRelevantExpError("");
  };

 

  const submitExpData = async () => {
    // console.log("manish");
    let reqBody = new FormData();
    if (name === "") {
      setUsernameError("Please enter a name");
      return;
    }
    if (overallExp === "") {
      setOverallExpError("Please enter an overall experience");
      return;
    }
    if (relevantExp === "") {
      setRelevantExpError("Please enter relevant experience");
      return;
    }
    if (relevantExp > overallExp) {
      setRelevantExpError(
        "Relevant experience not more than the overall experience"
      );
      return;
    }
    // if (file) {
    // reqBody.append(`se_cv`, file, file.name);
    reqBody.append(`se_name`, name);
    reqBody.append(`se_overall_experience`, overallExp);
    reqBody.append(`se_relevant_experience`, relevantExp);
    await submitSelfEvaluation(reqBody);
    // } else {
    //   alert("Please upload your CV");
    //   return;
    // }
  };
  useEffect(() => {
    getAllSkills();
    authUser();
  }, [isNewDelete]);
  useEffect(() => {}, []);
  useEffect(() => {
    //console.log("Auth======>", auth);
    if (auth) {
      setse_id(auth?._selfEval[0]?.se_id);
      setname(auth?._selfEval[0]?.se_name);
      setOverallExp(auth?._selfEval[0]?.se_overall_experience);
      setRelevantExp(auth?._selfEval[0]?.se_relevant_experience);
    }
    // console.log("CheckExistSkill", auth?._skills);
    if (auth?._skills?.length) {
      let skillsExistSkillList = auth._skills.map((skill) => {
        return { ...skill, id: uuidv4() };
      });
      setSkillList(skillsExistSkillList);
    }
  }, [auth]);
  const addSkill = () => {
    setSkillList([skillObj, ...skillList]);
  };

  useEffect(() => {
    if (skills && skills.length > 0) {
      let tempSkills = skills.map((skill) => {
        return { label: skill.sl_name, value: skill.sl_id };
      });
      setSkillOptions(tempSkills);
    }
  }, [skills]);

  useEffect(() => {
    let tempSkills = [];
    if (skillList.length > 0) { 
      tempSkills = skillOptions.filter((skill) => {
        let checkSkillExist = skillList.find((s) => s.ses_skills_id === skill.value);
        if (!checkSkillExist) {
          return skill;
        }
      });
    }
    setSkillOptions(tempSkills);
  }, [skillList])

  const onSelectSkill = (e, id, s_id) => {
    console.log("SelectedSkill", id, s_id);
    const checkSkillExist = skillList.find(
      (skill) => skill.ses_skills_id == e.value
    );
    if (checkSkillExist) {
      alert("Skill already exists");
      const tempSkillList = skillList.map((skill) => {
        if (skill.id === id) {
          skill.ses_skills_name = "";
          skill.ses_skills_id = "";
        }
        return skill;
      });
      setSkillList(tempSkillList);
      return false;
    }
    const tempSkillList = skillList.map((skill) => {
      if (skill.id === id) {
        skill.ses_skills_name = e.label;
        skill.ses_skills_id = e.value;
      }
      return skill;
    });
    setSkillList(tempSkillList);
  };

  function onSelectLevel(e, value, id) {
    e.preventDefault();
    // console.log(value, id);
    let newValue = parseInt(value) / 25;

    const tempSkillList = skillList.map((skill) => {
      if (skill.id === id) {
        skill.ses_skills_level = newValue + 1;
      }
      return skill;
    });
    setSkillList(tempSkillList);
  }

  const onChangExp = (e, id) => {
    e.preventDefault();
    if (e.target.value > overallExp) {
      alert("Skill experice not greater than overall experience");
      return;
    }
    const tempSkillList = skillList.map((skill) => {
      if (skill.id === id || skill.ses_id === id) {
        skill.ses_skills_exp = e.target.value;
      }
      return skill;
    });
    setSkillList(tempSkillList);
  };

  const deleteSkill = async (id, ses_id) => {
    if (ses_id) {
      await deleteEvSkills(ses_id);
      let increment = isNewDelete + 1;
      setNewDelete(increment);
    }
    const tempSkillList = skillList.filter((skill) => skill.id !== id);
    // const tempSkillList = !ses_id ? skillList.filter((skill) => skill.id !== id) : skillList.map((skill) => { if (skill.ses_id === ses_id) { return { ...skill, isDeleted: true } } else { return { ...skill } } });
    setSkillList(tempSkillList);
  };

  const submitData = async () => {
    if (skillList.length === 0) {
      alert("Please add skills");
      return;
    } else {
      let reqBody = { skills: skillList, se_id: se_id };
      await submitSkills(reqBody);
      if (skillList.length === 1 && skillList[0].isDeleted) {
        setSkillList([]);
      }
    }
  };

  const evalualteSkills = async () => {
    // console.log("Skill List===>", skillList);
    if (skillList.length === 0) {
      alert("Please add skills");
      return;
    } else {
      let reqBody = { skills: skillList, se_id: se_id };
      await submitSkills(reqBody);
      if (skillList.length === 1 && skillList[0].isDeleted) {
        setSkillList([]);
      } else {
        navigate("/employee/self-assessment/skill-assesment");
      }
    }
  };
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="h5">Experience </Typography>
            <Typography sx={{ mt: 0.5, ml: 1 }}> (years)</Typography>
          </Stack>

          <Divider />
          <Stack
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {/* Hidden Name Field */}
            <TextField
              id="name"
              name="name"
              value={name}
              onChange={onChangeName}
              size="small"
              variant="outlined"
              aria-label="Employee Name"
              placeholder="Enter your name"
              inputProps={{
                autoComplete: "off",
                "aria-label": "Employee Name",
              }}
              label="Employee Name"
              error={!!usernameError}
              helperText={usernameError}
              sx={{ display: "none" }}
            />

            {/* Overall Experience Field */}
            <Stack direction="row" alignItems="center" width={250}>
              <label htmlFor="overallExp" style={{ marginRight: "8px" }}>
                Overall Experience
              </label>
              <TextField
                id="overallExp"
                type="number"
                name="overallExp"
                value={overallExp}
                onChange={onChangeOverallExp}
                size="small"
                variant="outlined"
                aria-label="Overall Experience"
                placeholder="Overall experience"
                inputProps={{
                  autoComplete: "off",
                  "aria-label": "Overall Experience",
                  step: "0.5",
                  style: { textAlign: "right" },
                }}
                InputProps={{
                  style: {
                    width: "90px",
                    minWidth: "90px",
                    padding: "0",
                  },
                }}
                label=""
                error={!!overallExpError}
                // helperText={overallExpError}
              />
            </Stack>

            {/* Relevant Experience Field */}
            <Stack direction="row" alignItems="center" width={250}>
              <label htmlFor="relevantExp" style={{ marginRight: "8px" }}>
                Relevant Experience
              </label>
              <TextField
                id="relevantExp"
                type="number"
                name="relevantExp"
                value={relevantExp}
                onChange={onChangeRelevantExp}
                size="small"
                variant="outlined"
                aria-label="Relevant Experience"
                placeholder="Relevant experience"
                inputProps={{
                  autoComplete: "off",
                  "aria-label": "Relevant Experience",
                  step: "0.5",
                  style: { textAlign: "right" },
                }}
                InputProps={{
                  style: {
                    width: "90px",
                    minWidth: "90px",
                    padding: "0",
                  },
                }}
                label=""
                error={!!relevantExpError}
                // helperText={relevantExpError}
              />
            </Stack>

            {/* Update Button */}
            <Button
              color="primary"
              component="label"
              variant="contained"
              tabIndex={-1}
              onClick={submitExpData}
              sx={{ marginLeft: "16px" }}
            >
              Update
            </Button>
          </Stack>
          <Stack>
            {relevantExpError && (
              <div>
                <p style={{ textAlign: "center", color: "red" }}>
                  *{relevantExpError}
                </p>
              </div>
            )}
            {overallExpError && (
              <div>
                <p style={{ textAlign: "center", color: "red" }}>
                  *{overallExpError}
                </p>
              </div>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Card sx={{ width: "100%" }}>
        {/* <CardHeader
          sx={{ paddingBottom: 0 }}
          title={"Skills"}
        /> */}
        {/* <CardHeader
         
          title={"Skills"}
          action={
            <IconButton aria-label="Add Skill" onClick={addSkill} sx={{
              backgroundColor: '#cee5fd', // Example background color
              width: '48px', // Example width
              height: '48px', // Ensure the button is a square
              '&:hover': {
                backgroundColor: '#115293', // Darker shade on hover
                color: '#FFFFFF'
              }
            }}>
              <AddIcon />
            </IconButton>
           
          }
        /> */}
        <CardContent
          sx={{
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
           
            }}
          >
            <Typography variant="h5">Skills</Typography>
            <Button
              onClick={addSkill}
              variant="text"
              color="primary"
              size={"small"}
            >
              <AddIcon /> Add
            </Button>
          </Stack>
          <Divider />

          <Stack>
            {/* <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Button
                color="primary"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                onClick={addSkill}
              >
                +
              </Button>
            </Stack> */}

            {/* {skillList && skillList.length > 0 ? ( */}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ width: "100%", mb: 35 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width={20}>
                      SN
                    </TableCell>
                    <TableCell width={550} align="left">
                      Skill Name
                    </TableCell>
                    <TableCell align="left" width={450}>
                      Skill Level
                    </TableCell>
                    <TableCell align="left" width={150}>
                      Experience in years
                    </TableCell>
                    <TableCell align="left" width={20}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skillList.map((row, index) =>
                    !row?.isDeleted ? (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          <Select
                            name="skill"
                            options={skillOptions && skillOptions}
                            searchable={true}
                            openMenuOnFocus={true}
                            defaultValue={{
                              label: row.ses_skills_name,
                              value: row.ses_skills_id,
                            }}
                            onChange={(e) => onSelectSkill(e, row.id)}
                            isDisabled={row.ses_id}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {getSkillLevel(parseInt(row?.ses_skills_level))}
                          </Typography>
                          <Box>
                            <Slider
                              aria-label="Skill Level"
                              defaultValue={
                                (parseInt(row?.ses_skills_level) - 1) * 25
                              }
                              step={null}
                              marks={skillsLevels}
                              onChange={(e, value) =>
                                onSelectLevel(e, value, row.id)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            type="number"
                            id="outlined-basic"
                            name={row.ses_skills_exp}
                            value={row.ses_skills_exp}
                            onChange={(e) =>
                              onChangExp(e, row.ses_id ? row.ses_id : row.id)
                            }
                            hiddenLabel
                            size="small"
                            variant="outlined"
                            aria-label="Experience"
                            placeholder="Experience in years"
                            inputProps={{
                              autoComplete: "off",
                              ariaLabel: "Experience",
                              step: "0.5",
                              min: 0,
                              style: { textAlign: "right" },
                            }}
                            InputProps={{
                              style: {
                                textAlign: "right",
                                width: "70px",
                                minWidth: "70px",
                                padding: "0",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => deleteSkill(row?.id, row?.ses_id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* ) 
            : (
              ""
            )
            } */}
          </Stack>

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              mt: 3,
              justifyContent: "space-between",
            }}
          >
            <Button
              color="primary"
              component="label"
              variant="contained"
              tabIndex={-1}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              color="primary"
              component="label"
              variant="contained"
              tabIndex={-1}
              onClick={evalualteSkills}
              disabled={
                !name ||
                !overallExp ||
                !relevantExp ||
                skillList.length === 0 ||
                (skillList.length === 1 && skillList[0].isDeleted) ||
                (skillList.length === 1 && skillList[0].ses_skills_name === "")
              }
            >
              Save & Evaluate
            </Button>
            <Button
              color="primary"
              component="label"
              variant="contained"
              tabIndex={-1}
              onClick={submitData}
              disabled={
                !name ||
                !overallExp ||
                !relevantExp ||
                skillList.length === 0 ||
                (skillList.length === 1 &&
                  skillList[0].ses_skills_name === "") ||
                (skillList.length === 1 && skillList[0].isDeleted)
              }
            >
              Save
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.userData,
  skills: state.dashboard.skills,
  currentUserDetails: state?.auth.userProfile,
});

const mapDispatchToProps = {
  getAllSkills,
  submitSkills,
  submitSelfEvaluation,
  authUser,
  deleteEvSkills,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSkills);
