import {
  GET_APPLICANT_SUCCESS,
  GET_APPLICANT_RESUME_SUCCESS,
  GET_APPLICANT_REPORT_SUCCESS,
  VIEW_APPLICANT_REPORT_SUCCESS,
  GET_APPLICANT_VIDEO_SUCCESS,
  PLAY_APPLICANT_VIDEO_SUCCESS,
  DELETE_APPLICANT_SUCCESS,
} from "./types";
import {
  _getApplicant,
  _getApplicantResume,
  _getApplicantVideo,
  _playApplicantVideo,
  _getApplicantReport,
  _viewApplicantReport,
  _deleteApplicant,
} from "../../services/applicant.service";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";
import { getCandidates } from "./candidate";


export const getApplicant = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getApplicant(id);

    if (response.status === 200) {
      dispatch({
        type: GET_APPLICANT_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting applicant", error);
    dispatch(hideLoading());
  }
};

export const getApplicantResume = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getApplicantResume(id);

    if (response.status === 200) {
      dispatch({
        type: GET_APPLICANT_RESUME_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting applicant", error);
    dispatch(hideLoading());
  }
};

export const getApplicantReport = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getApplicantReport(id);

    if (response.status === 200) {
      dispatch({
        type: GET_APPLICANT_REPORT_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting applicant", error);
    dispatch(hideLoading());
  }
};

export const viewApplicantReport = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _viewApplicantReport(id);
    if (response.status === 200) {
      dispatch({
        type: VIEW_APPLICANT_REPORT_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting applicant", error);
    dispatch(hideLoading());
  }
};

export const getApplicantVideo = (userId) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getApplicantVideo(userId);

    if (response.status === 200) {
      dispatch({
        type: GET_APPLICANT_VIDEO_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting applicant video list", error);
    dispatch(hideLoading());
  }
};

export const playApplicantVideo =
  (recording_id, file_type) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await _playApplicantVideo(recording_id, file_type);

      if (response.status === 200) {
        dispatch({
          type: PLAY_APPLICANT_VIDEO_SUCCESS,
          payload: response.data,
        });
      }
      dispatch(hideLoading());
    } catch (error) {
      console.error("error in playing applicant video", error);
      dispatch(hideLoading());
    }
  };

export const deleteApplicant = (id, jb_id, page, size) => async (dispatch) => { 
  try {
    dispatch(showLoading());
    const response = await _deleteApplicant(id);

    if (response.status === 200) {

      // dispatch({
      //   type: DELETE_APPLICANT_SUCCESS,
      //   payload: response.data,
      // });
      dispatch(setMessage("Applicant deleted successfully"));
      dispatch(getCandidates(jb_id, page, size));

    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in deleting applicant", error);
    dispatch(hideLoading());
  }
}



