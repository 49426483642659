import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./styles/index.css";
import { I18nextProvider } from "react-i18next";
import  "./i18n";

const orgId = localStorage.getItem("orgId"); 

const initApp = async () => {
  // const i18n = await initializeI18n(orgId); // Initialize i18n with the orgId

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
      {/* <I18nextProvider i18n={i18n}> */}
        <App />
      {/* </I18nextProvider> */}
    </Provider>
  );
};

initApp();
