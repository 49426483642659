import axios from "axios";

const API_BASE_URL = "/email-templates";

export const getAllTemplates = async (queryParams = "") => {
    try {
      const response = await axios.get(`${API_BASE_URL}${queryParams}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching email templates:", error);
      throw error;
    }
  };

export const getTemplateById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/${id}`);
  return response.data;
};

export const createTemplate = async (templateData) => {
  const response = await axios.post(API_BASE_URL, templateData);
  return response.data;
};

export const updateTemplate = async (id, templateData) => {
  const response = await axios.put(`${API_BASE_URL}/${id}`, templateData);
  return response.data;
};

export const deleteTemplate = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/${id}`);
  return response.data;
};
