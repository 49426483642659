import axios from "axios";

let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');
axios.defaults.baseURL = `${BASE_URL}/${orgName ? orgName + '/' : ''}api/v1`

export const setAuthToken = (token) => {
  let userToken = localStorage.getItem('token') || token
  if (userToken) {
    axios.defaults.headers.common["x-api-key"] = `${userToken}`;
  } else {
    delete axios.defaults.headers.common["x-api-key"];
  }
};
