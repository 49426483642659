import React from "react";
import { connect } from "react-redux";
import SkillsPieChart from "../../components/dataCharts/SkillsPieChart";
import FeatureStateChart from "../../components/dataCharts/FeatureStateChart";
import EmployeeStateScatterChart from "../../components/dataCharts/EmployeeStateScatterChart";

export const SkillGapAnalysis = (props) => {
  return (
    <>
      <EmployeeStateScatterChart />
      <SkillsPieChart />
      {/* <FeatureStateChart /> */}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SkillGapAnalysis);
