import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Box,
  Grid,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Save";
import { saveQuestionSetAction } from "../../redux/actions/questions";
import { useNavigate, useParams } from "react-router-dom";
import McqQuestion from "./question-set/McqQuestion";
import CodingQuestion from "./question-set/CodingQuestion";
import AiQuestion from "./question-set/AiQuestion";
import { getQuestionSetDetail } from "../../redux/actions/questions";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CreateQuestionSet = ({
  saveQuestionSetAction,
  question_set,
  getQuestionSetDetail,
}) => {
  const { id: qs_id } = useParams();
  const navigate = useNavigate();
  const [mcqs, setMcqs] = useState([]);
  const [codingQuestions, setCodingQuestions] = useState([]);
  const [aiQuestions, setAiQuestions] = useState([]);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ setName: "" });
  const [file, setFile] = useState(null);

  const handleBulkUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Utility function to rename keys and handle unique IDs
      const renameKeys = (data, keyMapping, addUuidKey = false) => {
        return data.map((row) => {
          return Object.keys(row).reduce((acc, key) => {
            const newKey = keyMapping[key] || key; // Use mapped key or keep original
            acc[newKey] = key === "S.No" && addUuidKey ? uuidv4() : row[key];
            return acc;
          }, {});
        });
      };

      // Transform MCQ data into required structure
      const transformMCQData = (mcqData) =>
        mcqData.map((row) => ({
          q_id: uuidv4(), // Generate unique ID for each question
          q_name: row["Question"] || "", // Map "Question" to "q_name"
          q_answer: row["Answer"] || "", // Map "Answer" to "q_answer"
          options: [
            { a_label: "A", a_text: row["Option A"] || "" },
            { a_label: "B", a_text: row["Option B"] || "" },
            { a_label: "C", a_text: row["Option C"] || "" },
            { a_label: "D", a_text: row["Option D"] || "" },
          ],
        }));

      // Key mappings for Coding and AI Interview sheets
      const commonKeyMapping = {
        "S.No": "q_id",
        Question: "q_name",
        Time: "q_time",
      };

      // Parse each sheet
      const mcqSheet = workbook.Sheets["MCQ"];
      const codingSheet = workbook.Sheets["Coding"];
      const aiInterviewSheet = workbook.Sheets["AI Interview"];

      // Process MCQ sheet
      const mcqData = mcqSheet ? XLSX.utils.sheet_to_json(mcqSheet) : [];
      const transformedMCQData =
        mcqData.length > 0 ? transformMCQData(mcqData) : [];

      if (transformedMCQData.length > 0) setMcqs(transformedMCQData);

      // Process Coding sheet
      const codingData = codingSheet
        ? renameKeys(
            XLSX.utils.sheet_to_json(codingSheet),
            commonKeyMapping,
            true
          )
        : [];

      if (codingData.length > 0) setCodingQuestions(codingData);

      // Process AI Interview sheet
      const aiInterviewData = aiInterviewSheet
        ? renameKeys(
            XLSX.utils.sheet_to_json(aiInterviewSheet),
            commonKeyMapping,
            true
          )
        : [];
      if (aiInterviewData.length > 0) setAiQuestions(aiInterviewData);
    };

    reader.onerror = (error) => {
      console.error("File reading error:", error);
    };

    reader.readAsArrayBuffer(file);
  };

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleBulkUpload(selectedFile); // Call bulk upload function
    }
  };
  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDownloadSample = () => {
    // Define data for each sheet
    const mcqSheetData = [
      [
        "S.No",
        "Question",
        "Option A",
        "Option B",
        "Option C",
        "Option D",
        "Answer",
      ],
      [
        1,
        "What is the capital of France?",
        "Paris",
        "London",
        "Berlin",
        "Madrid",
        "Paris",
      ],
    ];

    const codingSheetData = [
      ["S.No", "Question", "Time"],
      [1, "Write a function to check if a number is prime.", 3],
    ];

    const aiInterviewSheetData = [
      ["S.No", "Question", "Time"],
      [1, "Explain your approach to solving complex team conflicts.", 5],
    ];

    // Create a workbook
    const workbook = XLSX.utils.book_new();

    // Add sheets to the workbook
    const mcqSheet = XLSX.utils.aoa_to_sheet(mcqSheetData);
    const codingSheet = XLSX.utils.aoa_to_sheet(codingSheetData);
    const aiInterviewSheet = XLSX.utils.aoa_to_sheet(aiInterviewSheetData);

    // Append sheets to the workbook
    XLSX.utils.book_append_sheet(workbook, mcqSheet, "MCQ");
    XLSX.utils.book_append_sheet(workbook, codingSheet, "Coding");
    XLSX.utils.book_append_sheet(workbook, aiInterviewSheet, "AI Interview");

    // Write the workbook and trigger the download
    XLSX.writeFile(workbook, "Sample_Questions.xlsx");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveQuestionSet = () => {
    if (formData.setName) {
      const dataToSave = {
        setName: formData.setName,
        questions: [
          ...mcqs
            .filter((q) => q.q_name)
            .map((mcq) => ({ ...mcq, type: "mcq" })),
          ...codingQuestions
            .filter((q) => q.q_name)
            .map((coding) => ({ ...coding, type: "coding" })),
          ...aiQuestions
            .filter((q) => q.q_name)
            .map((ai) => ({ ...ai, type: "interview" })),
        ],
      };

      saveQuestionSetAction(dataToSave);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (qs_id) {
      getQuestionSetDetail(qs_id);
    }
  }, [qs_id]);

  useEffect(() => {
    if (qs_id && question_set?.mcqQuestions) {
      setMcqs(question_set.mcqQuestions);
    }
    if (qs_id && question_set?.codeQuestions) {
      setCodingQuestions(question_set.codeQuestions);
    }
    if (qs_id && question_set?.aiQuestions) {
      setAiQuestions(question_set.aiQuestions);
    }
    if (qs_id && question_set?.questionSet) {
      setFormData({ ...formData, setName: question_set.questionSet.qs_name });
    }
  }, [question_set]);

  return (
    <>
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">
              {qs_id ? t("Question Set") : t("Create Question Set")}
            </Typography>
            {!qs_id && (
              <Button
                variant="text"
                size={"small"}
                sx={{ color: "green" }}
                onClick={saveQuestionSet}
              >
                <AddIcon /> Save
              </Button>
            )}
          </Box>
          <Divider />
          {/* <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {qs_id ? formData.setName : (<TextField
                                name="setName"
                                placeholder="Question Set Name"
                                value={formData.setName}
                                onChange={handleNameChange}
                            />)}
                        </Grid>
                    </Grid> */}
          <Grid container spacing={2} sx={{ p: 2, alignItems: "center" }}>
            <Grid item xs={2}>
              <Typography variant="subtitle2" fontSize={14}>
                Name:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {qs_id ? (
                formData.setName
              ) : (
                <TextField
                  name="setName"
                  placeholder="Question Set Name"
                  value={formData.setName}
                  onChange={handleNameChange}
                  fullWidth
                />
              )}
            </Grid>
            {!qs_id && (
              <Grid item xs={6} container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button variant="outlined" onClick={handleDownloadSample}>
                    {" "}
                    Download Sample
                  </Button>
                </Grid>
                <Grid item>
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                    id="bulk-upload-input"
                  />
                  <label htmlFor="bulk-upload-input">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Bulk Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label={`MCQ (${mcqs.length})`} {...a11yProps(0)} />
                  <Tab
                    label={`Coding (${codingQuestions.length})`}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={`AI Interview (${aiQuestions.length})`}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <McqQuestion mcqs={mcqs} setMcqs={setMcqs} qs_id={qs_id} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <CodingQuestion
                  codingQuestions={codingQuestions}
                  setCodingQuestions={setCodingQuestions}
                  qs_id={qs_id}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <AiQuestion
                  aiQuestions={aiQuestions}
                  setAiQuestions={setAiQuestions}
                  qs_id={qs_id}
                />
              </CustomTabPanel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  question_set: state.questions.question_set,
});

const mapDispatchToProps = {
  saveQuestionSetAction,
  getQuestionSetDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionSet);
