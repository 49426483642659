export const camalize = (str) => {
  let words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
  }

  return words.join(" ");
};

export const password_validate = (password) => {
  var re = {
    capital: /(?=.*[A-Z])/,
    length: /(?=.{8,40}$)/,
    specialChar: /[ -\/:-@\[-\`{-~]/,
    digit: /(?=.*[0-9])/,
  };
  return (
    re.capital.test(password) &&
    re.length.test(password) &&
    re.specialChar.test(password) &&
    re.digit.test(password)
  );
};

export const email_validate = (email) => {
  const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
  if (regEx.test(email)) {
    return true;
  } else if (!regEx.test(email) && email !== "") {
    return false;
  } else {
    return true;
  }
};

export const company_name_validate = (company_name) => {
  const regEx = /^[a-zA-Z0-9\s]+$/;
  company_name = company_name.trim();
  if (company_name === "") {
    return false;
  }

  let words = company_name.split("");

  if (isNaN(words[0]) && regEx.test(company_name)) {
    return true;
  } else {
    return false;
  }
};

export const fixToTwoDecimal = (num) => {
  try {
    if (
      num === null ||
      num === undefined ||
      num === "" ||
      num.isNaN ||
      num.subString
    ) {
      return 0;
    } else {
      return num.toFixed(2);
    }
  } catch (error) {
    return 0
  }
  
};

export const getSkillLevel = (value) => {
  if (value > 90) return "Expert";
  if (value > 75 && value <=90) return "Specialist";
  if (value > 60 && value <= 75) return "Intermediate";
  if (value <= 60 ) return "Beginner";
}

export const formatter  = Intl.NumberFormat('en', { notation: 'compact' });

export const getNearvalue = (num, div) => {
  return Math.round(num / div);
};
