import { SET_MESSAGE, CLEAR_MESSAGE} from "../actions/types";

const initialState = [];

export default function message(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return [...state, payload];
    case CLEAR_MESSAGE:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
}
