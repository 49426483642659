import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { getCourse } from "../../redux/actions/dashboard";

const SuggestedCourses = ({ getCourse, userData, courseContent }) => {
  const { skill_name, skill_level } = useParams();

  useEffect(() => {
    if (skill_name && skill_level) {
      fetchCourses(skill_name, skill_level);
    }
  }, [skill_name, skill_level]);

  const fetchCourses = async (name, level) => {
    // const data = {
    //   skill_name: name,
    //   skill_level: level,
    // };
    const data = {
      skill_name: "Apex",
      skill_level: "advance",
    };

    getCourse(data);
  };

  const renderContent = () => {
    return courseContent?.split("\\n\\n").map((paragraph, index) => {
      if (paragraph.startsWith("\\n Topic:")) {
        return <h2 key={index}>{paragraph.replace("\\n Topic: ", "")}</h2>;
      } else if (
        paragraph.startsWith("Understanding") ||
        paragraph.startsWith("Apex Trigger Components") ||
        paragraph.startsWith("Apex Trigger Best Practices") ||
        paragraph.startsWith("Conclusion")
      ) {
        return <h3 key={index}>{paragraph}</h3>;
      } else if (paragraph.match(/^\d+\. /)) {
        return <li key={index}>{paragraph}</li>;
      } else {
        return <p key={index}>{paragraph?.replace(/\\n/g, "")}</p>;
      }
    });
  };

  return (
    <Stack
      direction={{
        xs: "column",
        sm: "column",
        width: "100%",
      }}
    >
      <Stack
        direction={{ xs: "row", sm: "row" }}
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Typography variant="h2" sx={{ textAlign: "center" }} mb={4}>
          AI Suggested Courses
        </Typography>
      </Stack>

      <div className="pretty-text">{renderContent()}</div>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  courseContent: state.dashboard?.courses?.content[0]?.module?.replace(
    /^\n+/,
    ""
  ),
});

const mapDispatchToProps = { getCourse };

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedCourses);
