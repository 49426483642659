import axios from "axios";

export const getSkills = async (data) => {
  try {
    const url = `/skills/getAllSkills`;
    const response = await axios.get(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};