import {
  GET_BATCH_SUCCESS,
  UPDATE_BATCH_TITLE_SUCCESS,
  DELETE_BATCHES_SUCCESS,
  GET_JD_BATCHES_SUCCESS,
  GET_BATCHES_SUCCESS,
  GET_BATCH_PROGRESS_SUCCESS,
  GET_MODEL_NAME_WITH_JOBID_SUCCESS
} from "./types";
import {
  _getBatch,
  _updateBatchTitle,
  _deleteBatch,
  _getAllBatches,
  _getBatchProgress,
  _getBatchNameByJobId,
} from "../../services/batch.service";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";


export const getBatches = (id, page, size, filters, search) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getAllBatches(id, page, size, filters, search);

    if (response.status === 200) {
      if (id) {
        dispatch({
          type: GET_JD_BATCHES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_BATCHES_SUCCESS,
          payload: response.data,
        });
      }
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting batches", error);
    dispatch(hideLoading());
  }
};

export const getBatch = (id) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _getBatch(id);
    
        if (response.status === 200) {
        dispatch({
            type: GET_BATCH_SUCCESS,
            payload: response.data,
        });
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in getting batch", error);
        dispatch(hideLoading());
    }
};
    
export const getBatchProgress = (id) => async (dispatch) => {
  try {
    // dispatch(showLoading());
    const response = await _getBatchProgress(id);

    if (response.status === 200) {
      dispatch({
        type: GET_BATCH_PROGRESS_SUCCESS,
        payload: response.data,
      });
    }
    // dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting batch", error);
    dispatch(hideLoading());
  }
};


export const updateBatchTitle = (id, data) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _updateBatchTitle(id, data);
    
        if (response.status === 200) {
        dispatch({
            type: UPDATE_BATCH_TITLE_SUCCESS,
            payload: response.data,
        });
        dispatch(setMessage("Batch title updated successfully!"));
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("Error in updating batch title", error);
        dispatch(hideLoading());
    }
};
    
export const deleteBatch = (id, jdId, page, size) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _deleteBatch(id);
    
        if (response.status === 200) {
        dispatch({
            type: DELETE_BATCHES_SUCCESS,
            payload: response.data,
        });
        dispatch(getBatches(jdId, page, size));
        dispatch(setMessage("Batch deleted successfully!"));
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("Error in deleting batch", error);
        dispatch(hideLoading());
    }
}

export const getModelNameWithJobId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getBatchNameByJobId(id);

    if (response.status === 200) {
      dispatch({
        type: GET_MODEL_NAME_WITH_JOBID_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting model name with job id", error);
    dispatch(hideLoading());
  }
};