import { Route, Routes } from "react-router-dom";
import SiginIn from "../pages/common/SiginIn";
import SignUp from "../pages/common/SignUp";
import VerifyOtp from "../pages/common/VerifyOtp";
import ForgotPassword from "../pages/common/ForgotPassword";
import ResetPassword from "../pages/common/ResetPassword";
import { Navigate } from "react-router-dom";
import CandidateSignin from "../pages/common/CandidateSignin";

const PublicRoute = () => {
  // console.log("PublicRoute");
  return (
    <Routes>
      <Route path="/signin" element={<SiginIn />} exact />
      <Route path="/signup" element={<SignUp />} exact />
      <Route path="/forgot-password" element={<ForgotPassword />} exact />
      <Route path="/verify-otp" element={<VerifyOtp />} />
      <Route path="/reset-password/" element={<ResetPassword />} />
      <Route path="/:org_name/candidate-signin" element={<CandidateSignin />}></Route>
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </Routes>
  );
};

export default PublicRoute;
