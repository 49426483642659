import React, {useState, useEffect} from "react";
import Plot from "react-plotly.js";
import {getSkillExperienceData} from "../../services/chart.service";
import { styled} from "@mui/material/styles";

const CustomPlot = styled(Plot)(({theme}) => ({
    width: "45%",
    height: "45%",
    maxHeight: "400px",
    backgroundColor: 'transparent',
    marginTop: theme.spacing(2),
    "& .plot-container .svg-container svg": {
        borderRadius: theme.shape.borderRadius,
    }

}));



const SkillExperienceDynamicPieChart = () => {
    const [data, setData] = useState();

    const fetchSkillExperienceData = async () => {
        try {
            const response = await getSkillExperienceData();
            setData(response.data.data);
        } catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        fetchSkillExperienceData();
    }, []);

    let plotdata = [
        {
            values: data?.values,
            labels: data?.labels.map((l) => l.length > 20 ? l.substring(0, 20) + "..." : l),
            hovertemplate: "",
            textinfo: "",
            texttemplate: "%{label}",
            scale: .5,
            type: "pie",
            automargin: true,
            textposition: "outside",
            textfont: {
                size: 10,
            }
        }, {
            values: data?.values,
            labels: data?.labels,
            hovertemplate: "Skill_Name = %{label}<br>Skill_Experience = %{value}<br>Percent = %{percent}<extra></extra>",
            texttemplate: "%{percent}",
            textposition: "inside",
            type: "pie",
            automargin: true,

        }
    ];
    let layout = {
        title: "Skills Experience",
        // margin: { t: 50, b: 0, l: 10, r: 0 },
        showlegend: false,
        textposition: "outside",
        labeltext: "%{label}",
    };

      let config = {
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
        showLink: false,
      };

    return (
      <>
        {plotdata && plotdata ? (
          <CustomPlot data={plotdata} layout={layout} config={config} />
        ) : (
          ""
        )}
      </>
    );
};

export default SkillExperienceDynamicPieChart;
