import axios from "axios";

export const _getEmployeesProfileDetail = async (id) => {
  try {
    const url = `/employees/${id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _updateEmployeeProfileDetail = async (data, id) => {
  try {
    const url = `/employees/${id}`;
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _startInterview = async (se_id, skills) => {
  try {
    const start_interview_url = `/employees/start_interview`;
    const response = await axios.post(start_interview_url, { se_id, skills });
    return response;
  } catch (error) {
    throw error;
  }
};

export const _submitAns = async (data) => {
  try {

    const submit_interview_url = `/employees/submit_interview_question`;
    const response = await axios.post(submit_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _endInterview = async (data) => {
  try {
    const end_interview_url = `/employees/conclude_interview`;
    const response = await axios.post(end_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};
