import { getAppOrgLabels } from "../../services/app_org_labels.service";
import { hideLoading, showLoading } from "./loader";
import { GET_APP_ORG_LABELS_FAIL, GET_APP_ORG_LABELS_SUCCESS } from "./types";

export const getAppLabels = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getAppOrgLabels();

    if (response.status === 200) {
      dispatch({
        type: GET_APP_ORG_LABELS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_APP_ORG_LABELS_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch({
      type: GET_APP_ORG_LABELS_FAIL,
    });
  }
};