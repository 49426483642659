import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { getCurrentStateChartData } from "../../services/chart.service";
import jsonData from "./layout.json";
import { Card } from "@mui/material";

const EmployeeStateScatterChart = () => {
  //   );

  const [employeeStateData, setEmployeeStateData] = useState();

  useEffect(() => {
    fetchCurrentStateChartData();
  }, []);

  const fetchCurrentStateChartData = async () => {
    try {
      const response = await getCurrentStateChartData();
      setEmployeeStateData(response?.data);
    } catch (error) {
      throw error;
    }
  };

        let config = {
          responsive: true,
          displayModeBar: true,
          displaylogo: false,
          showLink: false,
        };

  return (
    <>
      {employeeStateData &&
      employeeStateData?.result &&
      employeeStateData?.result?.data ? (
        <Card
          sx={{
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Plot
            data={employeeStateData?.result?.data}
            layout={jsonData.layoutScatter}
            config={config}
          />
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default EmployeeStateScatterChart;
