import { useEffect, useCallback, useState } from "react";
import { toast } from "react-toastify";

const useFullscreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(true);

  const enterFullScreen = useCallback(() => {
    setIsFullScreen(true); // Update state when entering full screen
    const elem = document.documentElement;
    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }

    } catch (error) {
      console.error("Error entering full-screen mode:", error);
      toast.error("Failed to enter full-screen mode. Please try again.");
      setIsFullScreen(false); // Update state when entering full screen
    }
  }, []);

  const handleFullScreenChange = useCallback(() => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false); // Update state when exiting full screen
      // toast.error("You must remain in full-screen mode!");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullScreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  return { isFullScreen, enterFullScreen };
};

export default useFullscreen;
