import { getSkills } from "../../services/skill.service";
import { hideLoading, showLoading } from "./loader";
import { GET_ALL_SKILLS_FAIL, GET_ALL_SKILLS_SUCCESS } from "./types";

export const setSkillData = (data) => {
    return {
      type: 'SET_DATA',
      payload: data,
    };
  };

  export const getAllSkills = (data) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await getSkills(data);
  
      if (response.status === 200) {
        dispatch({
          type: GET_ALL_SKILLS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_ALL_SKILLS_FAIL,
        });
      }
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
  
      console.error("error in getting skills", error);
      dispatch({
        type: GET_ALL_SKILLS_FAIL,
      });
    }
  };