import { styled } from "@mui/material/styles";
import EmployeeRoutes from "./EmployeeRoutes";
import HRRoutes from "./HrRoutes";
import CandidateRoutes from "./CandidateRoutes";
import { useEffect, useRef, useState } from "react";
import { Icon, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const PrivateRoute = ({ open, mode, role, breadCrum, orgName }) => {



  // const ref = useRef();

  // const [pos, setPos] = useState(true);

  // const handleTop = () => {
  //   console.log("handleTop");
  //   ref.current.scrollTop = 0;
  //   setPos(false);
  // };

  // const handleScroll = () => {
  //   if (ref.current.scrollTop > 50) {
  //     if (!pos) setPos(true);
  //   } else {
  //     if (pos) setPos(false);
  //   }
  // };
  return (
    <>
      <Main open={open} mode={mode}>
        <DrawerHeader />
        {/* {breadCrum} */}
        {/* {role === "admin" && <AdminRoutes />} */}
        {/* <Box ref={ref}> */}
        {role === "hr" && <HRRoutes />}
        {role === "employee" && <EmployeeRoutes />}
        {role === "candidate" && <CandidateRoutes />}
        {/* <IconButton>
            <ArrowUpwardIcon onClick={handleTop} />
          </IconButton>
        </Box> */}
      </Main>
    </>
  );
};

export default PrivateRoute;
