import { setSkillData } from "../actions/skills";
import { GET_ALL_SKILLS_SUCCESS } from "../actions/types";
const initialState = {
    data: null,
    allSkills: null,
};

const skills = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case GET_ALL_SKILLS_SUCCESS:
            return {
                ...state,
                allSkills: action.payload.rows,
            };
        default:
            return state;
    }
};
export default skills;