import { useState } from "react";
import { Grid, TextField, Typography, Button, Card } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { f } from "html2pdf.js";
const CodingQuestion = ({ codingQuestions, setCodingQuestions, qs_id }) => {
  const handleAddMoreCodingQuestions = () => {
    setCodingQuestions([...codingQuestions, { q_id: uuidv4(), q_name: "" }]);
  };

  const handleRemoveQuestion = (id) => {
    setCodingQuestions(codingQuestions.filter((q) => q.q_id !== id));
  };

  const handleQuestionChange = (id, value) => {
    setCodingQuestions(
      codingQuestions.map((q) =>
        q.q_id === id ? { ...q, q_name: value } : q
      )
    );
  };

  const handleQuestionTimeChange = (id, value) => {
    setCodingQuestions(
      codingQuestions.map((q) =>
        q.q_id === id ? { ...q, q_time: value } : q
      )
    );
  };

  return (
    <>
      {codingQuestions.map((q) => (
        <Card key={q.id} sx={{ mb: 2 }}>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontSize={14}>
                Coding Question:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {qs_id ? (
                <div className="flexRowView">
                    <p className="width80 mr8px">{q.q_name}</p>
                    <p className="width18">{q.q_time !== 0 ? 'Question Time:- ' + q.q_time + ' mins' : 'Question Time:- NA'}</p>
                </div>
                
              ) : (
                <>
                 <div className="flexRowView spaceBetween" >
                  <TextField
                    value={q.q_name}
                    onChange={(e) =>
                      handleQuestionChange(q.q_id, e.target.value)
                    }
                    className="width80"
                    placeholder="Enter coding question here"
                  />

                  <TextField
                    type="number"
                    value={q.q_time}
                    onChange={(e) =>
                      handleQuestionTimeChange(
                        q.q_id,
                        parseInt(e.target.value, 10) || 0
                      )
                    }
                    onInput={(e) => {
                      const value = e.target.value;
                      e.target.value = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    }}
                    className="width18"
                    placeholder="Enter Time in Mins"
                  />
                  </div>
                </>
              )}
            </Grid>
            {!qs_id && codingQuestions.length > 0 && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveQuestion(q.q_id)}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
      ))}
      {!qs_id && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMoreCodingQuestions}
          >
            Add Coding Questions
          </Button>
        </Grid>
      )}
    </>
  );
};

export default CodingQuestion;
