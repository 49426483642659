import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../../components/Copyright";
import Logo from "../../components/Logo";
import { connect } from "react-redux";
import { forgotPasswordUser } from "../../redux/actions/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ForgotPassword = ({ forgotPasswordUser, forgotPasswordSuccess }) => {
  const [email, setEmail] = useState();
  const [message, setMessage] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleLoginChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const captchaToken = await executeRecaptcha("forgot_password");

      let obj = {
        email: email,
        reCaptchaToken: captchaToken,
      };
      await forgotPasswordUser(obj);
      toast.success(
        "An email has been sent to your email, please reset your password using the link given in the mail."
      );
      navigate("/signin");
    } catch (error) {
      toast.error(error || "Something went wrong");
    }
    console.log(message);
  };

  return (
    <Grid container item xs={12} sm={12}>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // alignContent: "center",
          minHeight: "100vh",
          justifyContent: "center",
          background: "white",
        }}
      >
        <Logo />
        <Typography  variant="h5" sx={{ mt: 2 }}>
          Forgot Password
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          {forgotPasswordSuccess ? (
            <Typography component="h5" sx={{ mt: 2 }}>
              An email has been sent to your mail, please verify.
            </Typography>
          ) : (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: 350, height: 50 }}
                required
              >
                <InputLabel htmlFor="outlined-username">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleLoginChange}
                />
              </FormControl>

              <Button
                variant="contained"
                sx={{ mt: 4, mb: 2, width: 350 }}
                onClick={handleSubmit}
                disabled={!email}
              >
                Send Link
              </Button>
            </Grid>
          )}
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Link href="/signin" variant="body2">
                {"Login"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <div className="container-fluid homepage-bgimage"></div>
      </Grid>
    </Grid>
  );
};

const ForgotPasswordWithReCaptcha = ({
  forgotPasswordUser,
  forgotPasswordSuccess,
}) => {
  const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_API_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <ForgotPassword
        forgotPasswordUser={forgotPasswordUser}
        forgotPasswordSuccess={forgotPasswordSuccess}
      />
    </GoogleReCaptchaProvider>
  );
};

const mapStateToProps = (state) => ({
  forgotPasswordSuccess: state.auth.forgotPasswordSuccess,
});

const mapDispatchToProps = { forgotPasswordUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordWithReCaptcha);
