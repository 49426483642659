import React, { useEffect, useState, useRef } from "react";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  Stack,
  Button
} from "@mui/material";
import { connect } from "react-redux";
import { getQAs } from "../../redux/actions/candidate";
import { useParams, useNavigate } from "react-router-dom";

let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');
let API_URL = '';
if (orgName) {
    API_URL = `${BASE_URL}/${orgName}/api/v1`
} else {
    API_URL = `${BASE_URL}/api/v1`
}

const InterviewDetails = ({ getQAs, qaData }) => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const { candidateId, threadId } = useParams();
  const [currentAns, setCurrentAns] = useState("");
  const [currentQues, setCurrentQues] = useState("");
  const [videos, setVideos] = useState([]);
  const [qaId, setQaId] = useState(null);
  const [currentVideoNumber, setCurrentVideoNumber] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (candidateId && threadId) getQAs(candidateId, threadId);
  }, [threadId, candidateId]);

  useEffect(() => {
    if (qaData && qaData.QAs && qaData.QAs.length > 0) {
      setTotalVideos(qaData.QAs.length);
      setVideos(qaData.QAs);
      setQaId(qaData.QAs[0].intqa_id);
      setCurrentAns(qaData.QAs[0].intqa_answer);
      setCurrentQues(qaData.QAs[0].intqa_question);
    }
    if (qaData && qaData.user && qaData.user.u_name) {
      setUserName(qaData.user.u_name);
    }
  }, [qaData]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute("src");
      videoRef.current.load();
    }
  });

  const handleVideoEnd = () => {
    if (currentVideoNumber + 1 < totalVideos) {
      const newV = videos[currentVideoNumber + 1];
      onClickPlay(newV, currentVideoNumber + 1);
    }
  };

  const startAgain = () => {
    const newV = videos[0];
    onClickPlay(newV, 0);
  };

  const onClickPlay = (qa, i) => {
    setCurrentVideoNumber(i);
    setCurrentAns(qa.intqa_answer);
    setCurrentQues(qa.intqa_question);
    setQaId(qa.intqa_id);
  };

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Grid
          xs={12}
          sm={3}
          item
          sx={{
            padding: "10px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              padding: "0 10px",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              <strong>{userName}</strong>
            </Typography>
            <Typography>
              Question : {currentVideoNumber + 1}/{totalVideos}
              {/* {currentVideoNumber + 1 === totalVideos ? (
              <IconButton onClick={startAgain}>
                <ReplayIcon />
              </IconButton>
            ) : null} */}
            </Typography>
          </Stack>
          <div className="div-ques-list">
            {videos.map((c, i) => (
              <Card
                sx={{
                  display: "flex",
                  backgroundColor:
                    i === currentVideoNumber ? "lightgray" : "white",
                }}
                key={c.intqa_id}
                onClick={() => onClickPlay(c, i)}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <div className="div-ques">
                      <strong> {i + 1} : </strong> {c.intqa_question}
                    </div>
                  </CardContent>
                </Box>

                {/* <CardMedia component="img" sx={{ width: 200 }} alt="" /> */}
              </Card>
            ))}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              autoPlay
              controls
              onEnded={handleVideoEnd}
              className="shadow-video"
            >
              <source
                src={`${API_URL}/candidates/ans_video/${candidateId}/${qaId}`}
                type="video/mp4"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          sx={{
            paddingLeft: "10px",
            marginTop: "-15px",
          }}
        >
          <div className="div-ques-list">
            {currentQues && (
              <Card>
                <CardContent>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="justify"
                    flexGrow={1}
                  >
                    <strong> Question : </strong>
                    {currentQues}
                  </Typography>
                </CardContent>
              </Card>
            )}
            {currentAns && (
              <Card>
                <CardContent>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="justify"
                    flexGrow={1}
                  >
                    <strong> Answer : </strong>
                    {currentAns}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Stack sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      }}>
        <Button
          variant="text"
          size={"small"}
          sx={{ mt: 1, color: "primary" }}
         onClick={()=>navigate(-1)}
        >
          Back
        </Button>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  qaData: state.candidate.qaData,
  user: state.auth.user,
});

const mapDispatchToProps = {
  getQAs,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewDetails);
