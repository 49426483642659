import { useEffect } from "react";
import { Grid, TextField, Typography, InputLabel, Button, Card } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
const McqQuestion = ({ mcqs, setMcqs, qs_id }) => {

    const handleAddMoreMCQQuestions = () => {
        setMcqs([...mcqs, { q_id: uuidv4(), q_name: "", options: [{ a_label: 'A', a_text: '' }, { a_label: 'B', a_text: '' }, { a_label: 'C', a_text: '' }, { a_label: 'D', a_text: '' }], correctAnswer: "" }]);
    };

    const handleRemoveQuestion = (id) => {
        setMcqs(mcqs.filter(q => q.q_id !== id));
    };

    const handleQuestionChange = (id, value) => {
        setMcqs(mcqs.map(q => (q.q_id === id ? { ...q, q_name: value } : q)));
    };

    const handleOptionChange = (id, option, value) => {
        setMcqs(mcqs.map(q =>
            q.q_id === id
                ? {
                    ...q,
                    options: q.options.map(o =>
                        o.a_label === option ? { a_label: option, a_text: value } : o
                    )
                }
                : q
        ));
    };

    const handleCorrectAnswerChange = (id, value) => {
        setMcqs(mcqs.map(q => (q.q_id === id ? { ...q, q_answer: value } : q)));
    };

    return (
        <>
            {mcqs.map((q) => (
                <Card key={q.q_id} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Question:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {qs_id ? q.q_name : (<TextField
                                fullWidth
                                value={q.q_name}
                                onChange={(e) => handleQuestionChange(q.q_id, e.target.value)}
                                placeholder="Enter question here"
                            />)}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Options:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            {q.options.map((option, index) => (
                                <Grid item xs={6} key={index} container alignItems="center">
                                    <Grid item xs={3}>
                                        <InputLabel>{`Option ${option.a_label}`}</InputLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {qs_id ? option.a_text : (<TextField
                                            fullWidth
                                            value={option.a_text}
                                            onChange={(e) => handleOptionChange(q.q_id, option.a_label, e.target.value)}
                                            placeholder={`Enter option ${option.a_label} text`}
                                        />)}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Correct Answer:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {qs_id ? q.q_answer : (<TextField
                                fullWidth
                                value={q.q_answer}
                                onChange={(e) => handleCorrectAnswerChange(q.q_id, e.target.value)}
                                placeholder="Enter the correct option"
                            />)}
                        </Grid>
                        {(!qs_id && mcqs.length > 0) && (
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleRemoveQuestion(q.q_id)}
                                >
                                    Remove
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Card>
            ))}
            {!qs_id && (<Grid item xs={12} sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleAddMoreMCQQuestions}>
                    Add MCQ Questions
                </Button>
            </Grid>)}
        </>
    );
};

export default McqQuestion;
