import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { getSkillStrengthData } from "../../services/chart.service";
import {styled} from "@mui/material/styles";

const CustomPlot = styled(Plot)(({theme}) => ({
  width: "45%",
  height: "45%",
  maxHeight: "400px",
  backgroundColor: 'transparent',
  marginTop: theme.spacing(2),
  "& .plot-container .svg-container svg": {
    borderRadius: theme.shape.borderRadius,
  }

}));

const SkillStrengthDynamicChart = () => {
  const [data, setData] = useState();

  const fetchSkillStrengthData = async () => {
    try {
      const response = await getSkillStrengthData();
      setData(response.data.data);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    fetchSkillStrengthData();
  }, []);

  let result = {
    values: data?.values,
    labels: data?.labels,
  };

 

  let plotdata = [
    {
      values: data?.values,
      labels: data?.labels.map((l) => l.length > 20 ? l.substring(0, 20) + "..." : l),
      hovertemplate: "",
      textinfo: "",
      texttemplate: "%{label}<br>%{percent}",
      scale: 1,
      hole: 0.8,
      type: "pie",
      //   automargin: true,
      insidetextorientation: "radial",
      textposition: "outside",
      textfont: {
        size: 10,
      }
    },
    {
      ...result,
      hovertemplate: "Skill_Name = %{label}<br>Skill_Strength = %{value}<br>Percent = %{percent}<extra></extra>",
      texttemplate: "%{percent}",
      textposition: "inside",
      scale: 1,
      hole: 0.7,
      type: "pie",
      automargin: true,
    },
  ];
  let layout = {
    title: "Skill Set Strength",
    // annotations: [
    //   {
    //     font: { size: 20 },
    //     showarrow: false,
    //     // text: "Skill Set Gaps",
    //     x: 0.5,
    //     y: 0.5,
    //   },
    // ],
    //  margin: { t: 50, b: 50, l: 50, r: 0 },
    showlegend: false,
    textposition: "outside",
    // Format label text
    labeltext: "%{label}",
    // paper_bgcolor: ${theme.palette.mode === "dark" ? grey[900] : "#fff"}

  };
  let config = {
    responsive: true,
    displayModeBar: true,
    displaylogo: false,
    showLink: false,
  };

  return (
    <>
      {plotdata && plotdata ? (
          <CustomPlot data={plotdata} layout={layout} config={config} />
      ) : (
        ""
      )}
    </>
  );
};

export default SkillStrengthDynamicChart;
