export const  capitalizeFirstLetter =(string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// export function convertMonthsToYearsAndMonths(totM) {
//   const y = Math.floor(totM / 12);
//   const m = totM % 12;
//   return `${y > 0 ? `${y} year${y > 1 ? "s" : ""} ` : ""}${m > 0 ? `${m} month${m > 1 ? "s" : ""}` : y > 0 ? "" : "0"}`;
// }

function convertMonthsToYearsAndMonths(totM, days = 0, filter = 0) {
  let totalDays = days + (totM * 30.5); // Assuming 1 month = 30 days for simplicity
  let y = Math.floor(totalDays / 365);
  let remainingDays = totalDays % 365;
  let m = Math.floor(remainingDays / 30);
  let d = remainingDays % 30;

  if(m===12){
      y++;
      m=0;
  }

  return `${y > 0 ? `${y} year${y > 1 ? 's' : ''} ` : ''}` +
      `${m > 0 ? `${m} month${m > 1 ? 's' : ''} ` : ''}`.trim();

}

// export function formatExperienceDuration(durationString) {
//   if (!durationString) return "0";

//   // Trim whitespace and check if input is a single number
//   const trimmed = durationString.trim();
//   if (/^\d+$/.test(trimmed)) {
//     // Single number handling: treat as years if < 10, else treat as months
//     const value = parseInt(trimmed, 10);
//     if (value >= 10) {
//       return convertMonthsToYearsAndMonths(value); // Treat as months
//     } else {
//       return convertMonthsToYearsAndMonths(value * 12); // Treat as years
//     }
//   }

//   // Regex patterns for parsing
//   const regexYM = /(\d+)\s*(years?|yrs?|y)\s*(\d+)\s*(months?|mons?|mths?|mth?|m)/im;
//   const regexYMNoSpace = /(\d+)\s*(years?|yrs?|y)(\d+)\s*(months?|mons?|mths?|mth?|m)/im;
//   const regexY = /(\d+)\s*(years?|yrs?|y)/im;
//   const regexM = /(\d+)\s*(months?|mons?|mths?|mth?|m)/im;
//   const regexDot = /[,.\s]/im;

//   let result = null;
//   let totalMonths = 0;

//   if ((result = durationString.match(regexYM)) || (result = durationString.match(regexYMNoSpace))) {
//     // Matches "X years Y months" or "XyearsYmonths"
//     const years = parseInt(result[1], 10);
//     const months = parseInt(result[3], 10);
//     totalMonths = years * 12 + months;
//   } else if ((result = durationString.match(regexY))) {
//     // Matches "X years"
//     totalMonths = parseInt(result[1], 10) * 12;
//   } else if ((result = durationString.match(regexM))) {
//     // Matches "X months"
//     totalMonths = parseInt(result[1], 10);
//   } else if ((result = durationString.split(regexDot)).length > 0) {
//     // Matches "X.Y" (e.g., "2.1" or "2,1")
//     if (result.length === 2) {
//       totalMonths = parseInt(result[0], 10) * 12 + parseInt(result[1], 10);
//     } else if (result.length === 1) {
//       totalMonths = parseInt(result[0], 10) * 12;
//     }
//   }

//   return totalMonths > 0 ? convertMonthsToYearsAndMonths(totalMonths) : "0";
// }



export function formatExperienceDuration(durationString) {
  if (durationString.trim() === "0") return "0";
  if (!durationString) return "0";
  

  // Trim whitespace and check if input is a single number
  const trimmed = durationString.trim();
  if (/^\d+$/.test(trimmed)) {
    // Single number handling: treat as years if < 10, else treat as months
    const value = parseInt(trimmed, 10);
    if (value >= 10) {
      return convertMonthsToYearsAndMonths(value); // Treat as months
    } else {
      return convertMonthsToYearsAndMonths(value * 12); // Treat as years
    }
  }
  const regexYM = /(\d+)\s*(years?|year?|yrs?|y)\s*(\d+)\s*(months?|month?|mons?|m)\s*(\d+)\s*(days?|day?|d)/im;
  const regexYMNoSpace = /(\d+)\s*(years?|year?|yrs?|y)(\d+)\s*(months?|month?|mons?|m)(\d+)\s*(days?|day?|d)/im;
  const regexYMD = /(\d+)\s*(years?|year?|yrs?|y)\s*(\d+)\s*(months?|month?|mons?|m)/im;
  const regexYD = /(\d+)\s*(years?|year?|yrs?|y)\s*(\d+)\s*(days?|day?|d)/im;
  const regexY = /(\d+)\s*(years?|year?|yrs?|y)/im;
  const regexM = /(\d+)\s*(months?|month?|mons?|m)/im;
  const regexD = /(\d+)\s*(days?|day?|d)/im;
  const regexDot = /[,.\s]/im;

  let month_result = null;
  let day_result = 0;
  let result = null;
  let type = "regexYM";

  result = durationString.match(regexYM)
  !result && (result = durationString.match(regexYMNoSpace)) && (type = "regexYMNoSpace");
  !result && (result = durationString.match(regexYMD)) && (type = "regexYMD");
  !result && (result = durationString.match(regexYD)) && (type = "regexYD");
  !result && (result = durationString.match(regexY)) && (type = "regexY");
  !result && (result = durationString.match(regexM)) && (type = "regexM");
  !result && (result = durationString.match(regexD)) && (type = "regexD");
  !result && (result = durationString.split(regexDot)) && (type = "regexDot");

  if (type === 'regexYM' || type === 'regexYMNoSpace') {
      const years = result[1] ? parseInt(result[1]) : 0;
      const months = result[3] ? parseInt(result[3]) : 0;
      day_result = result[5] ? parseInt(result[5]) : 0;

      if (result.length >= 4) {
          month_result = years * 12 + months;
      } else if (result.length === 3) {
          month_result = years * 12;
      } else if (result.length === 2) {
          month_result = months;
      } else {
          month_result = 0;
      }
  } else if (type === 'regexYMD') {
      const years = result[1] ? parseInt(result[1]) : 0;
      const months = result[3] ? parseInt(result[3]) : 0;

      if (result.length >= 4) {
          month_result = years * 12 + months;
      } else if (result.length === 3) {
          month_result = years * 12;
      } else if (result.length === 2) {
          month_result = months;
      } else {
          month_result = 0;
      }
  } else if (type === 'regexYD') {
      const years = result[1] ? parseInt(result[1]) : 0;
      day_result = result[3] ? parseInt(result[3]) : 0;

      month_result = years * 12;
  }
  else if (type === 'regexY') {
      const years = result[1] ? parseInt(result[1]) : 0;
      month_result = years * 12;
  } else if (type === 'regexM') {
      month_result = result[1] ? parseInt(result[1]) : 0;
  } else if (type === 'regexD') {
      day_result = result[1] ? parseInt(result[1]) : 0;
  } else if (type === 'regexDot') {
      const years = result[0] ? parseInt(result[0]) : 0;
      const months = result[1] ? parseInt(result[1]) : 0;
      const days = result[2] ? parseInt(result[2]) : 0;

      month_result = years * 12 + months;
      day_result = days;
  }

  return convertMonthsToYearsAndMonths(month_result, day_result);    
}