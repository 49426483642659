import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import loader from "./loader";
import dashboard from "./dashboard";
import jobs from "./jobs";
import applicant from "./applicant";
import candidate from "./candidate";
import batches from "./batches";
import skills from "./skills"
import progress from "./progress";
import employee from "./employee";
import hr from "./hr"
import sidebar from "./sidebar";
import labels from "./label";
import permission from "./acls";
import app_org_labels from "./app_org_labels";
import questions from "./questions";
import emailTemplate from "./email_template";


export default combineReducers({
  auth,
  message,
  loader,
  dashboard,
  jobs,
  applicant,
  candidate,
  batches,
  skills,
  progress,
  employee,
  hr,
  sidebar,
  labels,
  permission,
  app_org_labels,
  questions,
  emailTemplate
});
