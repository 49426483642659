import { _createQuestion, _createQuestionSet, _deleteQuestionSet, _getQuestionSetDetail, _getQuestionSets } from "../../services/question.service";
import { hideLoading, showLoading } from "./loader";
import { setMessage } from "./message";
import { CREATE_QUESTION_SET_SUCCESS, DELETE_QUESTION_SET_SUCCESS, GET_QUESTION_SET_SUCCESS, GET_QUESTION_SETS_SUCCESS } from "./types";

export const saveQuestionSetAction = (data) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _createQuestionSet(data);
        if (response.status === 201) {
            dispatch({
                type: CREATE_QUESTION_SET_SUCCESS,
                payload: response.data,
            });
            dispatch(setMessage("Question Set created successfully", "success"));
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in creating Audio Video", error.response.data.error);
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
};

export const getQuestionSets = () => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _getQuestionSets();
        if (response.status === 200) {
            dispatch({
                type: GET_QUESTION_SETS_SUCCESS,
                payload: response.data,
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in getting question sets", error.response.data.error);
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
};

export const getQuestionSetDetail = (data) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _getQuestionSetDetail(data);
        if (response.status === 200) {
            dispatch({
                type: GET_QUESTION_SET_SUCCESS,
                payload: response.data,
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in getting question set", error.response.data.error);
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
};

export const saveQuestionAction = (id, data) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _createQuestion(id, data);
        if (response.status === 201) {
            dispatch({
                type: CREATE_QUESTION_SET_SUCCESS,
                payload: response.data,
            });
            dispatch(setMessage("Question added successfully", "success"));
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in creating question", error.response.data.error);
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
};

export const deleteQuestionSetAction = (id) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _deleteQuestionSet(id);
        if (response.status === 200) {
            dispatch({
                type: DELETE_QUESTION_SET_SUCCESS,
                payload: id,
            });
            dispatch(setMessage("Question set deleted successfully", "success"));
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in creating question", error.response.data.error);
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
};