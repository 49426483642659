import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  TextField,
  ListItemText,
  Stack,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import { savePermissions, getAllAclGroups, getPermissionList, createModulePermission, getUserList, getMasterAclGroupsByUserIds } from "../../redux/actions/acls"
import { connect } from "react-redux";
import { modalPopUpStyle } from '../../styles/getLPTheme';
import { useTranslation } from 'react-i18next';

const AccessControlManagement = ({ savePermissions, getAllAclGroups, getMasterAclGroupsByUserIds, permissions, userGroups, getPermissionList, createModulePermission, getUserList, groupByUsers, masterAclGroups }) => {

  const aclGroups = ['Employee', 'HR', 'Candidate'];
  const [acls, setAcls] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [moduleFormData, setModuleFormData] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [editAccessControlItem, setEditAccessControlItem] = useState()
  const { t } = useTranslation();

  useEffect(() => {
    setAcls(
      permissions.map(permission => {
        const selectedUserGroup = userGroups.find(item =>
          item.mag_user_id === selectedUsers
        );
        return {
          ...permission,
          checked: selectedUserGroup ? selectedUserGroup.mag_acl_ids?.includes(permission.acl_id) : false
        };
      })
    );
  }, [permissions, selectedUsers]);

  useEffect(() => {
    getAllAclGroups()
    getPermissionList()
  }, []);

  useEffect(() => {
    setAcls(permissions);
  }, [permissions])

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    setSelectedUsers([])
    if (event.target.value) {
      getUserList(event.target.value)
    }
  };

  const handleUserChange = (event) => {
    setSelectedUsers(event.target.value);
  };

  const handleUsersSelectDone = () => {
    getMasterAclGroupsByUserIds(selectedUsers)
  };
  const [wpermissions, setWPermissions] = useState({});

  const handlePermissionChange = (aclId, isChecked) => {
    setWPermissions((prevPermissions) => ({
      ...prevPermissions,
      [aclId]: isChecked,
    }));
  };

  const handleAllPermissionChange = (aclModuleIndex, isChecked) => {
    const updatedPermissions = { ...wpermissions };

    const acl = acls[aclModuleIndex];

    acl.acl_type.forEach(acltype => {
      updatedPermissions[acltype.acl_id] = isChecked;
    });

    setWPermissions(updatedPermissions);
  };


  const handleSaveChanges = () => {
    const selectedPermissions = Object.keys(wpermissions).filter(key => wpermissions[key]);
    savePermissions({ selectedUsers, selectedPermissions })
  };

  const handleModulePermissionFormSubmit = () => {
    createModulePermission(moduleFormData, editAccessControlItem.acl_id)
    setOpenModal(false)
  }

  const handleModuleFormChange = (e) => {
    const { name, value } = e.target;
    setModuleFormData({ ...moduleFormData, [name]: value })
  }

  const handleCancel = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    !openModal && setEditAccessControlItem('');
  }, [openModal])

  useEffect(() => {
    if (masterAclGroups.length) {
      const idsObject = masterAclGroups.reduce((acc, item) => {
        item.mag_acl_ids.split(',').filter(id => id !== '').forEach(id => {
          acc[id] = true;
        });
        return acc;
      }, {});
      setWPermissions(idsObject)
    }
  }, [masterAclGroups]);

  return (
    <Card raised sx={{ minWidth: "100%", minHeight: "400px" }}>
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5"> {t("Access Control Management")} </Typography>
          <Divider />
          <Box sx={{ display: 'flex', gap: 2, mt: 4, marginBottom: 1 }}>
            <FormControl sx={{ width: '20%' }} variant="outlined">
              <InputLabel sx={{ fontWeight: 'bold' }}>Select User Group</InputLabel>
              <Select
                value={selectedGroup}
                onChange={handleGroupChange}
                label="Select User Group"
              >
                {aclGroups.map((group) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedGroup && (<FormControl sx={{ width: '80%' }} variant="outlined">
              <InputLabel sx={{ fontWeight: 'bold' }}>Select User </InputLabel>
              <Select multiple value={selectedUsers}
                onChange={handleUserChange}
                onClose={handleUsersSelectDone}
                label="Select User"
                renderValue={(selected) => {
                  return selected.map((userId) => {
                    const user = groupByUsers.find(item => item.u_id === userId);
                    return user ? user.u_name : null;
                  }).join(', ');
                }}
              >
                {groupByUsers.map((item) => (
                  <MenuItem key={item.u_id} value={item.u_id}>
                    <Checkbox checked={selectedUsers.indexOf(item.u_id) > -1} />
                    <ListItemText primary={item.u_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>)}
          </Box>

          {(selectedUsers.length && acls.length) ? (<Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {acls.map((acl, acl_module_idx) => (<Card key={acl.acl_id}
              raised
              sx={{
                width: { xs: "100%", sm: "32%" },
                marginBottom: 1,
              }}
            >
              <CardContent>
                <Stack
                  direction={{ xs: "row", sm: "row" }}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    sx={{ fontSize: 16, ml: 1 }}
                    gutterBottom
                    textAlign="center"
                    variant="h4"
                    textTransform={'uppercase'}
                  >
                    {t(acl.acl_module)}
                  </Typography>

                  <Checkbox
                    checked={acl.acl_type.every(acltype => !!wpermissions?.[acltype.acl_id])}
                    onChange={(e) =>
                      handleAllPermissionChange(acl_module_idx, e.target.checked)
                    }
                  />
                </Stack>
                <Divider />
                {acl.acl_type.map(acltype => (<Stack key={acltype.acl_id}
                  direction={{ xs: "row", sm: "row" }}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography textTransform={"capitalize"}>
                    {acltype.acl_type}
                  </Typography>
                  <Checkbox
                    checked={!!wpermissions?.[acltype.acl_id]}
                    onChange={(e) =>
                      handlePermissionChange(acltype.acl_id, e.target.checked)
                    }
                  />
                </Stack>))}
              </CardContent>
            </Card>))}
          </Stack>) : ''}

          {selectedUsers.length > 0 && (
            <Box display="flex" justifyContent="center">
              {acls.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px' }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              )}
            </Box>
          )}
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box className="modalContent" sx={modalPopUpStyle}
              component="form">
              <h2 className="modalTitle" id="child-modal-title">
                {editAccessControlItem ? 'Edit' : 'Add'} Module
              </h2>
              <form className="modalForm">
                <div className="modalFormItem">
                  <label className="label-bold">Module</label>
                  <TextField
                    placeholder="Module Name"
                    name='moduleName'
                    defaultValue={editAccessControlItem?.acl_module}
                    onChange={handleModuleFormChange}
                    required
                    fullWidth
                  />
                </div>
                <div className="modalFormItem">
                  <label className="label-bold">Type</label>
                  <TextField
                    placeholder="Type"
                    name='TypeName'
                    defaultValue={editAccessControlItem?.acl_type}
                    onChange={handleModuleFormChange}
                    required
                    fullWidth
                  />
                </div>
                <div className="modalFormItem">
                  <label className="label-bold">Route</label>
                  <TextField
                    placeholder="Route"
                    name='Route'
                    defaultValue={editAccessControlItem?.acl_route}
                    onChange={handleModuleFormChange}
                    required
                    fullWidth
                  />
                </div>
                <div className="modalFormItem">
                  <label className="label-bold">Backend API	</label>
                  <TextField
                    placeholder="Backend API	"
                    name='Route'
                    defaultValue={editAccessControlItem?.acl_api}
                    onChange={handleModuleFormChange}
                    required
                    fullWidth
                  />
                </div>
                <div className="modalFormItem">
                  <label className="label-bold">Method	</label>
                  <TextField
                    placeholder="Method"
                    name='Method'
                    defaultValue={editAccessControlItem?.acl_method}
                    onChange={handleModuleFormChange}
                    required
                    fullWidth
                  />
                </div>
                <div className="modalActions">
                  <Button className="modalButton" onClick={handleModulePermissionFormSubmit}>
                    Change
                  </Button>
                  <Button className="modalButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </form>
            </Box>
          </Modal>
        </Box>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  userGroups: state.permission.userGroups,
  permissions: state.permission.permissions,
  groupByUsers: state.permission.groupByUsers,
  masterAclGroups: state.permission.mags,
})

const mapDispatchToProps = {
  savePermissions,
  getAllAclGroups,
  getPermissionList,
  getUserList,
  createModulePermission,
  getMasterAclGroupsByUserIds
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlManagement);