import { GET_ALL_ACL_GROUPS, GET_MAG_BY_USERIDS_SUCCESS, GET_PERMISSIONS_SUCCESS, GET_USERS_BY_GROUP_SUCCESS } from "../actions/types";


const initialState = {
    permissions: [],
    userGroups: [],
    groupByUsers: [],
    mags: []
};

const permission = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ACL_GROUPS:
            return {
                ...state, userGroups: payload
            }
        case GET_USERS_BY_GROUP_SUCCESS:
            return {
                ...state, groupByUsers: payload
            }
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state, permissions: payload
            }
        case GET_MAG_BY_USERIDS_SUCCESS:
            return {
                ...state, mags: payload
            }
        default:
            return state;
    }
}

export default permission