export const marksValues = [
  {
    min: 0,
    max: 0,
    label: "Novoice",
    color: "#ffffff",
  },
  {
    min: 0,
    max: 70,
    label: "Novoice",
    color: "#ffff00",
  },
  {
    min: 71,
    max: 100,
    label: "Theoretical Knowledge",
    color: "#66cc66",
  },
  {
    min: 101,
    max: 130,
    label: "Beginner Hands on",
    color: "#2d862d",
  },
  {
    min: 131,
    max: 160,
    label: "Intermediate Hands on",
    color: "#33ccff",
  },
  {
    min: 161,
    max: 200,
    label: "Advanced Hands on",
    color: "#0086b3",
  },
];

export const skillsLevels = [
  {
    value: 0,
    label: "1",
    name: "Theoretical Knowledge",
  },
  {
    value: 25,
    label: "2",
    name: "Beginner",
  },
  {
    value: 50,
    label: "3",
    name: "Intermediate",
  },
  {
    value: 75,
    label: "4",
    name: "Advance",
  },
  {
    value: 100,
    label: "5",
    name: "Expert",
  },
];

export const getSkillLevelName = (score) => {
  let level = marksValues.find(
    (item) => score >= item.min && score <= item.max
  );
  return level;
};

export const getSkillLevel = (score) => {
  // console.log("Level Score====>", score, skillsLevels);
  let level = skillsLevels.find((item) => score.toString() === item.label);
  return level ? level.name : "Theoretical Knowledge";
};
