import { GET_SOCKET_PROGRESS } from "./types";

export const getSocketProgress = (data) => (dispatch) => {
    console.log("data in redux line : 4", data);
  dispatch({
    type: GET_SOCKET_PROGRESS,
    payload: data,
  });
};

