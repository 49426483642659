import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const defaultResources = {
    en: {
        translation: {
            dashboard: "Dashboard",
            description: "This is a simple i18n example."
        }
    },
    fr: {
        translation: {
            welcome: "Bienvenue dans React",
            description: "Ceci est un exemple simple d'i18n."
        }
    }
};

const initI18n = async () => {

    i18n.use(initReactI18next).init({
        lng: 'en', // default language
        fallbackLng: 'en',
        resources: defaultResources, // Provide default translations
        interpolation: {
            escapeValue: false // React already does escaping
        },
        // You can also configure the backend for error handling
        // Consider using a custom handler for better error control
        saveMissing: true, // Optionally save missing translations
        // missingKeyHandler: (lng, ns, key) => {
        //     console.warn(`Missing translation: ${key} in ${lng}`);
        // },
    });
}

initI18n();

export default i18n;