import { useState, useEffect, useRef } from "react";
import {
  Button,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  Card,
  Box,
  Modal,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSelfEvalTest, submitMCQ } from "../../redux/actions/dashboard";
import AlertDialog from "../../components/Dialog";
import { getApplicant } from "../../redux/actions/applicant";
import { hideSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";
import { saveInterviewSession } from "../../redux/actions/candidate";

// Modal popup styles
const modalPopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  textAlign: 'center',
};

export const TestPage = ({
  saveInterviewSession,
  userData,
  auth: { user },
  dashboard: { test },
  getAllSelfEvalTest,
  submitMCQ,
  testResult,
  skillData,
  hideSidebar
}) => {
  const navigate = useNavigate();
  const [answerSheet, setAnswerSheet] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [isPassing, setIsPassing] = useState(0);
  const [openSecond, setOpenSecond] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullscreen();
  const [isFaceDetected, setIsFaceDetected] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null); // Canvas to draw rectangles
  const [videoStream, setVideoStream] = useState(null);
  const [faceapi, setFaceApi] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false); // Track consent
  const [consentModalOpen, setConsentModalOpen] = useState(true); // Consent modal state
  const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false); // Confirm dialog state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // Disable submit initially

  // Load face-api models dynamically
  useEffect(() => {
    const loadFaceApi = async () => {
      const faceapi = await import('face-api.js');
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
      setFaceApi(faceapi);
    };

    loadFaceApi();
  }, []);

  // Show error modal with dynamic message
  const showErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  // Close error modal
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    enterFullScreen();
  };

  // Camera and audio permission handling after consent
  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setVideoStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      showErrorModal("Camera and microphone access is required");
    }
  };

  // Handle consent acceptance
  const handleConsentAccept = () => {
    setConsentGiven(true);
    setConsentModalOpen(false);
    getUserMedia(); // Request camera and microphone access after consent
  };

  // Draw rectangles around detected faces
  const drawFaceDetections = (detections) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    detections.forEach((detection) => {
      const { x, y, width, height } = detection.box;
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
    });
  };

  // Facial detection and drawing bounding boxes
  useEffect(() => {
    let faceDetectionInterval;
    const detectFaces = async () => {
      if (videoRef.current && videoRef.current.readyState === 4 && faceapi) {
        const detections = await faceapi.detectAllFaces(
          videoRef.current,
          new faceapi.TinyFaceDetectorOptions({ inputSize: 160, scoreThreshold: 0.5 })
        );

        if (detections.length > 0) {
          drawFaceDetections(detections); // Draw rectangles around faces
        }

        if (detections.length > 1) {
          saveInterviewSession({ multiFaceDetected: true, apct_id: user.u_apct_id_fk })
          showErrorModal("More than one face detected. Please ensure only one person is visible.");
        }

        setIsFaceDetected(detections.length === 1);
      }
    };

    if (faceapi && consentGiven) {
      faceDetectionInterval = setInterval(detectFaces, 2000); // Check every 2 seconds
    }

    return () => clearInterval(faceDetectionInterval);
  }, [faceapi, consentGiven]);

  // Show warning if no face or multiple faces are detected
  useEffect(() => {
    if (consentGiven && !isFaceDetected) {
      showErrorModal("Face not detected. Please ensure only one face is visible.");
    }
  }, [isFaceDetected, consentGiven]);

  // Handle answer selection logic
  const onClickAnswer = (e, q_id, opt) => {
    let tempAnswerSheet = [...answerSheet];
    if (answerSheet.find((ans) => ans.q_id === q_id)) {
      if (answerSheet.find((ans) => ans.q_id === q_id && ans.answer === opt)) {
        tempAnswerSheet = answerSheet.filter((ans) => ans.q_id !== q_id);
      } else {
        tempAnswerSheet = answerSheet.map((ans) => {
          return ans.q_id === q_id ? { ...ans, answer: opt } : ans;
        });
      }
    } else {
      tempAnswerSheet.push({ q_id: q_id, answer: opt });
    }

    setAnswerSheet(tempAnswerSheet);
    setIsSubmitDisabled(tempAnswerSheet.length === 0); // Enable submit button if at least one answer is selected
  };

  // Show confirmation dialog before submitting
  const handleSubmitConfirm = () => {
    setSubmitConfirmOpen(true);
  };

  // Submit final data (original functionality)
  const submitFinalData = async () => {
    let data = {
      skills: skillData,
      totalQues: test?.length,
      answerSheetMCQ: answerSheet,
    };
    await submitMCQ(data);
    setOpen(false);
    setConfirm(false);
    navigate('/candidate/dashboard', { replace: true });
    window.location.reload();
  };

  // Retain all existing useEffects and functionality
  useEffect(() => {
    if (skillData && skillData?.length) {
      getAllSelfEvalTest({ skills: skillData }, "mcq");
    }
  }, [skillData]);

  useEffect(() => {
    if (confirm) {
      submitFinalData();
    }
  }, [confirm]);

  useEffect(() => {
    hideSidebar();
  }, []);

  useEffect(() => {
    const handleCopy = (e) => {
      e.preventDefault();
      showErrorModal("Copying is not allowed");
    };

    const handleCut = (e) => {
      e.preventDefault();
      showErrorModal("Cutting is not allowed");
    };

    const handlePaste = (e) => {
      e.preventDefault();
      showErrorModal("Pasting is not allowed");
    };

    let mcqsPage = document.getElementById("mcqPage");
    mcqsPage.addEventListener('copy', handleCopy);
    mcqsPage.addEventListener('cut', handleCut);
    mcqsPage.addEventListener('paste', handlePaste);

    return () => {
      mcqsPage.removeEventListener('copy', handleCopy);
      mcqsPage.removeEventListener('cut', handleCut);
      mcqsPage.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === 'c' || e.key === 'x' || e.key === 'v')) {
        e.preventDefault();
        let obj = { "c": "Copying", "x": "Cutting", "v": "Pasting" };
        showErrorModal(`${obj[e.key]} is not allowed`);
      }
    };

    let mcqPage = document.getElementById("mcqPage");
    mcqPage.addEventListener('keydown', handleKeyDown);

    return () => {
      mcqPage.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      showErrorModal("Action Not Allowed");
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk })
        showErrorModal("Switching Screen is not allowed");
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (!event.relatedTarget) {
        saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk })
        showErrorModal("You cannot leave the window");
      }
    };

    window.addEventListener('mouseout', handleMouseOut);

    return () => {
      window.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  useEffect(() => {
    if (!isFullScreen) {
      saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk })
      showErrorModal("Full screen exit not allowed");
    }
  }, [isFullScreen]);

  return (
    <>
      {/* Consent Modal */}
      <Modal
        open={consentModalOpen}
        onClose={() => { }}
        aria-labelledby="consent-modal-title"
        aria-describedby="consent-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="consent-modal-title">
            Consent Required
          </Typography>
          <Typography sx={{ mt: 2 }}>
            This test requires access to your camera and microphone for verification. Do you consent?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConsentAccept}
            sx={{ mt: 3 }}
          >
            Accept
          </Button>
        </Box>
      </Modal>

      {/* Error Modal */}
      <Modal
        open={openErrorModal}
        onClose={() => { }}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="error-modal-title">
            Error
          </Typography>
          <Typography sx={{ mt: 2 }}>{errorMessage}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseErrorModal}
            sx={{ mt: 3 }}
          >
            OK
          </Button>
        </Box>
      </Modal>

      {/* Submit Confirmation Dialog */}
      <Modal
        open={submitConfirmOpen}
        onClose={() => setSubmitConfirmOpen(false)}
        aria-labelledby="submit-confirm-modal-title"
        aria-describedby="submit-confirm-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="submit-confirm-modal-title">
            Confirm Submission
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to submit your answers?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={submitFinalData}
            sx={{ mt: 3 }}
          >
            Yes, Submit
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setSubmitConfirmOpen(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <CardContent id="mcqPage">
        <Stack direction="column" alignItems="center" spacing={3}>
          <Box component="main" sx={{ width: "100%", position: "relative" }}>
            {/* Live video stream positioned at bottom-right */}
            <video
              ref={videoRef}
              autoPlay
              muted
              style={{
                position: "fixed",
                bottom: "450px", // Adjusted for buttons
                right: "20px",
                width: "200px",
                height: "150px",
                border: "1px solid black",
                borderRadius: "8px",
                zIndex: 9999,
                display: consentGiven ? 'block' : 'none' // Only show video if consent is given
              }}
            />
            {/* Canvas for drawing rectangles */}
            <canvas
              ref={canvasRef}
              style={{
                position: "fixed",
                bottom: "460px", // Adjusted for buttons
                right: "20px",
                width: "200px",
                height: "150px",
                zIndex: 10000, // Draw over the video
                pointerEvents: "none"
              }}
            />
            <AlertDialog
              open={open}
              setOpen={setOpen}
              title={title}
              setConfirm={setConfirm}
              content={content}
            />
            <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
              {test && test?.length > 0 && test.map((item, index) => (
                <Card key={index} sx={{ mb: 2, userSelect: 'none' }}>
                  <CardContent>
                    <Typography variant="h6">
                      {index + 1}. {item.q_name}
                    </Typography>
                    <Stack direction="column">
                      {item?.options?.map((opt, idx) => (
                        <FormControlLabel
                          key={opt.a_id}
                          control={
                            <Checkbox
                              color="primary"
                              checked={answerSheet?.find(ans => ans.q_id === item.q_id && ans.answer === opt.a_label) ? true : false}
                              onChange={(e) => onClickAnswer(e, item.q_id, opt.a_label)}
                            />
                          }
                          label={opt.a_text}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Box>
        </Stack>

        {/* Submit and Back buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitConfirm}
            disabled={isSubmitDisabled} // Disable if no answer is selected
          >
            Submit
          </Button>
        </Box>
      </CardContent>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userData: state.auth.userData,
  dashboard: state.dashboard,
  skillData: state.applicant?.applicant?.skills,
  testResult: state.dashboard.testResult,
});

const mapDispatchToProps = {
  getAllSelfEvalTest,
  submitMCQ,
  getApplicant,
  hideSidebar,
  saveInterviewSession
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
