import { GET_ALL_LABEL_OPTIONS_SUCCESS, GET_ALL_LABEL_SUCCESS } from "../actions/types";
const initialState = {
    data: null,
    allLabels: [],
    allLabelOptions: null,
};

const labels = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case GET_ALL_LABEL_SUCCESS:
            return {
                ...state,
                allLabels: action.payload,
            };
        case GET_ALL_LABEL_OPTIONS_SUCCESS:
            return {
                ...state,
                allLabelOptions: action.payload,
            };
        default:
            return state;
    }
};
export default labels;