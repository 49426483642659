import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { fetchTemplates } from "../../redux/actions/email_template";

const SendInviteDialog = ({
  open,
  handleClose,
  confirmAndSendInvite,
  openConfirmationDialog,
  closeConfirmationDialog,
  mcqChecked,
  setMcqChecked,
  codeChecked,
  setCodeChecked,
  aiAssistanceCheck,
  setAiAssistanceCheck,
  emailContent,
  setEmailContent,
  setEmailSubject,
  mcqCount,
  handleMcqCountChange,
  mcqError,
  codeCount,
  handleCodeCountChange,
  codeError,
  mcqTime,
  handleMcqTimeChange,
  codeTime,
  handleCodeTimeChange,
  aiInterviewTime,
  handleAiInterviewTimeChange,
  showConfirmationDialog,
  reschedule,
  fetchTemplates,
  templates,
  loading,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // Fetch templates when the dialog opens
  useEffect(() => {
    if (open) {
      fetchTemplates(0, 10, "et_name", "asc", ""); // Fetch first 10 templates sorted by name
    }
  }, [open, fetchTemplates]);

  // Update email content when a template is selected
  useEffect(() => {
    if (selectedTemplate) {
      setEmailContent(selectedTemplate.et_body || "");
      setEmailSubject(selectedTemplate.et_subject || "");
    }
  }, [selectedTemplate, setEmailContent, setEmailSubject]);



  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "white",
          },
        }}
      >
        <DialogTitle>Send Invitation</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mcqChecked}
                    onChange={(e) => setMcqChecked(e.target.checked)}
                    color="primary"
                  />
                }
                label="MCQ"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={codeChecked}
                    onChange={(e) => setCodeChecked(e.target.checked)}
                    color="primary"
                  />
                }
                label="Code"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aiAssistanceCheck}
                    onChange={(e) => setAiAssistanceCheck(e.target.checked)}
                    color="primary"
                  />
                }
                label="AI Interview"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            {mcqChecked && (
              <Grid item xs={4}>
                <TextField
                  label="MCQ Count"
                  type="number"
                  value={mcqCount}
                  onChange={handleMcqCountChange}
                  error={mcqError}
                  helperText={mcqError ? "MCQs must be in range of 10-100" : ""}
                  variant="standard"
                />
              </Grid>
            )}
            {codeChecked && (
              <Grid item xs={4}>
                <TextField
                  label="Code Count"
                  type="number"
                  value={codeCount}
                  onChange={handleCodeCountChange}
                  error={codeError}
                  helperText={
                    codeError ? "Coding Questions must be in range of 2-10" : ""
                  }
                  variant="standard"
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            {mcqChecked && (
              <Grid item xs={4}>
                <TextField
                  label="MCQ Time (minutes)"
                  type="number"
                  value={mcqTime}
                  onChange={handleMcqTimeChange}
                  variant="standard"
                />
              </Grid>
            )}
            {codeChecked && (
              <Grid item xs={4}>
                <TextField
                  label="Code Time (minutes)"
                  type="number"
                  value={codeTime}
                  onChange={handleCodeTimeChange}
                  variant="standard"
                />
              </Grid>
            )}
            {aiAssistanceCheck && (
              <Grid item xs={4}>
                <TextField
                  label="AI Interview Time (minutes)"
                  type="number"
                  value={aiInterviewTime}
                  onChange={handleAiInterviewTimeChange}
                  variant="standard"
                />
              </Grid>
            )}
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">Email Template:</Typography>
            {/* <Autocomplete
              options={templates || []}
              getOptionLabel={(option) => option.et_name || ""}
              loading={loading}
              onChange={(e, value) => setSelectedTemplate(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Template"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              sx={{ mb: 2 }}
            /> */}
            <Autocomplete
              options={templates || []} // Ensure options is always an array
              getOptionLabel={(option) => option.et_name || ""} // Extract label safely
              loading={loading}
              onChange={(e, value) => setSelectedTemplate(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Template"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              sx={{ mb: 2 }}
            />
            <ReactQuill
              theme="snow"
              value={emailContent}
              onChange={setEmailContent}
              style={{ height: "260px", marginBottom: "50px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={openConfirmationDialog}
            color="primary"
            disabled={
              (!mcqChecked && !codeChecked && !aiAssistanceCheck) ||
              mcqError ||
              codeError
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmationDialog}
        onClose={closeConfirmationDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to send this invitation?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog}>Cancel</Button>
          <Button onClick={confirmAndSendInvite} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.emailTemplate.templates?.rows,
  loading: state.emailTemplate.loading,
});

const mapDispatchToProps = {
  fetchTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendInviteDialog);
