import {
  GET_SKILLS_FAIL,
  GET_SKILLS_SUCCESS,
  SUBMIT_SELF_EVALUATION_FAIL,
  GET_SELF_EVAL_SKILLS_SUCCESS,
  GET_SELF_EVAL_SKILLS_FAIL,
  GET_SELF_EVAL_TEST_SUCCESS,
  GET_SELF_EVAL_TEST_FAIL,
  SUBMIT_MCQ_TEST_SUCCESS,
  SUBMIT_MCQ_TEST_FAIL,
  SUBMIT_AI_INTERVIEW_ANSWER_SUCCESS,
  SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
  UPLAOD_AI_CV_SUCCESS,
  UPLAOD_AI_CV_FAIL,
  CLEAR_DATA_REDUCER,
  TOGGLE_THEME,
  GET_AI_SCORE_REPORT,
  GET_COURSE_SUGGESTIONS,
  DELETE_EVALUATE_SKILL,
  SUBMIT_CODE_TEST_SUCCESS,
} from "./types";
import {
  getSkills,
  addSelfEvaluation,
  getSelfEvalSkills,
  getSelfEvalTest,
  submitMCQTest,
  submitAiAns,
  uploadAiCvData,
  addSkills,
  submitMCQCoding,
  getAIInterviewScore,
  getCourses,
  deleteEvSkill,
  endInt,
} from "../../services/dashboard";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";
import { authUser } from "./auth";

export const toggleThemeAction = (theme) => async (dispatch) => {
  dispatch({
    type: TOGGLE_THEME,
    payload: theme,
  });
};

export const getAllSkills = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getSkills(data);

    if (response.status === 200) {
      dispatch({
        type: GET_SKILLS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_SKILLS_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in getting skills", error);
    dispatch({
      type: GET_SKILLS_FAIL,
    });
  }
};

export const getAllSelfEvalSkills = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getSelfEvalSkills();

    if (response.status === 200) {
      dispatch({
        type: GET_SELF_EVAL_SKILLS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_SELF_EVAL_SKILLS_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in getting skills", error);
    dispatch({
      type: GET_SELF_EVAL_SKILLS_FAIL,
    });
  }
};

export const getAllSelfEvalTest = (skillData, type) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getSelfEvalTest(skillData, type);

    if (response.status === 200) {
      dispatch({
        type: GET_SELF_EVAL_TEST_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_SELF_EVAL_TEST_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in getting skills", error);
    dispatch({
      type: GET_SELF_EVAL_TEST_FAIL,
    });
  }
};
export const submitSelfEvaluation = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await addSelfEvaluation(data);
    if (response.status === 200) {
      dispatch(setMessage("Self evaluation submitted successfully!"));
      dispatch(authUser());
    } else {
      dispatch({
        type: SUBMIT_SELF_EVALUATION_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in add self evaluation", error);
    dispatch({
      type: SUBMIT_SELF_EVALUATION_FAIL,
    });
  }
};

export const submitSkills = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await addSkills(data);
    if (response.status === 200) {
      dispatch(setMessage("Skills added successfully!"));
      dispatch(authUser());
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in add self evaluation", error);
  }
};

export const submitMCQ = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await submitMCQTest(data);
   
    if (response.status === 200) {
      dispatch(setMessage("Test submitted successfully!"));

      dispatch(authUser());
      dispatch({
        type: SUBMIT_MCQ_TEST_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: SUBMIT_MCQ_TEST_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in add self evaluation", error);
    dispatch({
      type: SUBMIT_MCQ_TEST_FAIL,
    });
  }
};

// submitMCQCoding

export const submitMCQCod = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await submitMCQCoding(data);
    if (response.status === 200) {
      dispatch(setMessage("Test submitted successfully!"));

      dispatch(authUser());
      dispatch({
        type: SUBMIT_CODE_TEST_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: SUBMIT_MCQ_TEST_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in add self evaluation", error);
    dispatch({
      type: SUBMIT_MCQ_TEST_FAIL,
    });
  }
};

export const uploadAICV = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await uploadAiCvData(data);
    if (response.status === 200) {
      dispatch({
        type: UPLAOD_AI_CV_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("CV uploaded successfully!"));
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in uploading cv in AI Interview", error);
    dispatch(setMessage("There is some issue, please try again!"));
    dispatch({
      type: UPLAOD_AI_CV_FAIL,
    });
  }
};

export const submitAIanswer = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await submitAiAns(data);
    if (response.status === 200) {
      dispatch({
        type: SUBMIT_AI_INTERVIEW_ANSWER_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("Answer submitted successfully!"));
    } else {
      dispatch({
        type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in uploading cv in AI Interview", error);
    dispatch({
      type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
    });
  }
};

export const concludeInt = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await endInt(data);
    if (response.status === 200) {
      dispatch(setMessage("Interview concluded successfully!"));
    } else {
      dispatch(setMessage("There is some issue, please try again!"));
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(setMessage("There is some issue, please try again!"));
    console.error("error in uploading cv in AI Interview", error);
  }
};

export const getInterviewAIScore = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getAIInterviewScore(data);
    if (response.status === 200) {
      dispatch({
        type: GET_AI_SCORE_REPORT,
        payload: response.data.response,
      });
      dispatch(setMessage("Answer submitted successfully!"));
    } else {
      dispatch({
        type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in uploading cv in AI Interview", error);
    dispatch({
      type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
    });
  }
};
export const getCourse = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getCourses(data);
    if (response.status === 200) {
      dispatch({
        type: GET_COURSE_SUGGESTIONS,
        payload: response.data,
      });
      dispatch(setMessage("Courses fetch successfully"));
    } else {
      dispatch({
        type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in uploading cv in AI Interview", error);
    dispatch({
      type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
    });
  }
};
export const deleteEvSkills =  (sesId) => async (dispatch) => {
  try {
    // dispatch(showLoading());
    const response = await deleteEvSkill(sesId);
    if (response.status === 200) {
      dispatch({type:DELETE_EVALUATE_SKILL});
      dispatch(setMessage("Skill deleted successfully"));
    }
  } catch (error) {
    dispatch(hideLoading());
    console.error("Error in delete evaluate skill", error);
    dispatch({
      type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
    });
  }
};
///api/v1/self_evaluations/course/suggesstions

export const clearRedux = (data) => async (dispatch) => {
 
    dispatch({
      type: CLEAR_DATA_REDUCER,
    });

};

