import React, { useEffect, useRef } from 'react';
import { Editor } from '@monaco-editor/react';

const CodeEditor = ({ onChange }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const handleResizeObserverError = (event) => {
      if (event.message.includes('ResizeObserver loop limit exceeded')) {
        event.stopImmediatePropagation();
      }
    };

    window.addEventListener('error', handleResizeObserverError);

    return () => {
      window.removeEventListener('error', handleResizeObserverError);
      if (editorRef.current) {
        editorRef.current.dispose();
      }
    };
  }, []);

  return (
    <Editor
      height="500px"
      defaultLanguage=""
      defaultValue="// Start coding..."
      theme="vs-dark"
      onMount={(editor) => {
        editorRef.current = editor;
      }}
      onChange={onChange}
    />
  );
};

export default CodeEditor;
