import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Stack,
  MenuItem,
  Select,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createNewTemplate,
  fetchTemplateById,
  updateExistingTemplate,
} from "../../redux/actions/email_template";

const EmailTemplateForm = ({
  createNewTemplate,
  fetchTemplateById,
  updateExistingTemplate,
  template,
}) => {
  const { id } = useParams(); // Get template ID from URL params
  const navigate = useNavigate();
  const location = useLocation(); // To detect mode from the route

  const [formData, setFormData] = useState({
    et_name: "",
    et_subject: "",
    et_body: "",
  });

  const [mode, setMode] = useState("create"); // Mode: "view", "edit", "create"
  const [usedKeywords, setUsedKeywords] = useState([]); // Track used keywords

  const keywords = [
    { label: "Applicant Name", value: "{{userName}}" },
    { label: "Applicant Email", value: "{{userEmail}}" },
    { label: "Applicant Password", value: "{{userPassword}}" },
    { label: "Job Title", value: "{{jobName}}" },
    { label: "Login URL", value: "{{link}}" },
  ];

  // Detect mode from route
  useEffect(() => {
    if (location.pathname.includes("/view")) {
      setMode("view");
      fetchTemplateById(id);
    } else if (id) {
      setMode("edit");
      fetchTemplateById(id); // Fetch template details
    } else {
      setMode("create");
    }
  }, [id, location.pathname, fetchTemplateById]);

  // Populate form if editing or viewing
  useEffect(() => {
    if (template && (mode === "edit" || mode === "view")) {
      setFormData({
        et_name: template.et_name || "",
        et_subject: template.et_subject || "",
        et_body: template.et_body || "",
      });
      extractUsedKeywords(template.et_body);
    }
  }, [template, mode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBodyChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      et_body: value,
    }));
    extractUsedKeywords(value);
  };

  const extractUsedKeywords = (body) => {
    const used = keywords.filter((keyword) => body.includes(keyword.value));
    setUsedKeywords(used.map((k) => k.value));
  };

  const handleKeywordInsert = (keyword) => {
    const editor = formData.et_body || "";
    const updatedBody = `${editor} ${keyword}`;
    setFormData((prevData) => ({
      ...prevData,
      et_body: updatedBody,
    }));
    extractUsedKeywords(updatedBody);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mode === "edit") {
      updateExistingTemplate(id, formData).then(() => {
        navigate("/hr/email-templates");
      });
    } else if (mode === "create") {
      createNewTemplate(formData).then(() => {
        navigate("/hr/email-templates");
      });
    }
  };

  return (
    <Card raised sx={{ maxWidth: 800, margin: "auto", mt: 4 }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {mode === "view"
            ? "View Email Template"
            : mode === "edit"
            ? "Edit Email Template"
            : "Create Email Template"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {mode === "view" ? (
              <>
                <div style={{ marginBottom: "16px" }}>
                  <strong>Template Name:</strong> {formData.et_name || "N/A"}
                </div>
                <div>
                  <strong>Subject:</strong> {formData.et_subject || "N/A"}
                </div>
              </>
            ) : (
              <>
                <TextField
                  label="Template Name"
                  name="et_name"
                  value={formData.et_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Subject"
                  name="et_subject"
                  value={formData.et_subject}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2, // Add some margin at the bottom for spacing
              }}
            >
              <Typography variant="subtitle1">Body</Typography>
              {mode !== "view" && (
                <Select
                  value=""
                  displayEmpty
                  onChange={(e) => handleKeywordInsert(e.target.value)}
                  sx={{
                    minWidth: 200, // Ensure dropdown is wide enough
                  }}
                >
                  <MenuItem disabled value="">
                    Insert Keyword
                  </MenuItem>
                  {keywords.map((keyword) => (
                    <MenuItem
                      key={keyword.value}
                      value={keyword.value}
                      disabled={usedKeywords.includes(keyword.value)}
                    >
                      {keyword.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>

            {mode === "view" ? (
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  minHeight: "300px",
                  p: 2,
                  backgroundColor: "#f9f9f9",
                }}
                dangerouslySetInnerHTML={{ __html: formData.et_body }}
              />
            ) : (
              <>
                <ReactQuill
                  value={formData.et_body}
                  onChange={handleBodyChange}
                  style={{ height: "300px", marginBottom: "20px" }}
                  theme="snow"
                />
                {/* <Stack direction="row" spacing={2}>
                  <Select
                    value=""
                    displayEmpty
                    onChange={(e) => handleKeywordInsert(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      Insert Keyword
                    </MenuItem>
                    {keywords.map((keyword) => (
                      <MenuItem
                        key={keyword.value}
                        value={keyword.value}
                        disabled={usedKeywords.includes(keyword.value)}
                      >
                        {keyword.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack> */}
              </>
            )}

            {mode !== "view" && (
              <Stack direction="row" spacing={2} style={{ paddingTop: "20px" }}>
                <Button variant="contained" color="primary" type="submit">
                  {mode === "edit" ? "Update" : "Create"}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/hr/email-templates")}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  template: state.emailTemplate.template,
});

const mapDispatchToProps = {
  createNewTemplate,
  fetchTemplateById,
  updateExistingTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateForm);
