import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";

import {
  Stack,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import WebcamVideo from "../../components/Recorder/VideoRecorder";
import { getSkillLevel } from "../../utils/getSkillLevelName";
import {
  startInterview,
  concludeInt,
  submitAIanswer,
} from "../../redux/actions/employee";
import AlertDialog from "../../components/Dialog";
import { useNavigate } from "react-router-dom";
import { Userprofile } from "../../redux/actions/auth";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

export const InterviewPage = ({
  submitAIanswer,
  concludeInt,
  // dashboard: { uploadCVRes, submitAnsRes },
  Userprofile,
  currentUserDetails,
  interviewData,
  submitAnsRes,
  startInterview,
  interviewEnded,
  skillData,
}) => {
  const navigate = useNavigate();
  const { speak } = useSpeechSynthesis();

  const [chatThread, setChatThread] = useState([]);
  const [response, setResponse] = useState({});
  const [threadId, setThreadId] = useState(null);
  const [intwId, setIntwId] = useState(null);
  const [assistId, setAssistId] = useState(null);
  const [vectId, setVectId] = useState(null);
  const [qaId, setQaId] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [userId, setUserId] = useState("");
  const [startInt, setStartInt] = useState(false);
  const [audio, setAudio] = useState(null);
  const [mute, setMute] = useState(true);
  const [finalSkills, setFinalSkills] = useState([]);
  const [seId, setSeId] = useState(null);
  

  // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setShowExitPrompt(!showExitPrompt);
  // };

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setShowExitPrompt(!showExitPrompt);
  // };

  // useEffect(() => {
  //   return () => {
  //     setShowExitPrompt(false);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!interviewEnded) {
  //     handleClick();
  //   }
  // }, [interviewEnded]);

  useEffect(() => {
    if (interviewEnded) {
      setOpen(false);
      setConfirm(false);
      navigate("/dashboard");
    }
  }, [interviewEnded]);

  useEffect(() => {
    if (response && response.question && !mute) {
      speak({ text: response.question });
    }
  }, [mute]);

  useEffect(() => {
    // console.log("startInt", skillData && skillData);

if (startInt) {
  if (skillData && skillData.length > 0) {
    let newSkills = skillData.map((skill) => {
      return {
        name: skill.ses_skills_name,
        level: getSkillLevel(skill.ses_skills_level),
        experience: skill.ses_skills_exp,
      };
    });
    // console.log("newSkills", newSkills);
    setFinalSkills(newSkills)
    let ses_id = skillData[0].ses_id;

    setSeId(ses_id);

    startInterview(ses_id, newSkills);
  }
}
  }, [startInt]);

  useEffect(() => {
    let timmer = setTimeout(() => Userprofile());
    return () => {
      clearTimeout(timmer);
    };
  }, []);

  useEffect(() => {
    if (currentUserDetails) {
      setUserId(currentUserDetails?.u_id);
    }
  }, [currentUserDetails]);

  useEffect(() => {
    if (interviewData) {
      setThreadId(interviewData.thread_id);
      setIntwId(interviewData.intw_id);
      setAssistId(interviewData.assistant_id);
      setVectId(interviewData.vector_store_id);
      setQaId(interviewData.intqa_id);
      setQuestionCount(1);
      let obj = {
        id: uuidv4(),
        question: interviewData.question,
        answer: "",
      };
      setResponse(obj);
      // setChatThread([...chatThread, obj]);
    }
  }, [interviewData]);

  useEffect(() => {
    if (submitAnsRes) {
      setThreadId(submitAnsRes.thread_id);
      setIntwId(submitAnsRes.intw_id);
      setAssistId(submitAnsRes.assistant_id);
      setVectId(submitAnsRes.vector_store_id);
      setQaId(submitAnsRes.intqa_id);
      setQuestionCount(submitAnsRes.questionsCount);

      let obj = {
        id: uuidv4(),
        question: submitAnsRes.response,
        answer: submitAnsRes.transcription,
      };
      setResponse(obj);
      setChatThread(submitAnsRes.QAs);
    }
  }, [submitAnsRes]);

  const submitData = async () => {
    setTitle("Conclude Interview");
    setContent("Are you sure you want to conclude this interview?");
    setOpen(true);
  };

  useEffect(() => {
    if (confirm) {
      concludeInt({
        intwId,
        threadId,
        skills: finalSkills,
        seId: seId,
      });
    }
  }, [confirm]);

  useEffect(() => {
    if (audio) {
      const data = {
        audio_id: audio,
        thread_id: threadId,
        intw_id: intwId,
        assistant_id: assistId,
        vector_store_id: vectId,
        intqa_id: qaId,
        skills: finalSkills,
        seId : seId
      };

      submitAIanswer(data);
      setAudio(null);
    }
  }, [audio]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />

      {/* <button onClick={handleClick}>Show/Hide the prompt</button> */}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2" textAlign="center" color="primary">
          Welcome to
        </Typography>
        <Typography variant="h4" textAlign="center" color="primary">
          {" "}
          AI Interview Assitant
        </Typography>
        {threadId && (
          <>
            <Button
              style={{
                marginTop: "10px",
                width: "10vw",
                textAlign: "center",
              }}
              variant="contained"
              onClick={submitData}
              disabled={!threadId}
            >
              Conclude
            </Button>
            <Typography variant="caption" color="green" sx={{ mt: 1 }}>
              * Please conclude interview before leaving the page
            </Typography>
          </>
        )}
      </Stack>

      {threadId && threadId ? (
        <Stack
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 3,
          }}
        >
          <Card sx={{ width: "50%" }}>
            <Typography textAlign="center"></Typography>
            <WebcamVideo
              threadId={threadId && threadId}
              userId={userId && userId}
              setAudio={setAudio}
              qaId={qaId && qaId}
              intwId={intwId && intwId}
            />
          </Card>
          <Card sx={{ width: "50%" }}>
            <CardHeader />
            <CardContent>
              {response.question && (
                <>
                  <Typography>
                    {" "}
                    <IconButton onClick={() => setMute(!mute)}>
                      {mute ? <VolumeOffIcon /> : <VolumeUpIcon />} :
                    </IconButton>{" "}
                    {response.question}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <Stack
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" onClick={() => setStartInt(true)}>
            Start
          </Button>
        </Stack>
      )}

      {chatThread.length > 0 && (
        <Stack
          sx={{
            mt: 5,
            ml: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">AI Post Interview Analysis</Typography>
          {chatThread.map((res, index) => (
            <>
              {res.intqa_answer ? (
                <Card key={res.intqa_id} sx={{ minWidth: "30vw" }}>
                  <CardContent>
                    <Typography variant="h6">
                      🤖 : {res.intqa_question}
                    </Typography>
                    <Typography>🧑 : {res.intqa_answer}</Typography>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}
            </>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  currentUserDetails: state.auth.user,
  interviewData: state.employee.interviewData,
  submitAnsRes: state.employee.submitAnsRes,
  interviewEnded: state.employee.interviewEnded,
  skillData: state.skills.data,
});

const mapDispatchToProps = {
  startInterview,
  submitAIanswer,
  concludeInt,
  Userprofile,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewPage);
