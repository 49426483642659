import axios from "axios";

export const getCurrentStateChartData = async () => {
    try {
        const chart_url = `/charts/current_state`;
       const response = await axios.get(chart_url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getFeatureStateChartData = async () => {
    try {
        const chart_url = `/charts/fetaure_state`;
        const response = await axios.get(chart_url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getEmployeeTechnologyData = async () => {
    try {
        const chart_url = `/charts/technology_data`;
        const response = await axios.get(chart_url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSkillGapData = async () => {
    try {
        const chart_url = `/charts/skill_gap`;
        const response = await axios.get(chart_url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSkillStrengthData = async () => {
    try {
        const chart_url = `/charts/skill_strength`;
        const response = await axios.get(chart_url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSkillExperienceData = async () => {
    try {
        const chart_url = `/charts/skill_exp`;
        const response = await axios.get(chart_url);
        return response;
    } catch (error) {
        throw error;
    }
}