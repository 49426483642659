import { getLabelOptions, getLabels, saveOrgLabel } from "../../services/label.service";
import { hideLoading, showLoading } from "./loader";
import { GET_ALL_LABEL_FAIL, GET_ALL_LABEL_OPTIONS_FAIL, GET_ALL_LABEL_OPTIONS_SUCCESS, GET_ALL_LABEL_SUCCESS, SAVE_ORG_LABEL_SUCCESS, SAVE_ORG_LABEL_FAIL } from "./types";

export const setSkillData = (data) => {
  return {
    type: 'SET_DATA',
    payload: data,
  };
};

export const getAllLabels = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getLabels(data);

    if (response.status === 200) {
      dispatch({
        type: GET_ALL_LABEL_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_ALL_LABEL_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch({
      type: GET_ALL_LABEL_FAIL,
    });
  }
};

export const getAllLabelOptions = (page, size) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getLabelOptions(page, size);

    if (response.status === 200) {
      response.data.rows.map((item, id) => ({ ...item, id }))
      dispatch({
        type: GET_ALL_LABEL_OPTIONS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_ALL_LABEL_OPTIONS_FAIL,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch({
      type: GET_ALL_LABEL_OPTIONS_FAIL,
    });
  }
};

export const saveOrganizationLabel = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    const response = await saveOrgLabel(payload);
    const { labels } = getState();
    let updatedLabels;

    if (response.status === 200) {
      updatedLabels = labels.allLabels.map(item =>
        item.ol_id === response.data.org_label.ol_id
          ? { ...item, ol_value: response.data.org_label.ol_value }
          : item
      );
    } else if (response.status === 201) {
      updatedLabels = [...labels.allLabels, response.data.org_label];
    } else {
      throw new Error('Failed to save organization label');
    }

    dispatch({
      type: GET_ALL_LABEL_SUCCESS,
      payload: updatedLabels,
    });
  } catch (error) {
    dispatch({ type: SAVE_ORG_LABEL_FAIL });
  } finally {
    dispatch(hideLoading());
  }
};