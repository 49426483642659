import React, {  useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { getEmployeeTechnologyData } from "./../../services/chart.service";
import jsonData from "./layout.json";
import { Card } from "@mui/material";

const SkillsPieChart = () => {
  const [technologyData, setTechnologyData] = useState();

  useEffect(() => {
    fetchEmployeeTechnologyData();
  }, []);

  const fetchEmployeeTechnologyData = async () => {
    try {
      const response = await getEmployeeTechnologyData();
      setTechnologyData(response?.data);
    } catch (error) {
      throw error;
    }
  };
     let config = {
       responsive: true,
       displayModeBar: true,
       displaylogo: false,
       showLink: false,
     };

  return (
    <>
      {technologyData &&
      technologyData?.result &&
      technologyData?.result?.data ? (
        <Card
          sx={{
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Plot
            data={technologyData?.result?.data}
              layout={jsonData.layoutPie}
              config={config}
          />
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default SkillsPieChart;
