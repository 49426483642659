import { useState, useEffect } from "react";

const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "Please conclude interview before leaving the page";
      }
      return "Please conclude interview before leaving the page";
    }
    // return "Please conclude interview before leaving the page";
    };
      window.onunload = (event) => {
        if (showExitPrompt) {
          const e = event || window.event;
          e.preventDefault();
          if (e) {
            e.returnValue = "Please conclude interview before leaving the page";
          }
          return "";
        }
        // return "Please conclude interview before leaving the page";
      };
};

export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
