import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Copyright from "../../components/Copyright";
import {
  resetPasswordUser,
  verifyResetTokenUser,
} from "../../redux/actions/auth";
import Logo from "../../components/Logo";
import { useSearchParams } from "react-router-dom";
import { TextField, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ResetPassword = ({
  email,
  resetPasswordUser,
  resetPasswordSuccess,
  verifyResetTokenUser,
  verifyResetTokenSuccess,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(true);

  const [params, setParams] = useSearchParams();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await verifyResetToken(params.get("token"));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    verifyToken();
  }, [params.get("token")]);

  const verifyResetToken = async (token) => {
    try {
      await verifyResetTokenUser({ token: params.get("token") });
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(true);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const captchaToken = await executeRecaptcha("reset_password");

      await resetPasswordUser({
        password,
        token: params.get("token"),
        reCaptchaToken: captchaToken,
      });
      toast.success("Password reset successfully!");
      navigate("/signin");
    } catch (error) {
      console.log(error);
      toast.error(error || "Something went wrong!");
    }
  };

  const handleLoginClick = () => {
    navigate("/signin");
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container item xs={12} sm={12}>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // alignContent: "center",
          minHeight: "100vh",
          justifyContent: "center",
          background: "white",
        }}
      >
        <Logo />
        <Typography  variant="h5" sx={{ mt: 2 }}>
          Reset Password
        </Typography>
        {verifyResetTokenSuccess ? (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <FormControl
              variant="outlined"
              sx={{ mt: 2, width: 350, height: 50 }}
              required
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Email
              </InputLabel>
              <OutlinedInput
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                disabled
              />
            </FormControl>
            {resetPasswordSuccess ? (
              <Typography component="body2" sx={{ mt: 2 }}>
                You've successfully reset your password.
              </Typography>
            ) : (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <FormControl
                  variant="outlined"
                  sx={{ width: 350, height: 50 }}
                  required
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="Password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography variant="caption" color="error">
                    {passwordError}
                  </Typography>
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 4, mb: 2 }}
                  disabled={!password}
                >
                  Reset Password
                </Button>
              </Grid>
            )}
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Alert severity="error" variant="outlined">
              <AlertTitle>Invalid Token</AlertTitle>
              Please make sure you are using a valid reset password link.
            </Alert>
            <Button
              onClick={handleLoginClick}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Go to Login
            </Button>
          </Box>
        )}

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <div className="container-fluid homepage-bgimage"></div>
      </Grid>
    </Grid>
  );
};

const ResetPasswordWithReCaptcha = ({
  email,
  resetPasswordUser,
  resetPasswordSuccess,
  verifyResetTokenUser,
  verifyResetTokenSuccess,
}) => {
  const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_API_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <ResetPassword
        email={email}
        resetPasswordUser={resetPasswordUser}
        resetPasswordSuccess={resetPasswordSuccess}
        verifyResetTokenUser={verifyResetTokenUser}
        verifyResetTokenSuccess={verifyResetTokenSuccess}
      />
    </GoogleReCaptchaProvider>
  );
};

const mapStateToProps = (state) => ({
  email: state.auth.email,
  resetPasswordSuccess: state.auth.resetPasswordSuccess,
  verifyResetTokenSuccess: state.auth.verifyResetTokenSuccess,
});

const mapDispatchToProps = { resetPasswordUser, verifyResetTokenUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordWithReCaptcha);
