import {
  GET_EMPLOYEE_PROFILE_DETAIL,
  UPDATE_EMPLOYEE_PROFILE_DETAIL,
  START_INTERVIEW_EMP_SUCCESS,
  SUBMIT_INTERVIEW_QUESTION_EMP_SUCCESS,
  END_INTERVIEW_EMP_SUCCESS,
} from "../actions/types";

const initialState = {
  employeeProfileDetail: null,
  interviewData: null,
  submitAnsRes: null,
  interviewEnded: false,
};



const employee = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case START_INTERVIEW_EMP_SUCCESS:
            return {
                ...state,
                interviewData: payload,
            }
        case SUBMIT_INTERVIEW_QUESTION_EMP_SUCCESS:
            return {
                ...state,
                submitAnsRes: payload,
            }
        case END_INTERVIEW_EMP_SUCCESS:

            return {
                ...state,
                interviewEnded: true,
            }
        case GET_EMPLOYEE_PROFILE_DETAIL:
            return {
                ...state,
                employeeProfileDetail: payload,
            }
        
        case UPDATE_EMPLOYEE_PROFILE_DETAIL:
            return {
                ...state,
                employeeProfileDetail: payload,
            }
        default:
            return state;
    }
};

export default employee;