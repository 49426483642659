import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({
  title,
  content,
  saveButtonText,
  cancelButtonText,
  setConfirm,
  open,
  setOpen,
}) {
  const onClickCancel = () => {
    setOpen(false);
    setConfirm(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onClickSave = () => {
    setOpen(false);
    setConfirm(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel}>
          {cancelButtonText ? cancelButtonText : "Cancel"}
        </Button>
        <Button onClick={onClickSave} autoFocus>
          {saveButtonText ? saveButtonText : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
