import {
  GET_BATCH_SUCCESS,
  UPDATE_BATCH_TITLE_SUCCESS,
  GET_JD_BATCHES_SUCCESS,
  GET_BATCHES_SUCCESS,
  CLEAR_BATCH_DATA,
  GET_BATCH_PROGRESS_SUCCESS,
  GET_MODEL_NAME_WITH_JOBID_SUCCESS
} from "../actions/types";

const initialState = {
  batchData: null,
  jDBatchesList: null,
  batchesList: null,
  batchProgress: null,
  modelNameOfJobId: null,
};

const batches = (state = initialState, action) => {
    const { type, payload } = action;
  switch (type) {
    case GET_BATCH_PROGRESS_SUCCESS:
      return {
        ...state,
        batchProgress: payload,
      };
    case CLEAR_BATCH_DATA:
      return {
        ...state,
        batchData: null,
      };
      case GET_BATCH_SUCCESS:
        return {
          ...state,
          batchData: payload,
        };
      case GET_JD_BATCHES_SUCCESS:
        return {
          ...state,
          jDBatchesList: payload,
        };
      case GET_BATCHES_SUCCESS:
        return {
          ...state,
          batchesList: payload,
        };

      case UPDATE_BATCH_TITLE_SUCCESS:
        return {
          ...state,
          batchData: payload,
        };
      case GET_MODEL_NAME_WITH_JOBID_SUCCESS:
        return {
          ...state,
          modelNameOfJobId: payload,
        };
      default:
        return state;
    }
};
export default batches;