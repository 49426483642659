import { useState, useEffect } from "react";
import { Button, Stack, Typography, Box, Modal } from "@mui/material";
import { connect } from "react-redux";
import {  useNavigate } from "react-router-dom";
import {
  getAllSelfEvalTest,
  submitMCQCod,
} from "../../redux/actions/dashboard";
import Editor from "../../components/Editor/Editor";
import AlertDialog from "../../components/Dialog";



const modalPopUpStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "8px",
  textAlign: "center",
};

export const TestCoding = ({
  dashboard: { test, testResultCode },
  getAllSelfEvalTest,
  submitMCQCod,
  skillData,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [answerSheetCoding, setAnswerSheetCoding] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPassing, setIsPassing] = useState(0);
  const [openSecond, setOpenSecond] = useState(false);

  useEffect(() => {
    if (testResultCode) {
      setOpenSecond(true);
      const isPassings = testResultCode && testResultCode.finalPercentage > 50;
      setIsPassing(isPassings);
    }
  }, [testResultCode]);

  const onChangAnswer = (code, q_id, ques) => {
    const answer = code;
    let obj = { q_id, answer, ques };
    setAnswerSheetCoding((prev) => {
      let index = prev.findIndex((item) => item.q_id === q_id);
      if (index > -1) {
        prev[index] = obj;
      } else {
        prev.push(obj);
      }
      return [...prev];
    });
  };

  useEffect(() => {
    if (!skillData) {
      // console.log("Heloooooooo====>", JSON.parse(localStorage.getItem('skillData')));
      skillData = JSON.parse(localStorage.getItem("skillData"));
    }
  }, [skillData]);

  useEffect(() => {
    if (skillData && skillData.length) {
      getAllSelfEvalTest({ skills: skillData }, "coding");
    }
  }, [skillData]);

  useEffect(() => {
    if (confirm) {
      let data = {
        skills: skillData,
        answerSheetCoding: answerSheetCoding,
      };
      submitMCQCod(data);
      setOpen(false);
      setConfirm(false);
      // navigate("/dashboard");
    }
  }, [confirm, skillData]);

  const submitData = async () => {
    setTitle("Submit Test");
    setContent("Are you sure you want to submit the test?");
    setOpen(true);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < test.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    setOpenSecond(false);
    navigate("/employee/ai-assistant", { replace: true });
    // window.location.href = "/employee/dashboard";
  };

  return (
    <Stack
      direction={{
        xs: "column",
        sm: "column",
        width: "-webkit-fill-available",
      }}
      alignItems="center"
    >
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />
      <Stack
        direction={{ xs: "row", sm: "row" }}
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Button
          color="primary"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={handlePreviousQuestion}
          disabled={currentQuestionIndex === 0}
        >
          Previous
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={handleNextQuestion}
          disabled={currentQuestionIndex === test.length - 1}
        >
          Next
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={submitData}
        >
          Submit
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="label"
          variant="contained"
          tabIndex={-1}
          onClick={() => navigate("/dashboard")}
        >
          Back
        </Button>
      </Stack>

      <Stack
        direction={{ xs: "row", sm: "row" }}
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Test Coding
        </Typography>
      </Stack>

      {test && test.length > 0 && (
        <Stack
          direction={{ xs: "column", sm: "column" }}
          alignSelf="center"
          spacing={1}
          useFlexGap
          sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h6">
              {currentQuestionIndex + 1}. {test[currentQuestionIndex].q_name}{" "}
            </Typography>{" "}
          </Stack>
          <Stack direction="column" sx={{ width: "100%" }}>
            <Editor
              key={test[currentQuestionIndex].q_id}
              onChange={(e) =>
                onChangAnswer(
                  e,
                  test[currentQuestionIndex].q_id,
                  test[currentQuestionIndex].q_name
                )
              }
              style={{ width: "100%" }}
            />
          </Stack>
        </Stack>
      )}

      <Modal
        open={openSecond}
        onClose={() => setOpenSecond(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="child-modal-title">
            Your Score is
          </Typography>

          <Box >
            <Typography>
              Your code is under review, you can check on dashboard.
            </Typography>
          </Box>

          {/* {testResultCode && testResultCode.length > 0 ? (
            <>
              {console.log("testResultCode", testResultCode)}
              {testResultCode.map((t) => (
                <Box key={t.skill_id}>
                  <Typography>
                    {t.name} : {t.coding_score}
                  </Typography>
                </Box>
              ))}
            </>
          ) : (
            <>
              {skillData.map((t) => (
                <Box key={t.ses_id}>
                  <Typography>
                    {t.ses_skills_name} : 0
                  </Typography>
                </Box>
              ))}
            </>
          )} */}

          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color={isPassing ? "success" : "error"}
              onClick={handleSubmit}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  skillData: state.skills.data,
  dashboard: state.dashboard,
});

const mapDispatchToProps = {
  getAllSelfEvalTest,
  submitMCQCod,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCoding);
