import React, { useEffect, useRef, useState } from "react";
import { IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { io } from "socket.io-client";
import { createAudioVideo } from "../../redux/actions/jobs";
import { connect } from "react-redux";
import * as faceapi from "face-api.js";
import { _checkTransCription } from "../../services/applicant.service";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');

const socket = io(BASE_URL, {
  path: `/${orgName}/socket.io`,  // Adjust the path if necessary
});

const constraints = {
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
  },
  video: {
    width: 640,
    height: 480,
    aspectRatio: 1.7777777778,
    frameRate: { ideal: 10, max: 20 },
    facingMode: "user",
  },
};
const WARNING_THRESHOLD = 2;
const WebcamVideo = ({ streamRef, threadId, userId, setAudio, qaId, intwId, startCapturing, onTranscriptUpdate, timeLeft, setConfirm, setViolationData, nextQestion, setStartCapturing, showWaitMessage, setIsLoaderVisible }) => {
  const [permission, setPermission] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [countdown, setCountdown] = useState(30); // Countdown timer for popup
  const mediaRecorder = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const videoRecorder = useRef(null);
  const audioRecorder = useRef(null);
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [multiFaceWarning, setMultiFaceWarning] = useState(false); // State to track warning popup
  const [detectedFaces, setDetectedFaces] = useState(0); // Track number of faces
  const [noFaceWarning, setNoFaceWarning] = useState(false); // Popup for no face detected
  const [lookingAwayWarning, setLookingAwayWarning] = useState(false); // Popup for looking away
  const [deviceWarning, setDeviceWarning] = useState(false);
  const [multiFace, setMultiFace] = useState(0);
  const [noFace, setNoFace] = useState(0);
  const [lookingAway, setLookingAway] = useState(0);
  const [reason, setReason] = useState("");
  const [showConcludePop, setshowConcludePop] = useState(false);
  const [microphoneQuality, setMicrophoneQuality] = useState(0); // Microphone quality as a percentage
  const [isMuted, setIsMuted] = useState(false);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);


  let audioChunks = useRef([]); // Persist audioChunks with useRef to maintain reference across intervals
  const countdownInterval = useRef(null); // Persist the countdown interval across renders

  let transcriptionInterval;

  const cancelOngoingAPI = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
  };

  const handleWarning = (key, message) => {
    if (!capturing && key === "noFace") {
      return;
    }
    if (key === "noFace") {
      setNoFace((prevNoFace) => prevNoFace + 1);
    } else if (key === "multiFace") {
      setMultiFace((prevMultiFace) => prevMultiFace + 1);
    } else if (key === "lookingAway") {
      setLookingAway((prevLookingAway) => prevLookingAway + 1);
    }
  };

  const onlyUpdateNoFace = () => {
    setNoFace((prevNoFace) => prevNoFace + 1);
  }
  
  useEffect(() => {
    console.log(noFace, multiFace, lookingAway);
    // Handle warnings sequentially
    if (noFace > WARNING_THRESHOLD) {
      setshowConcludePop(false);
      setViolationData(JSON.stringify({
        noFace:noFace,
        multiFace:multiFace,
        lookingAway:lookingAway,
        reason:reason?reason:"",
      }));
      // setReason(`We are concluding your interview because of repeated violations: No Face Detected`);
    } else if (multiFace > WARNING_THRESHOLD) {
      setshowConcludePop(true);
      setReason(`We are concluding your interview because of repeated violations: Multiple faces detected in the frame`);
    } else if (lookingAway > WARNING_THRESHOLD) {
      setViolationData(JSON.stringify({
        noFace:noFace,
        multiFace:multiFace,
        lookingAway:lookingAway,
        reason:reason?reason:"",
      }));
      setshowConcludePop(false);
      // setReason(`We are concluding your interview because of repeated violations: Looking Away`);
    }
  }, [noFace, multiFace, lookingAway]);

  useEffect(() => {
    console.log(noFace, multiFace, lookingAway);
    if (reason) {
      setViolationData(JSON.stringify({
        noFace:noFace,
        multiFace:multiFace,
        lookingAway:lookingAway,
        reason:reason,
      }));
      setConfirm(true);
    }
  }, [reason]);
  
  useEffect(() => {
    console.log(noFace, multiFace, lookingAway);
    if (audioStream) {
      checkMicrophoneQuality(audioStream);
    }
  }, [audioStream]);

  // useEffect(()=>{
  //   console.log(capturing,noFaceWarning)
  //   if (capturing && noFaceWarning) {
  //     onlyUpdateNoFace();
  //     setNoFaceWarning(false);
  //   } else {
  //     setNoFaceWarning(false);
  //   }
  // }, [capturing]);


  const loadFaceDetectionModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + "/models";
    await Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]);
    console.log("FaceAPI models loaded");
  };

  const handleVideoOnPlay = () => {
    let previousFaceCount = 0; // Track the previous face count
    let previousFaceBoxes = []; // Track previous face bounding boxes

    const intervalId = setInterval(async () => {
      // Only proceed if the video is ready
      if (canvasRef.current && videoRef.current.readyState === 4) {
        const displaySize = { width: 640, height: 480 };
        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi
          .detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions({
            inputSize: 416,    // Larger size for better accuracy (default is 224)
            scoreThreshold: 0.6 // Higher value decreases sensitivity
          }))
          .withFaceLandmarks()
          .withFaceExpressions();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);

        const faceCount = detections.length;
        setDetectedFaces(faceCount);

        const currentFaceBoxes = detections.map(detection => detection.detection.box);

        // Warn if multiple faces are detected
        if (faceCount > 1 && !multiFaceWarning) {
          console.warn("Multiple faces detected!");
          handleWarning("multiFace", "Multiple faces detected in the frame");
          setMultiFaceWarning(true);
        }

        // Detect if no face is in view (face out of window)
        if (faceCount === 0 && !noFaceWarning) {
          console.warn("No face detected! User might have moved out of the camera view.");
          setNoFaceWarning(true);
          handleWarning("noFace", "No face detected in the frame");
        }

        // Save current face data for the next iteration
        previousFaceCount = faceCount;
        previousFaceBoxes = currentFaceBoxes;

        // Check if the user is looking away from the screen
        if (detections.length > 0) {
          const faceLandmarks = detections[0].landmarks;
          const leftEye = faceLandmarks.getLeftEye();
          const rightEye = faceLandmarks.getRightEye();

          const leftToRightEyeDist = Math.abs(leftEye[0].x - rightEye[3].x);
          if (leftToRightEyeDist < 70 && !lookingAwayWarning) {
            console.warn("User might not be looking at the screen!");
            setLookingAwayWarning(true);
            handleWarning("lookingAway", "Not looking at the screen");
          }
        }
      }
    }, 3000);

    return () => clearInterval(intervalId);
  };

  // Helper function to detect suspicious obstacles
  const isSuspiciousObstacle = (previousBoxes, currentBoxes) => {
    if (previousBoxes.length !== currentBoxes.length) {
      return true; // New face or obstacle added
    }

    for (let i = 0; i < currentBoxes.length; i++) {
      const prevBox = previousBoxes[i];
      const currBox = currentBoxes[i];

      // Check if the box dimensions or position have changed significantly
      if (
        Math.abs(prevBox.x - currBox.x) > 50 || // Significant x-axis movement
        Math.abs(prevBox.y - currBox.y) > 50 || // Significant y-axis movement
        Math.abs(prevBox.width - currBox.width) > 30 || // Significant size change
        Math.abs(prevBox.height - currBox.height) > 30 // Significant size change
      ) {
        return true; // Obstacle detected
      }
    }

    return false;
  };

  // Function to handle warning popup close
  const handleMultiFaceWarningClose = () => {
    setMultiFaceWarning(false);
  };

  const handleNoFaceWarningClose = () => {
    setNoFaceWarning(false);
    onlyUpdateNoFace();
  };

  const handleLookingAwayWarningClose = () => {
    setLookingAwayWarning(false);
  };

  const analyzeAudio = async (audioBlob) => {
    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const arrayBuffer = await audioBlob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const channelData = audioBuffer.getChannelData(0);

      const threshold = 0.02;
      const result = channelData.some(sample => Math.abs(sample) > threshold);
      console.log("Audio analyzed, result:", result);
      return result;
    } catch (error) {
      console.error("Error decoding audio data:", error);
      return false;
    }
  };

  // const startTranscriptionInterval = () => {
  //   transcriptionInterval = setInterval(async () => {
  //     if (audioChunks.current.length > 0) {
  //       const last5SecAudio = new Blob(audioChunks.current, { type: "audio/webm" });
  //       const audioPresent = await analyzeAudio(last5SecAudio);
  //       if (!audioPresent) {
  //         console.log("No audio detected, opening popup");
  //         showPopup();
  //       }
  //       audioChunks.current.length = 0; // Clear the chunks after processing
  //     } else {
  //       console.log("No audio chunks available to analyze");
  //     }
  //   }, 15000);
  // };

  // const stopTranscriptionInterval = () => {
  //   clearInterval(transcriptionInterval);
  // };

  const checkMicrophoneQuality = async (stream) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);
  
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
  
    source.connect(analyser);
  
    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;
  
    let silentFrames = 0; // Count of consecutive silent frames
    const silenceThreshold = 50; // Number of frames to confirm mute
    const silenceLevel = 80; // Adjusted threshold for silence detection
  
    const analyzeAudio = () => {
      analyser.getByteTimeDomainData(dataArray);
      const sum = dataArray.reduce((acc, value) => acc + Math.abs(value - 128), 0);
      const average = sum / dataArray.length;
  
      const quality = Math.min((average / 128) * 100, 100); // Calculate quality as a percentage
      setMicrophoneQuality(quality);
      // console.log("==========>",sum);
  
      // const isAudioSilent = sum < 80;
      // Increment or reset silentFrames based on audio input level
      if (sum < silenceLevel) {
        silentFrames++;
      } else {
        silentFrames = 0;
      }
      const isAudioSilent = silentFrames > silenceThreshold;
      if (stream.getAudioTracks()[0].muted) {
        setIsMuted(true);
      } else if (isAudioSilent) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }
  
      // console.log(
      //   "Audio Input Level:",
      //   sum,
      //   "Silent Frames:",
      //   silentFrames,
      //   "Muted:",
      //   silentFrames > silenceThreshold
      // );
      
      // Check if the microphone is muted based on low or no audio input
      // const isAudioSilent = sum < 5; // Low threshold for audio input
      // setIsMuted(isAudioSilent);
      // console.log("====================>", stream.getAudioTracks());
      requestAnimationFrame(analyzeAudio);
    };
  
    analyzeAudio();
  };

  const startVideoStream = async () => {
    try {
      // const stream = await navigator.mediaDevices.getUserMedia(constraints);
      //const stream = streamRef.current;
      const selectedDeviceId = localStorage.getItem('selectedDeviceId');
      
      // Build constraints with the selected device ID for video input
      const constraints = {
        video: {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
        },
        audio: false, // Enable audio
      };
  
      // Request media permissions
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      videoRef.current.srcObject = stream;
      setVideoStream(stream);

      const videoMediaRecorder = new MediaRecorder(stream);
      videoRecorder.current = videoMediaRecorder;
      videoMediaRecorder.ondataavailable = (event) => {
        socket.emit("video-stream", {
          data: event.data,
          vdUserId: userId,
          streamId: qaId,
        });
      };
      videoMediaRecorder.start(100);
      const selectedDeviceIdAudio = localStorage.getItem('selectedDeviceIdAudio');
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: selectedDeviceIdAudio? { deviceId: { exact: selectedDeviceIdAudio } } : undefined });
      setAudioStream(audioStream);

      const audioMediaRecorder = new MediaRecorder(audioStream);
      audioRecorder.current = audioMediaRecorder;

      audioMediaRecorder.ondataavailable = (event) => {
        socket.emit("audio-stream", { data: event.data, vdUserId: userId, streamId: qaId });
        audioChunks.current.push(event.data);

        // Keep only the last 10 seconds of chunks
        const maxDurationMs = 10000; // 10 seconds
        const chunkDurationMs = 100; // assuming each chunk is 100ms
        const maxChunks = Math.ceil(maxDurationMs / chunkDurationMs); // number of chunks to keep

        if (audioChunks.current.length > maxChunks) {
          // Remove the oldest chunks to keep the buffer size within the last 10 seconds
          audioChunks.current.shift();
        }
      };

      audioMediaRecorder.start(100);

      console.log("Starting transcription interval");
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  const stopRecording = () => {
    videoRecorder.current?.stop();
    audioRecorder.current?.stop();
    videoStream?.getTracks().forEach(track => track.stop());
    audioStream?.getTracks().forEach(track => track.stop());
    resetTranscript();
    SpeechRecognition.stopListening();
    cancelOngoingAPI();
    setAudio(qaId);
    setCapturing(false);
    setStartCapturing(false);
    setIsLoaderVisible(true); // Show loader
  };

  const getCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia(constraints);
      setPermission(true);
    } catch (err) {
      alert("Error accessing camera/microphone");
      console.error("Camera permission error:", err);
    }
  };

  const showPopup = () => {
    setOpenPopup(true);
    resetCountdown(); // Reset and start the countdown when the popup is shown
    // stopTranscriptionInterval(); // Stop the interval when popup opens
  };

  const resetCountdown = () => {
    setCountdown(30); // Reset the countdown timer
    if (countdownInterval.current) clearInterval(countdownInterval.current); // Clear any existing interval
    countdownInterval.current = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(countdownInterval.current); // Stop the countdown when time's up
          console.log("Auto-submitting due to timeout");
          handlePopupClose(true); // Auto-submit if timer reaches 0
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handlePopupClose = (submit) => {
    if (countdownInterval.current) clearInterval(countdownInterval.current); // Stop the countdown interval
    setOpenPopup(false);
    // stopTranscriptionInterval();
    if (submit) {
      console.log("Stopping recording due to user action or timeout");
      stopRecording();
    } else {
      console.log("User chose to continue, restarting transcription interval");
      //resetTranscript(); // Restart the transcription interval if the user chooses to continue
    }
  };

  useEffect(() => {
    loadFaceDetectionModels();
  }, []);

  useEffect(() => {
    if (transcript.trim().length > 0) {
      const timeout = setTimeout(() => {
        showPopup(); // Auto-stop after 5 seconds of silence
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [transcript]);

  useEffect(() => {
    if (startCapturing) {
      startVideoStream();
      setCapturing(true);
    }
  }, [startCapturing]);

  useEffect(() => {
    if (!permission) {
      getCameraPermission();
    }
  }, [permission]);


  useEffect(() => {
    if (timeLeft === 1) {
      handlePopupClose(true)
    }
  }, [timeLeft]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedData = () => {
        handleVideoOnPlay();
      };
      videoElement.addEventListener('loadeddata', handleLoadedData);
      return () => {
        videoElement.removeEventListener('loadeddata', handleLoadedData);
      };
    }
  }, []);

  useEffect(() => {
    // When the transcript updates, send it to the parent component
    if (onTranscriptUpdate && transcript) {
      onTranscriptUpdate(transcript);
    }
  }, [transcript, onTranscriptUpdate]);

  useEffect(() => {
    // When the transcript updates, send it to the parent component
    if(noFaceWarning && capturing) {
      handleNoFaceWarningClose();
    }
  }, [noFaceWarning]);

  useEffect(() => {
    // When the transcript updates, send it to the parent component
    if(nextQestion) {
      handlePopupClose(true);
    }
  }, [nextQestion]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
      {/* Fullscreen Loader */}
      <Dialog
        open={showConcludePop}
        onClose={() => setshowConcludePop(false)}
        aria-labelledby="device-warning-titles"
        aria-describedby="device-warning-descriptions"
      >
        <DialogTitle id="device-warning-titles">{"Violation Limit Reched"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="device-warning-description">
            {reason}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* <Dialog
        open={deviceWarning}
        onClose={() => setDeviceWarning(false)}
        aria-labelledby="device-warning-title"
        aria-describedby="device-warning-description"
      >
        <DialogTitle id="device-warning-title">{"Device Detected"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="device-warning-description">
            A suspicious device (e.g., phone or tablet) has been detected in the frame. Please remove it to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeviceWarning(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog
        open={multiFaceWarning}
        onClose={handleMultiFaceWarningClose}
        aria-labelledby="multi-face-warning-title"
        aria-describedby="multi-face-warning-description"
      >
        <DialogTitle id="multi-face-warning-title">{"Multiple Faces Detected"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="multi-face-warning-description">
            Multiple faces have been detected in the frame. Please ensure only one face is visible to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMultiFaceWarningClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={noFaceWarning && capturing }
        onClose={handleNoFaceWarningClose}
        aria-labelledby="no-face-warning-title"
        aria-describedby="no-face-warning-description"
      >
        <DialogTitle id="no-face-warning-title">{"No Face Detected"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="no-face-warning-description">
            Your face is not detected. Please remain in the camera view to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoFaceWarningClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* <Dialog
        open={lookingAwayWarning}
        onClose={handleLookingAwayWarningClose}
        aria-labelledby="looking-away-warning-title"
        aria-describedby="looking-away-warning-description"
      >
        <DialogTitle id="looking-away-warning-title">{"Focus on the Screen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="looking-away-warning-description">
            It seems like you're not focusing on the screen. Please pay attention to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLookingAwayWarningClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
      <div id="videoContainer" style={{ minWidth: "100%", position: "relative", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <video
          id="video"
          ref={videoRef}
          style={{ width: "100%" }}
          muted
          autoPlay
        />
        <canvas ref={canvasRef} style={{ position: "absolute", top: 0 }} />
        {isMuted ? <p style={{color:"red"}}>You are on mute</p> : ""}
        {!permission && (
          <div style={{ textAlign: "center", marginTop: 10 }}>
            <IconButton onClick={getCameraPermission}>
              <VideocamOffIcon />
            </IconButton>
            <Typography variant="caption" color="error">
              Please allow permission for camera and mic
            </Typography>
          </div>
        )}

        {permission && (
          <>
            {
            showWaitMessage ? (
              <Typography style={{color:"red"}}>Wait...</Typography>) : 
              (<Typography style={{color:"green"}}>Speak now</Typography>)
            }
          </>
          
          // <IconButton style={{ marginTop: 10 }}>
          //   {capturing ? (
          //     <StopCircleIcon style={{ height: 60, width: 60, backgroundColor: "red", color: "white", borderRadius: "50%" }} />
          //   ) : (
          //     <PlayCircleIcon style={{ height: 60, width: 60, backgroundColor: "green", color: "white", borderRadius: "50%" }} />
          //   )}
          // </IconButton>
        )}

        {/* Popup Dialog */}
        <Dialog
          open={openPopup}
          onClose={() => handlePopupClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">...</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              continue answering or submit for next question.
              <br />
              Auto-submit in {countdown} seconds.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handlePopupClose(false)} color="primary">
              Continue Answering
            </Button>
            <Button onClick={() => handlePopupClose(true)} color="primary" autoFocus>
              Next Question
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  audioVideoList: state.jobs.audioVideoList,
});

const mapDispatchToProps = {
  createAudioVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebcamVideo);
