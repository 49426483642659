import { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchTemplates,
  deleteTemplateById,
} from "../../redux/actions/email_template";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AlertDialog from "../../components/Dialog";
import EditIcon from "@mui/icons-material/Edit";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled("input")(({ theme }) => ({
  color: "inherit",
  width: "100%",
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  transition: theme.transitions.create("width"),
  [theme.breakpoints.up("sm")]: {
    width: "12ch",
    "&:focus": {
      width: "20ch",
    },
  },
}));

const EmailTemplateList = ({
  fetchTemplates,
  deleteTemplateById,
  templates,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
    sortField: "et_name",
    sortOrder: "asc",
  });
  const [searchKey, setSearchKey] = useState("");

  const [tableHeight, setTableHeight] = useState(600);
  const rowHeight = 52;

  const updateTableHeight = (pageSize) => {
    const headerHeight = 56;
    const footerHeight = 56;
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const columns = [
    {
      field: "et_name",
      headerName: "Template Name",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/hr/email-templates/view/${params.row.et_id}`}>
          {params.row.et_name}
        </Link>
      ),
    
    },
    {
      field: "et_subject",
      headerName: "Subject",
      flex: 1,
    },
    {
      field: "et_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.et_created_at).toLocaleDateString(),
    },
    {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <Stack direction="row" spacing={1}>
            {/* Edit Button */}
            <IconButton
              onClick={() => navigate(`/hr/email-templates/edit/${params.row.et_id}`)}
              title="Edit Template"
            >
              <EditIcon sx={{ color: "blue" }} />
            </IconButton>
      
            {/* Delete Button */}
            <IconButton
              onClick={() =>
                submitDeleteTemplate(params.row.et_id, params.row.et_name)
              }
              title="Delete Template"
            >
              <DeleteForeverIcon sx={{ color: "red" }} />
            </IconButton>
          </Stack>
        ),
      }
      
  ];

  const submitDeleteTemplate = (id, name) => {
    setTemplateId(id);
    setOpen(true);
  };

  const clearSearch = () => {
    setSearchKey("");
    fetchTemplates(paginationModals.page, paginationModals.pageSize, "", "");
  };

  useEffect(() => {
    fetchTemplates(
      paginationModals.page,
      paginationModals.pageSize,
      paginationModals.sortField,
      paginationModals.sortOrder,
      searchKey
    );
  }, [paginationModals, searchKey]);

  useEffect(() => {
    if (confirm && templateId) {
      deleteTemplateById(templateId);
      setConfirm(false);
    }
  }, [confirm]);

  useEffect(() => {
    updateTableHeight(templates?.rows?.length || 0);
  }, [paginationModals.pageSize, templates]);

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Delete Template"
        setConfirm={setConfirm}
        content="Are you sure you want to delete this template?"
      />
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
          >
            <Typography variant="h5">Email Templates</Typography>
            <Stack direction="row" spacing={2}>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>
              {searchKey && (
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              )} */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/hr/email-templates/new")}
              >
                New Template
              </Button>
            </Stack>
          </Box>
          <Divider />
          <div style={{ height: tableHeight, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={templates?.rows || []}
              getRowId={(row) => row?.et_id}
              total={templates?.count || 0}
              columns={columns}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.emailTemplate.templates,
});

const mapDispatchToProps = { fetchTemplates, deleteTemplateById };

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateList);
