import axios from "axios";

export const _getInterviews = async (page, size) => {
  try {
    const url = `/jobs/list/interviews?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getEmployees = async (page, size) => {
  try {
    const url = `/jobs/employee_list?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};



export const _getHrData = async () => {
  try {
    const url = `/hr/dash_data`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};


export const _getAllBatches = async (id, page, size) => {
  try {
    const url = `/jobs/batches/${id}?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};


