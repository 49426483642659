import {
    FETCH_TEMPLATES_SUCCESS, 
    FETCH_TEMPLATES_FAILURE, 
    FETCH_TEMPLATES_REQUEST, 
    FETCH_TEMPLATE_BY_ID_REQUEST, 
    FETCH_TEMPLATE_BY_ID_SUCCESS, 
    FETCH_TEMPLATE_BY_ID_FAILURE, 
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_SUCCESS,
    CREATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE
} from "../actions/types";

const initialState = {
  templates: [],
  template: null,
  loading: false,
  error: null,
};

const emailTemplate = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATES_REQUEST:
    case CREATE_TEMPLATE_REQUEST:
    case UPDATE_TEMPLATE_REQUEST:
    case DELETE_TEMPLATE_REQUEST:
    case FETCH_TEMPLATE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload  || [],
        error: null,
      };

    case FETCH_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        template: action.payload,
        error: null,
      };

    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: [...state.templates, action.payload],
        error: null,
      };

    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: state.templates.map((template) =>
          template.et_id === action.payload.et_id ? action.payload : template
        ),
        error: null,
      };

    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: state.templates.filter(
          (template) => template.et_id !== action.payload
        ),
        error: null,
      };

    case FETCH_TEMPLATES_FAILURE:
    case CREATE_TEMPLATE_FAILURE:
    case UPDATE_TEMPLATE_FAILURE:
    case DELETE_TEMPLATE_FAILURE:
    case FETCH_TEMPLATE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default emailTemplate;
