import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function ServerPaginationGridNoRowCount({
  setPaginationModals,
  paginationModals,
  rowsData,
  getRowId,
  total,
  columns,
  initialState,
  setSortModels = () => {}, // Default to a no-op function if not provided
  sortModels = [], // Default to an empty array if not provided
}) {
  const [paginationModel, setPaginationModel] = useState(paginationModals);
  const [sortModel, setSortModel] = useState(sortModels);
  useEffect(() => {
    setPaginationModals(paginationModel);
    setSortModels(sortModel);
  }, [paginationModel, sortModel]);

  return (
    <DataGrid
      rows={rowsData}
      getRowId={getRowId}
      columns={columns}
      pageSizeOptions={[5, 10, 25, 50]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      disableRowSelectionOnClick
      rowCount={total}
      initialState={initialState}
      sx={{
        mt: 2,
      }}
    />
  );
}
