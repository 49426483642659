export const testMCQ = [
  {
    id: 1,
    type: "mcq",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 2,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 3,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 4,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 5,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 6,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 7,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 8,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 9,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
  {
    id: 10,
    type: "coding",
    question:
      "How many LR(lookup relationship) fields can be created in an object?",
    options: ["opt1", "opt2", "opt3", "opt4"],
  },
];

export const testCoding = [
  {
    id: 1,
    type: "coding",
    question: "Write code for Flow Control Statement in Apex?",
  },
  {
    id: 2,
    type: "coding",
    question: "What is Apex Sharing, write a sample code on Apex Sharing?",
  },
  // {
  //   id: 3,
  //   type: "coding",
  //   question: "Write code for Flow Control Statement in Apex?",
  // },
  // {
  //   id: 4,
  //   type: "coding",
  //   question: "What is Apex Sharing, write a sample code on Apex Sharing?",
  // },
];

export const courses = [
  {
    id: 1,
    skill_name: "Apex",
    course_name: "Re-skilling Course",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean scelerisque, tellus vitae congue dictum, quam ante elementum lorem, non volutpat dui urna vel massa. Aenean tempor diam elementum nulla gravida faucibus. Duis dapibus lacus ac malesuada pretium. Nulla turpis nibh, aliquet pretium tempor eget, ultrices in dui. Quisque ut mi id lorem sagittis dictum nec non metus. Cras facilisis ut quam quis porta. Suspendisse facilisis sit amet sem sagittis volutpat. Nullam accumsan cursus aliquet. Aliquam iaculis volutpat neque eu mollis. Sed viverra quam ut odio mattis imperdiet. Nam vitae malesuada lorem, ut imperdiet massa.",
    url: "https://www.google.com/",
  },
  {
    id: 2,
    skill_name: "Apex",
    course_name: "LWC upskilling Course",
    desc: "Aenean imperdiet nibh sed efficitur venenatis. Duis maximus mi nunc, sit amet scelerisque justo egestas ut. Etiam ultricies augue faucibus, lobortis eros quis, facilisis nisi. Donec est ipsum, hendrerit sit amet neque vel, placerat suscipit sem. In venenatis nunc sit amet tellus vehicula, id efficitur turpis ullamcorper. Mauris vestibulum, lectus vel faucibus maximus, velit sapien dignissim sem, vitae pellentesque nulla ipsum quis tellus. Suspendisse eget ipsum tempor, feugiat purus vel, rhoncus ex. Sed vitae accumsan turpis. Integer aliquet dui sit amet nunc viverra, nec tempor justo gravida. Suspendisse sit amet massa velit. Ut eget lacus urna. Morbi lectus nisi, fermentum a dolor vitae, faucibus tristique orci. Nulla facilisi. Praesent maximus nisi ut pulvinar posuere. Nullam facilisis fringilla pellentesque. Nullam finibus tristique felis, ac feugiat libero gravida vel.",
    url: "https://www.google.com/",
  },

  {
    id: 3,
    skill_name: "Apex",
    course_name: "Re-skilling Course",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean scelerisque, tellus vitae congue dictum, quam ante elementum lorem, non volutpat dui urna vel massa. Aenean tempor diam elementum nulla gravida faucibus. Duis dapibus lacus ac malesuada pretium. Nulla turpis nibh, aliquet pretium tempor eget, ultrices in dui. Quisque ut mi id lorem sagittis dictum nec non metus. Cras facilisis ut quam quis porta. Suspendisse facilisis sit amet sem sagittis volutpat. Nullam accumsan cursus aliquet. Aliquam iaculis volutpat neque eu mollis. Sed viverra quam ut odio mattis imperdiet. Nam vitae malesuada lorem, ut imperdiet massa.",
    url: "https://www.google.com/",
  },
  {
    id: 4,
    skill_name: "Apex",
    course_name: "LWC upskilling Course",
    desc: "Aenean imperdiet nibh sed efficitur venenatis. Duis maximus mi nunc, sit amet scelerisque justo egestas ut. Etiam ultricies augue faucibus, lobortis eros quis, facilisis nisi. Donec est ipsum, hendrerit sit amet neque vel, placerat suscipit sem. In venenatis nunc sit amet tellus vehicula, id efficitur turpis ullamcorper. Mauris vestibulum, lectus vel faucibus maximus, velit sapien dignissim sem, vitae pellentesque nulla ipsum quis tellus. Suspendisse eget ipsum tempor, feugiat purus vel, rhoncus ex. Sed vitae accumsan turpis. Integer aliquet dui sit amet nunc viverra, nec tempor justo gravida. Suspendisse sit amet massa velit. Ut eget lacus urna. Morbi lectus nisi, fermentum a dolor vitae, faucibus tristique orci. Nulla facilisi. Praesent maximus nisi ut pulvinar posuere. Nullam facilisis fringilla pellentesque. Nullam finibus tristique felis, ac feugiat libero gravida vel.",
    url: "https://www.google.com/",
  },
];

export const languages = [
  {
    id: 1,
    label: "Java",
    value: "java",
  },
  {
    id: 2,
    label: "JavaScript",
    value: "javascript",
  },
  {
    id: 3,
    label: "Python",
    value: "python",
  },
  {
    id: 4,
    label: "Apex",
    value: "apex",
  },
  { id: 5, label: "XML", value: "xml" },
  { id: 6, label: "Ruby", value: "ruby" },
  { id: 7, label: "SASS", value: "sass" },
  { id: 9, label: "MYSQL", value: "mysql" },
  { id: 10, label: "JSON", value: "json" },
  { id: 11, label: "HTML", value: "html" },
  { id: 13, label: "GOLANG", value: "golang" },
  { id: 15, label: "CSHARP", value: "csharp" },
  { id: 17, label: "CSS", value: "css" },
];

export const scores = [
  {
    id: 1,
    skill_name: "Java",
    tests: [
      {
        id: "sc_1",
        score: 32,
        date: "2021-10-10",
      },
      {
        id: "sc_2",
        score: 68,
        date: "2021-11-11",
      },
      {
        id: "sc_3",
        score: 90,
        date: "2021-12-12",
      },
      {
        id: "sc_4",
        score: 95,
        date: "2021-10-10",
      },
      {
        id: "sc_5",
        score: 131,
        date: "2021-11-11",
      },
      {
        id: "sc_6",
        score: 165,
        date: "2021-12-12",
      },
      {
        id: "sc_7",
        score: 183,
        date: "2021-10-10",
      },
      {
        id: "sc_8",
        score: 190,
        date: "2021-12-12",
      },
    ],
  },
  {
    id: 2,
    skill_name: "Python",
    tests: [
      {
        id: "sc_9",
        score: 36,
        date: "2021-01-10",
      },
      {
        id: "sc_10",
        score: 64,
        date: "2021-02-11",
      },
      {
        id: "sc_11",
        score: 81,
        date: "2021-03-12",
      },
      {
        id: "sc_12",
        score: 95,
        date: "2021-04-10",
      },
      {
        id: "sc_13",
        score: 99,
        date: "2021-05-11",
      },
      {
        id: "sc_14",
        score: 131,
        date: "2021-06-12",
      },
      {
        id: "sc_15",
        score: 153,
        date: "2021-07-10",
      },
      {
        id: "sc_16",
        score: 185,
        date: "2021-08-12",
      },
    ],
  },
  {
    id: 3,
    skill_name: "Apex",
    tests: [
      {
        id: "sc_17",
        score: 32,
        date: "2021-10-10",
      },
      {
        id: "sc_18",
        score: 68,
        date: "2021-11-11",
      },
      {
        id: "sc_19",
        score: 90,
        date: "2021-12-12",
      },
      {
        id: "sc_20",
        score: 95,
        date: "2021-10-10",
      },
      {
        id: "sc_21",
        score: 131,
        date: "2021-11-11",
      },
      {
        id: "sc_22",
        score: 165,
        date: "2021-12-12",
      },
      {
        id: "sc_23",
        score: 183,
        date: "2021-10-10",
      },
      {
        id: "sc_24",
        score: 190,
        date: "2021-12-12",
      },
    ],
  },

  {
    id: 4,
    skill_name: "Salesforce",
    tests: [
      {
        id: "sc_25",
        score: 36,
        date: "2021-01-10",
      },
      {
        id: "sc_26",
        score: 64,
        date: "2021-02-11",
      },
      {
        id: "sc_27",
        score: 81,
        date: "2021-03-12",
      },
      {
        id: "sc_28",
        score: 95,
        date: "2021-04-10",
      },
      {
        id: "sc_29",
        score: 99,
        date: "2021-05-11",
      },
      {
        id: "sc_30",
        score: 131,
        date: "2021-06-12",
      },
      {
        id: "sc_31",
        score: 153,
        date: "2021-07-10",
      },
      {
        id: "sc_32",
        score: 185,
        date: "2021-08-12",
      },
    ],
  },
  {
    id: 5,
    skill_name: "AWS",
    tests: [
      {
        id: "sc_33",
        score: 32,
        date: "2021-10-10",
      },
      {
        id: "sc_34",
        score: 68,
        date: "2021-11-11",
      },
      {
        id: "sc_35",
        score: 90,
        date: "2021-12-12",
      },
      {
        id: "sc_36",
        score: 95,
        date: "2021-10-10",
      },
      {
        id: "sc_37",
        score: 131,
        date: "2021-11-11",
      },
      {
        id: "sc_38",
        score: 165,
        date: "2021-12-12",
      },
      {
        id: "sc_39",
        score: 183,
        date: "2021-10-10",
      },
      {
        id: "sc_40",
        score: 190,
        date: "2021-12-12",
      },
    ],
  },
  {
    id: 6,
    skill_name: "Javascript",
    tests: [
      {
        id: "sc_41",
        score: 36,
        date: "2021-01-10",
      },
      {
        id: "sc_42",
        score: 64,
        date: "2021-02-11",
      },
      {
        id: "sc_43",
        score: 81,
        date: "2021-03-12",
      },
      {
        id: "sc_44",
        score: 95,
        date: "2021-04-10",
      },
      {
        id: "sc_45",
        score: 99,
        date: "2021-05-11",
      },
      {
        id: "sc_46",
        score: 131,
        date: "2021-06-12",
      },
      {
        id: "sc_47",
        score: 153,
        date: "2021-07-10",
      },
      {
        id: "sc_48",
        score: 185,
        date: "2021-08-12",
      },
    ],
  },
];

export const InterviewData = [
  {
    id: 1,
    question: "What is difference between public and global class in Apex ?",
    answer:
      "Public class can be accessed within application or namespace. This is not exactly like public modifier in Java. Global class visible everywhere , any application or namespace. WebService must be declared as Global and which can be accessed inside Javascript also. It is like public modifier in Java.",
  },
  {
    id: 2,
    question: "What is Apex?",
    answer:
      "Apex is a strongly typed, object-oriented programming language that allows developers to execute flow and transaction control statements on the Force.com platform server in conjunction with calls to the Force.com​ API. Using syntax that looks like Java and acts like database stored procedures, Apex enables developers to add business logic to most system events, including button clicks, related record updates, and Visualforce pages. Apex code can be initiated by Web service requests and from triggers on objects.",
  },
  {
    id: "3",
    question: "What is your hobby ?",
    answer: "I like to play cricket and chess.",
  },
];

export const jobList = [
  {
    id: 1,
    name: "Software Developer",
    description: "Develop software applications",
    status: "Active",
    postedOn: "2021-10-10",

    totalApplications: 10,
    hired: 2,
    inProgress: 3,
    total: 20,
    shortlisted: 5,
    rejected: 10,
  },
  {
    id: 2,
    name: "Data Analyst",
    description: "Analyze data",
    status: "Active",
    postedOn: "2021-03-14",
    totalApplications: 23,
    hired: 5,
    inProgress: 7,
    total: 30,
    shortlisted: 10,
    rejected: 13,
  },
  {
    id: 3,
    name: "HR Manager",
    description: "Manage HR",
    status: "Closed",
    postedOn: "2023-05-20",
    totalApplications: 15,
    hired: 5,
    inProgress: 3,
    total: 20,
    shortlisted: 5,
    rejected: 10,
  },
  {
    id: 4,
    name: "Product Manager",
    description: "Manage products",
    status: "Closed",
    postedOn: "2023-07-18",
    totalApplications: 10,
    hired: 2,
    inProgress: 6,
    total: 20,
    shortlisted: 5,
    rejected: 10,
  },
  {
    id: 5,
    name: "Software Developer",
    description: "Develop software applications",
    status: "Closed",
    postedOn: "2023-08-25",
    totalApplications: 20,
    hired: 5,
    inProgress: 7,
    total: 30,
    shortlisted: 10,
    rejected: 13,
  },
  {
    id: 6,
    name: "Data Analyst",
    description: "Analyze data",
    status: "Active",
    postedOn: "2024-02-30",
    totalApplications: 10,
    hired: 0,
    inProgress: 9,
    total: 20,
    shortlisted: 5,
    rejected: 10,
  },
  {
    id: 7,
    name: "HR Manager",
    description: "Manage HR",
    status: "Closed",
    postedOn: "2024-01-10",
    totalApplications: 19,
    hired: 8,
    inProgress: 11,
    total: 30,
    shortlisted: 10,
    rejected: 13,
  },
  {
    id: 8,
    name: "Product Manager",
    description: "Manage products",
    status: "Active",
    postedOn: "2024-03-06",
    totalApplications: 30,
    hired: 10,
    inProgress: 15,
    total: 40,
    shortlisted: 25,
    rejected: 15,
  },
  {
    id: 9,
    name: "Software Developer",
    description: "Develop software applications",
    status: "Active",
    postedOn: "2024-04-20",
    totalApplications: 33,
    hired: 15,
    inProgress: 13,
    total: 50,
    shortlisted: 25,
    rejected: 12,
  },
  {
    id: 10,
    name: "Data Analyst",
    description: "Analyze data",
    status: "Active",
    postedOn: "2024-02-20",
    totalApplications: 15,
    hired: 5,
    inProgress: 7,
    total: 20,
    shortlisted: 10,
    rejected: 13,
  },
  {
    id: 11,
    name: "HR Manager",
    description: "Manage HR",
    status: "Closed",
    postedOn: "2024-01-17",
    totalApplications: 20,
    hired: 8,
    inProgress: 11,
    total: 30,
    shortlisted: 10,
    rejected: 13,
  },
  {
    id: 12,
    name: "Product Manager",
    description: "Manage products",
    status: "Active",
    postedOn: "2024-03-25",
    totalApplications: 10,
    hired: 2,
    inProgress: 6,
    total: 20,
    shortlisted: 5,
    rejected: 10,
  },
];

const skillsGroup = [
  {
    id: 1,
    name: "Programming Languages",
    label: "Programming Languages",
  },
  {
    id: 2,
    name: "Frameworks",
    label: "Frameworks",
  },
  {
    id: 3,
    name: "Tools",
    label: "Tools",
  },
  {
    id: 4,
    name: "Databases",
    label: "Databases",
  },
  {
    id: 5,
    name: "OS",
    label: "OS",
  },
  {
    id: 6,
    name: "Others",
    label: "Others",
  },
];

const technologies = [
  {
    id: 1,
    name: "Python",
    label: "Python",
    groupId: 1,
  },
  {
    id: 2,
    name: "Java",
    label: "Java",
    groupId: 1,
  },
  {
    id: 3,
    name: "JavaScript",
    label: "JavaScript",
    groupId: 1,
  },

  {
    id: 7,
    name: "Django",
    label: "Django",
    groupId: 2,
  },
  {
    id: 8,
    name: "Flask",
    label: "Flask",
    groupId: 2,
  },
  {
    id: 9,
    name: "Spring",
    label: "Spring",
    groupId: 2,
  },

  {
    id: 11,
    name: "Express",
    label: "Express",
    groupId: 2,
  },
  {
    id: 12,
    name: "NoneSQL Databases",
    label: "NoneSQL Databases",
    groupId: 4,
  },
  {
    id: 13,
    name: "SQL Databases",
    label: "SQL Databases",
    groupId: 4,
  },

  {
    id: 18,
    name: "Salesforce",
    label: "Salesforce",
    groupId: 1,
  },
  {
    id: 19,
    name: "React Native",
    label: "React Native",
    groupId: 3,
  },
  {
    id: 20,
    name: "Flutter",
    label: "Flutter",
    groupId: 3,
  },
  {
    id: 21,
    name: "Kotlin",
    label: "Kotlin",
    groupId: 1,
  },
  {
    id: 22,
    name: "Swift",
    label: "Swift",
    groupId: 1,
  },
  {
    id: 23,
    name: "Objective-C",
    label: "Objective-C",
    groupId: 1,
  },
  {
    id: 24,
    name: "C#",
    label: "C#",
    groupId: 1,
  },
  {
    id: 25,
    name: "PHP",
    label: "PHP",
    groupId: 1,
  },
  {
    id: 26,
    name: "Ruby",
    label: "Ruby",
    groupId: 1,
  },
  {
    id: 27,
    name: "Go",
    label: "Go",
    groupId: 1,
  },
  {
    id: 28,
    name: "Rust",
    label: "Rust",
    groupId: 1,
  },
  {
    id: 29,
    name: "Scala",
    label: "Scala",
    groupId: 1,
  },
  {
    id: 30,
    name: "Perl",
    label: "Perl",
    groupId: 1,
  },
  {
    id: 31,
    name: "R",
    label: "R",
    groupId: 1,
  },
  {
    id: 32,
    name: "TypeScript",
    label: "TypeScript",
    groupId: 1,
  },
  {
    id: 33,
    name: "HTML",
    label: "HTML",
    groupId: 1,
  },
  {
    id: 34,
    name: "CSS",
    label: "CSS",
    groupId: 1,
  },
  {
    id: 35,
    name: "Sass",
    label: "Sass",
    groupId: 1,
  },
  {
    id: 36,
    name: "Less",
    label: "Less",
    groupId: 1,
  },
];

export const skills = [
  {
    id: 1,
    name: "Apex",
    label: "Apex",
    techId: 18,
  },
  {
    id: 2,
    name: "Visualforce",
    label: "Visualforce",
    techId: 18,
  },
  {
    id: 3,
    name: "Lightning",
    label: "Lightning",
    techId: 18,
  },
  {
    id: 4,
    name: "Aura",
    label: "Aura",
    techId: 18,
  },
  {
    id: 5,
    name: "SOQL",
    label: "SOQL",
    techId: 18,
  },
  {
    id: 6,
    name: "SOSL",
    label: "SOSL",
    techId: 18,
  },
  {
    id: 7,
    name: "Triggers",
    label: "Triggers",
    techId: 18,
  },
  {
    id: 8,
    name: "Classes",
    label: "Classes",
    techId: 18,
  },
  {
    id: 9,
    name: "Components",
    label: "Components",
    techId: 18,
  },
  {
    id: 10,
    name: "Objects",
    label: "Objects",
    techId: 18,
  },
  {
    id: 11,
    name: "Fields",
    label: "Fields",
    techId: 18,
  },
  {
    id: 12,
    name: "Workflows",
    label: "Workflows",
    techId: 18,
  },
  {
    id: 13,
    name: "Process Builder",
    label: "Process Builder",
    techId: 18,
  },
  {
    id: 14,
    name: "Flows",
    label: "Flows",
    techId: 18,
  },
  {
    id: 15,
    name: "Reports",
    label: "Reports",
    techId: 18,
  },
  {
    id: 16,
    name: "Dashboards",
    label: "Dashboards",
    techId: 18,
  },
  {
    id: 17,
    name: "AppExchange",
    label: "AppExchange",
    techId: 18,
  },
  {
    id: 18,
    name: "Lightning Web Components",
    label: "Lightning Web Components",
    techId: 18,
  },

  {
    id: 19,
    name: "PostgreSQL",
    label: "PostgreSQL",
    techId: 13,
  },
  {
    id: 20,
    name: "SQLite",
    label: "SQLite",
    techId: 13,
  },
  {
    id: 21,
    name: "Oracle",
    label: "Oracle",
    techId: 13,
  },
  {
    id: 22,
    name: "SQL Server",
    label: "SQL Server",
    techId: 13,
  },
  {
    id: 23,
    name: "MySQL",
    label: "MySQL",
    techId: 13,
  },
  {
    id: 24,
    name: "MariaDB",
    label: "MariaDB",
    techId: 13,
  },
  {
    id: 25,
    name: "MongoDB",
    label: "MongoDB",
    techId: 12,
  },
  {
    id: 26,
    name: "Cassandra",
    label: "Cassandra",
    techId: 12,
  },
  {
    id: 27,
    name: "CouchDB",
    label: "CouchDB",
    techId: 12,
  },
  {
    id: 28,
    name: "DynamoDB",
    label: "DynamoDB",
    techId: 12,
  },
  {
    id: 29,
    name: "Firebase",
    label: "Firebase",
    techId: 12,
  },
  {
    id: 30,
    name: "Realm",
    label: "Realm",
    techId: 12,
  },
  {
    id: 31,
    name: "Neo4j",
    label: "Neo4j",
    techId: 12,
  },
  {
    id: 32,
    name: "Redis",
    label: "Redis",
    techId: 12,
  },
  {
    id: 33,
    name: "Elasticsearch",
    label: "Elasticsearch",
    techId: 12,
  },
  {
    id: 34,
    name: "Couchbase",
    label: "Couchbase",
    techId: 12,
  },
  {
    id: 35,
    name: "Riak",
    label: "Riak",
    techId: 12,
  },
  {
    id: 36,
    name: "HBase",
    label: "HBase",
    techId: 12,
  },
  {
    id: 37,
    name: "Hive",
    label: "Hive",
    techId: 12,
  },
  {
    id: 38,
    name: "Hadoop",
    label: "Hadoop",
    techId: 12,
  },
  {
    id: 39,
    name: "React",
    label: "React",
    techId: 3,
  },
  {
    id: 40,
    name: "Angular",
    label: "Angular",
    techId: 3,
  },
  {
    id: 41,
    name: "Vue",
    label: "Vue",
    techId: 3,
  },
  {
    id: 42,
    name: "Node",
    label: "Node",
    techId: 3,
  },
  {
    id: 43,
    name: "Express",
    label: "Express",
    techId: 3,
  },
  {
    id: 44,
    name: "Next",
    label: "Next",
    techId: 3,
  },
  {
    id: 45,
    name: "Nest",
    label: "Nest",
    techId: 3,
  },
  {
    id: 46,
    name: "Gatsby",
    label: "Gatsby",
    techId: 3,
  },
  {
    id: 47,
    name: "Svelte",
    label: "Svelte",
    techId: 3,
  },
  {
    id: 48,
    name: "Ember",
    label: "Ember",
    techId: 3,
  },
  {
    id: 49,
    name: "Backbone",
    label: "Backbone",
    techId: 3,
  },
  {
    id: 50,
    name: "Meteor",
    label: "Meteor",
    techId: 3,
  },
  {
    id: 51,
    name: "Polymer",
    label: "Polymer",
    techId: 3,
  },
  {
    id: 52,
    name: "Aurelia",
    label: "Aurelia",
    techId: 3,
  },
  {
    id: 53,
    name: "Knockout",
    label: "Knockout",
    techId: 3,
  },
  {
    id: 54,
    name: "Dojo",
    label: "Dojo",
    techId: 3,
  },
  {
    id: 55,
    name: "jQuery",
    label: "jQuery",
    techId: 3,
  },
];

const domains = [
  {
    id: 1,
    name: "Web Development",
    label: "Web Development",
  },
  {
    id: 2,
    name: "Mobile Development",
    label: "Mobile Development",
  },
  {
    id: 3,
    name: "Desktop Development",
    label: "Desktop Development",
  },
  {
    id: 4,
    name: "DevOps",
    label: "DevOps",
  },
  {
    id: 5,
    name: "Data Science",
    label: "Data Science",
  },
  {
    id: 6,
    name: "Machine Learning",
    label: "Machine Learning",
  },
  {
    id: 7,
    name: "Artificial Intelligence",
    label: "Artificial Intelligence",
  },
  {
    id: 8,
    name: "Cyber Security",
    label: "Cyber Security",
  },
  {
    id: 9,
    name: "Cloud Computing",
    label: "Cloud Computing",
  },
  {
    id: 10,
    name: "Blockchain",
    label: "Blockchain",
  },
  {
    id: 11,
    name: "IoT",
    label: "IoT",
  },
  {
    id: 12,
    name: "AR/VR",
    label: "AR/VR",
  },
  {
    id: 13,
    name: "Game Development",
    label: "Game Development",
  },
  {
    id: 14,
    name: "UI/UX",
    label: "UI/UX",
  },
  {
    id: 15,
    name: "Testing",
    label: "Testing",
  },
  {
    id: 16,
    name: "Others",
    label: "Others",
  },
];

const jobTypes = [
  {
    id: 1,
    name: "Full Time",
    label: "Full Time",
  },
  {
    id: 2,
    name: "Part Time",
    label: "Part Time",
  },
  {
    id: 3,
    name: "Contract",
    label: "Contract",
  },
  {
    id: 4,
    name: "Internship",
    label: "Internship",
  },
  {
    id: 5,
    name: "Remote",
    label: "Remote",
  },
  {
    id: 6,
    name: "Freelance",
    label: "Freelance",
  },
];

const bussinessDomains = [
  {
    id: 1,
    name: "Healthcare",
    label: "Healthcare",
  },
  {
    id: 2,
    name: "Finance",
    label: "Finance",
  },
  {
    id: 3,
    name: "Education",
    label: "Education",
  },
  {
    id: 4,
    name: "E-Commerce",
    label: "E-Commerce",
  },
  {
    id: 5,
    name: "Real Estate",
    label: "Real Estate",
  },
  {
    id: 6,
    name: "Travel",
    label: "Travel",
  },
  {
    id: 7,
    name: "Food",
    label: "Food",
  },
  {
    id: 8,
    name: "Logistics",
    label: "Logistics",
  },
  {
    id: 9,
    name: "Media",
    label: "Media",
  },
  {
    id: 10,
    name: "Entertainment",
    label: "Entertainment",
  },
  {
    id: 11,
    name: "Gaming",
    label: "Gaming",
  },
  {
    id: 12,
    name: "Automobile",
    label: "Automobile",
  },
  {
    id: 13,
    name: "Fashion",
    label: "Fashion",
  },
  {
    id: 14,
    name: "Sports",
    label: "Sports",
  },
  {
    id: 15,
    name: "Telecom",
    label: "Telecom",
  },
  {
    id: 16,
    name: "Others",
    label: "Others",
  },
];
