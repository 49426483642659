import React, { useState, useEffect } from 'react';

const OperatingSystemCheck = () => {
  const [os, setOS] = useState('');

  useEffect(() => {
    const detectOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;

      if (/Win/.test(platform)) {
        setOS('Windows');
      } else if (/Mac/.test(platform)) {
        setOS('MacOS');
      } else if (/Linux/.test(platform)) {
        setOS('Linux');
      } else if (/Android/.test(userAgent)) {
        setOS('Android');
      } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        setOS('iOS');
      } else {
        setOS('Unknown');
      }
    };

    detectOS();
  }, []);

  return (
    <>{os}</>
  );
};

export default OperatingSystemCheck;
