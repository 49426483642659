// ProgressBarWithTooltip.js
import React, { useEffect } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import * as Tooltip from "@radix-ui/react-tooltip";
import "react-step-progress-bar/styles.css";
import "./tooltipStyles.css";
import { connect } from "react-redux";

const steps = [
  "Checking Extension and Analyzing File",
  "Text Extracted, File Parsing",
  "Matching Skills with JD (Checking Good to have and Nice to Have Skill)",
  "Giving Score to CV as per Skill",
  "Process Completed",
];

const ProgressBarWithTooltip = ({ currentStep, type }) => {
  return (
    <div
      style={{
        textAlign: "center",
        marginLeft: "80px",
        marginRight: "80px",
        height: "150px",
      }}
    >
      <h1>
        Step : {currentStep}/4
        {/* <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <span style={{ cursor: "pointer", marginLeft: "10px" }}>🛈</span>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="tooltip-content" side="right" align="center">
                <div>
                  <h3>Steps Involved</h3>
                  <ul>
                    {steps.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </div>
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider> */}
      </h1>
      <ProgressBar percent={(currentStep / 4) * 100} filledBackground="green">
        <Step transition="scale">
          {({ accomplished }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "green" : "gray",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                1
              </div>
              <div
                style={{
                  marginTop: "10px",
                  height: "3em",
                  lineHeight: "1.5em",
                  overflow: "hidden",
                  width: "60%",
                }}
              >
                Checking Extension and Analyzing File
              </div>
            </div>
          )}
        </Step>

        <Step transition="scale">
          {({ accomplished }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "green" : "gray",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                2
              </div>
              <div
                style={{
                  marginTop: "10px",
                  height: "3em",
                  lineHeight: "1.5em",
                  overflow: "hidden",
                  width: type ==="CSV" ? "80%" : "60%",
                }}
              >
                {type ==="CSV" ? 'Data Extracted':'Text Extracted, File Parsing'}
              </div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "green" : "gray",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                3
              </div>
              <div
                style={{
                  marginTop: "10px",
                  height: "3em",
                  lineHeight: "1.5em",
                  overflow: "hidden",
                  width: type ==="CSV" ? "80%" : "55%" ,
                }}
              >
                 {type ==="CSV" ? 'Adding Applicant to database' : 'Matching Skills with JD and giving score to CV'} 
              </div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: accomplished ? "white" : "black",
                  backgroundColor: accomplished ? "green" : "gray",
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                4
              </div>
              <div
                style={{
                  marginTop: "10px",
                  height: "3em",
                  lineHeight: "1.5em",
                  overflow: "hidden",
                }}
              >
                Completed
              </div>
            </div>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};
const mapStateToProps = (state) => ({
  progress: state.progress.progress,
});

export default connect(mapStateToProps, {})(ProgressBarWithTooltip);
