import {
    FETCH_TEMPLATES_SUCCESS, 
    FETCH_TEMPLATES_FAILURE, 
    FETCH_TEMPLATES_REQUEST, 
    FETCH_TEMPLATE_BY_ID_REQUEST, 
    FETCH_TEMPLATE_BY_ID_SUCCESS, 
    FETCH_TEMPLATE_BY_ID_FAILURE, 
    CREATE_TEMPLATE_REQUEST,
    CREATE_TEMPLATE_SUCCESS,
    CREATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE
} from "./types";
import {
  getAllTemplates,
  getTemplateById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
} from "../../services/email_template.service";
import { showLoading, hideLoading } from "./loader";

// Fetch all templates
// export const fetchTemplates = () => async (dispatch) => {
//   dispatch({ type: FETCH_TEMPLATES_REQUEST });
//   try {
//     const templates = await getAllTemplates();
//     dispatch({ type: FETCH_TEMPLATES_SUCCESS, payload: templates });
//   } catch (error) {
//     dispatch({ type: FETCH_TEMPLATES_FAILURE, payload: error.message });
//   }
// };

export const fetchTemplates = (page, pageSize, sortField, sortOrder, searchKey) => async (dispatch) => {
    dispatch(showLoading());
    dispatch({ type: FETCH_TEMPLATES_REQUEST });
    console.log('test api call');
  
    try {
      // Build query parameters
      const params = new URLSearchParams({
        page: page + 1, // Backend expects 1-based page numbers
        pageSize,
      });
  
      if (sortField) params.append("sortField", sortField);
      if (sortOrder) params.append("sortOrder", sortOrder);
      if (searchKey) params.append("search", searchKey);
  
      // Fetch templates from service
      const templates = await getAllTemplates(`?${params.toString()}`);
  
      dispatch({ type: FETCH_TEMPLATES_SUCCESS, payload: templates });
      dispatch(hideLoading());
    } catch (error) {
      dispatch({ type: FETCH_TEMPLATES_FAILURE, payload: error.message });
      dispatch(hideLoading());
    }
  };

// Fetch template by ID
export const fetchTemplateById = (id) => async (dispatch) => {
  dispatch(showLoading());  
  dispatch({ type: FETCH_TEMPLATE_BY_ID_REQUEST });
  try {
    const template = await getTemplateById(id);
    dispatch({ type: FETCH_TEMPLATE_BY_ID_SUCCESS, payload: template });
    dispatch(hideLoading());
  } catch (error) {
    dispatch({ type: FETCH_TEMPLATE_BY_ID_FAILURE, payload: error.message });
    dispatch(hideLoading());
  }
};

// Create a new template
export const createNewTemplate = (templateData) => async (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CREATE_TEMPLATE_REQUEST });
  try {
    const newTemplate = await createTemplate(templateData);
    dispatch({ type: CREATE_TEMPLATE_SUCCESS, payload: newTemplate });
    dispatch(hideLoading());
  } catch (error) {
    dispatch({ type: CREATE_TEMPLATE_FAILURE, payload: error.message });
    dispatch(hideLoading());
  }
};

// Update an existing template
export const updateExistingTemplate = (id, templateData) => async (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_TEMPLATE_REQUEST });
  try {
    const updatedTemplate = await updateTemplate(id, templateData);
    dispatch({ type: UPDATE_TEMPLATE_SUCCESS, payload: updatedTemplate });
    dispatch(hideLoading());
  } catch (error) {
    dispatch({ type: UPDATE_TEMPLATE_FAILURE, payload: error.message });
    dispatch(hideLoading());
  }
};

// Delete a template
export const deleteTemplateById = (id) => async (dispatch) => {
    dispatch(showLoading());
  dispatch({ type: DELETE_TEMPLATE_REQUEST });
  try {
    await deleteTemplate(id);
    dispatch({ type: DELETE_TEMPLATE_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_TEMPLATE_FAILURE, payload: error.message });
  }
};
