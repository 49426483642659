import { forgotPassword } from "../../services/auth.service";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,
  AUTH_USER,
  USER_PROFILE,
  UPLOAD_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_TOKEN_SUCCESS,
  UPDATE_USER_DETAILS,
  SIGNUP_INVITE_SENT,
  SIGNUP_INVITE_DECRYPT,
  GET_HR_DASH_DATA_PERMISSION_SUCCESS,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  isLoggedIn: user ? true : false,
  user: user ? user : null,
  userData: null,
  userProfile: null,
  forgotPasswordSuccess: false,
  resetPasswordSuccess: false,
  verifyResetTokenSuccess: false,
  email: '',
  userDetail: null,
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: payload,
      };
    case GET_HR_DASH_DATA_PERMISSION_SUCCESS:
      localStorage.setItem('user', JSON.stringify({ ...state.user, permissions: payload }))
      return {
        ...state,
        user: { ...state.user, permissions: payload }
      }
    case LOGIN_SUCCESS:
      // localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };
    case SIGNUP_SUCCESS:
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case USER_PROFILE:
    case SIGNUP_INVITE_SENT:
      return {
        ...state,
        signupInvite: payload
      }
    case SIGNUP_INVITE_DECRYPT:
      return {
        ...state,
        signupData: payload
      }
    case UPLOAD_USER_PROFILE:
      return {
        ...state,
        userProfile: payload,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetail: payload, //pending
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
      }

    case VERIFY_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        verifyResetTokenSuccess: true,
        email: payload.email,
      }
    default:
      return state;
  }
};

export default auth;
