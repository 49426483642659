import moment from 'moment-timezone';

export function formatDate(user, value) {
    // console.log('user:'+user.u_timezone);
  const timezone = user?.u_timezone || moment.tz.guess();
  const format = (user?.u_date_time_format || "LL").replace(/\s?(HH:mm|hh:mm A)/, '');
  
  return value ? moment(value).tz(timezone).format(format) : "NA";
}

export function formatDateTime(user, value) {
    const timezone = user?.u_timezone || moment.tz.guess();
    const format = user?.u_date_time_format || "LLLL";
    
    return value ? moment(value).tz(timezone).format(format) : "NA";
  }
