import React from 'react'
import { connect } from 'react-redux'

export const SelfAssessment = (props) => {
  return (
    <div>SelfAssessment</div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessment)