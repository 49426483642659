import React, { useEffect, useState } from "react";
import { useIdle, useGeolocation } from "react-use";

const NetworkInfo = ({ speed, setSpeed }) => {
  const [networkState, setNetworkState] = useState({});
  const isIdle = useIdle(5000); // 5 seconds of inactivity to consider user idle
  const geoState = useGeolocation();

  // Simulate fluctuating network speed
  useEffect(() => {
    const updateNetworkState = () => {
      if (navigator.connection) {
        // Simulate fluctuating downlink and RTT values for testing
        const simulatedDownlink =
          (navigator.connection.downlink || 5) + Math.random() * 0.5 - 0.25; // Slight fluctuation
        const simulatedRTT =
          (navigator.connection.rtt || 50) + Math.random() * 10 - 5; // Slight fluctuation

        setNetworkState({
          online: navigator.onLine,
          type: navigator.connection.type,
          effectiveType: navigator.connection.effectiveType,
          downlink: simulatedDownlink.toFixed(2),
          rtt: Math.max(0, simulatedRTT.toFixed(0)), // Ensure RTT is non-negative
          saveData: navigator.connection.saveData,
        });
        setSpeed(simulatedDownlink.toFixed(2));
      } else {
        setNetworkState({
          online: navigator.onLine,
          type: "unknown",
          effectiveType: "unknown",
          downlink: Math.random() * 5, // Simulated random downlink
          rtt: Math.random() * 100, // Simulated random RTT
          saveData: false,
        });
        setSpeed(Math.random() * 5);
      }
    };

    updateNetworkState(); // Initial fetch
    const interval = setInterval(updateNetworkState, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* {speed < 1 && ( */}
        <>
          {/* <h2>Live System Information</h2>
          <h3>Network Information</h3>
          <p>
            <strong>Online:</strong> {networkState.online ? "Yes" : "No"}
          </p>
          <p>
            <strong>Connection Type:</strong> {networkState.type || "unknown"}
          </p>
          <p>
            <strong>Effective Type:</strong>{" "}
            {networkState.effectiveType || "unknown"}
          </p>
          <p>
            <strong>Download Speed:</strong>{" "}
            {networkState.downlink
              ? `${networkState.downlink} Mbps`
              : "unknown"}
          </p>
          <p>
            <strong>Round-Trip Time (RTT):</strong>{" "}
            {networkState.rtt ? `${networkState.rtt} ms` : "unknown"}
          </p>
          <p>
            <strong>Save Data Mode:</strong>{" "}
            {networkState.saveData ? "Enabled" : "Disabled"}
          </p> */}
        </>
      {/* )} */}
    </div>
  );
};

export default NetworkInfo;
