import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const EnsureStorageClearCandidate = ({ orgName, children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const pathArray = currentPath.split("/");
        // Check if 'org_name' is in the route\
        if(pathArray[pathArray.length-1] == "candidate-signin") {
            localStorage.clear();
            localStorage.setItem("corg", pathArray[1]);
        }
        if (pathArray[pathArray.length-1] == "dashboard" && pathArray[pathArray.length-2] == "candidate") {
            setTimeout(() => {
                if (!localStorage.getItem("token")) {
                    const newPath = `/${localStorage.getItem("corg")}/candidate-signin`;
                    navigate(newPath, { replace: true });
                }
            }, 500); 
        }
        
    }, [location, navigate]);
    
    return children; // Render the children only if the route is correct
};

export default EnsureStorageClearCandidate;
